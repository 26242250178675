import { InverterManualControlType } from "@prisma/client"
import { z } from "zod"

import { controlPlanStepInterruption, controlPlanStepState, instructionSchema, stepMetadata } from "./controlPlan"

const stepForReconcilationBaseSchema = z.object({
  interruptions: z.array(controlPlanStepInterruption).nullish(),
  instructions: z.array(instructionSchema),
  stepId: z.string(),
  metadata: stepMetadata.nullish(),
  householdId: z.string(),
})

const stepForReconcilationCleanupSchema = stepForReconcilationBaseSchema.extend({
  source: z.literal("cleanup"),
  cleanupId: z.string(),
})

const stepForReconcilationManualSchema = stepForReconcilationBaseSchema.extend({
  source: z.literal("manual"),
  controlType: z.nativeEnum(InverterManualControlType).nullish(),
})

const stepForReconcilationCommandSchema = stepForReconcilationBaseSchema.extend({
  source: z.literal("command"),
  commandId: z.string(),
  householdId: z.string().nullish(),
})

const stepForReconcilationAutomaticSchema = stepForReconcilationBaseSchema.extend({
  source: z.literal("automatic"),
  controlPlanId: z.string(),
  state: controlPlanStepState,
  startAt: z.date(),
  endAt: z.date(),
})

export const stepForReconcilationSchema = z.discriminatedUnion("source", [
  stepForReconcilationManualSchema,
  stepForReconcilationAutomaticSchema,
  stepForReconcilationCleanupSchema,
  stepForReconcilationCommandSchema,
])

// currently there is no omit for union of objects or discriminatedUnion https://github.com/colinhacks/zod/issues/56 https://github.com/colinhacks/zod/issues/1768
export const stepForReconcilationWithoutInstructionsSchema = z.discriminatedUnion("source", [
  stepForReconcilationManualSchema.omit({ instructions: true, interruptions: true }),
  stepForReconcilationAutomaticSchema.omit({ instructions: true, interruptions: true }),
  stepForReconcilationCleanupSchema.omit({ instructions: true, interruptions: true }),
  stepForReconcilationCommandSchema.omit({ instructions: true, interruptions: true }),
])

export const stepForReconcilationWithoutInterruptionsSchema = z.discriminatedUnion("source", [
  stepForReconcilationManualSchema.omit({ interruptions: true }),
  stepForReconcilationAutomaticSchema.omit({ interruptions: true }),
  stepForReconcilationCleanupSchema.omit({ interruptions: true }),
  stepForReconcilationCommandSchema.omit({ interruptions: true }),
])

export const reconcilationWorkerInputSchema = z.union([
  z.object({ type: z.literal("linkbox-config"), linkBoxId: z.string(), force: z.boolean().nullish() }),
  z.object({
    type: z.literal("inverter"),
    step: stepForReconcilationWithoutInterruptionsSchema,
  }),
])
