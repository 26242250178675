// New file: parseSolarmanV5Message.ts
import { assertUnreachable } from "@deltagreen/utils"

import { ModBusFunctionCode } from "../modbus/modbusProtocol"
import { ModBusReply, validateCheckSum } from "../modbus/utils"
import { SolarmanV5 } from "../SolarmanV5"

export function parseSolarmanV5Message(obuffer: Buffer, opts: { solarmanSerialNumber?: string }): ModBusReply {
  if (!opts.solarmanSerialNumber) {
    throw new Error("Solarman serial number not set!")
  }
  const s = new SolarmanV5(opts.solarmanSerialNumber)
  const unwrapped = s.unwrapModbusFrame(obuffer)

  const txId = unwrapped.sequenceNumber
  const buffer = unwrapped.modbusFrame

  const command = buffer.readUInt8(3 - 2) as ModBusFunctionCode
  const address = buffer.readUInt8(2 - 2)

  const response = buffer.subarray(2 - 2)
  const data = response.subarray(0, response.length - 2)
  const checksum = response.subarray(response.length - 2, response.length)

  const crcValid = validateCheckSum(data, checksum)
  if (!crcValid) {
    throw new Error("InvalidCRC")
  }

  switch (command) {
    case ModBusFunctionCode.WriteSingleHoldingRegister: {
      return {
        txId,
        address,
        command,
        data: buffer.subarray(6 - 2, 8 - 2),
      }
    }
    case ModBusFunctionCode.ReadInputRegisters:
    case ModBusFunctionCode.ReadMultipleHoldingRegisters: {
      const length = buffer.readUInt8(4 - 2)
      const data = buffer.subarray(5 - 2, 5 - 2 + length)
      return {
        txId,
        address,
        command,
        data,
      }
    }
    // todo tohle je asi spravne, jinde to bude blbe, ale prvne se to pouziva je u cuket
    case ModBusFunctionCode.WriteRegisters: {
      const x = {
        txId,
        address, // slave address
        command, // function code
        data: buffer.subarray(6 - 2, 8 - 2),
      }
      return x
    }

    case ModBusFunctionCode.WriteSingleHoldingRegisterError: {
      throw new Error(`Write error:${buffer.toString("hex")}`)
    }
    case ModBusFunctionCode.WriteMultipleRegisterError: {
      const errorCode = data.subarray(2, 3).readUInt8(0) as ModbusErrorCodeValue
      const error = getErrorMessage(errorCode)

      throw new Error(`Write error multi:${buffer.toString("hex")} errorCode: ${errorCode} ${error}`)
    }
    default:
      assertUnreachable(command)
  }
}

const ModbusErrorCode = {
  IllegalFunction: 0x01, // Funkční kód není podporován zařízením.
  IllegalDataAddress: 0x02, // Adresa registru není platná nebo přístupná.
  IllegalDataValue: 0x03, // Hodnota dat není povolena zařízením.
  SlaveDeviceFailure: 0x04, // Interní chyba zařízení, nelze dokončit operaci.
  Acknowledge: 0x05, // Požadavek přijat, ale zpracování trvá déle.
  SlaveDeviceBusy: 0x06, // Zařízení je zaneprázdněno a nemůže zpracovat požadavek.
  MemoryParityError: 0x08, // Paritní chyba v paměti zařízení.
  GatewayPathUnavailable: 0x0a, // Cesta k cílovému zařízení není dostupná.
  GatewayTargetFailedToRespond: 0x0b, // Cílové zařízení neodpovědělo.
} as const

// Typy pro klíče a hodnoty
type ModbusErrorCodeKey = keyof typeof ModbusErrorCode
type ModbusErrorCodeValue = (typeof ModbusErrorCode)[ModbusErrorCodeKey]

// Funkce pro získání zprávy chyby
function getErrorMessage(errorCode: ModbusErrorCodeValue): string {
  switch (errorCode) {
    case ModbusErrorCode.IllegalFunction:
      return "Funkční kód není podporován."
    case ModbusErrorCode.IllegalDataAddress:
      return "Adresa registru není platná."
    case ModbusErrorCode.IllegalDataValue:
      return "Hodnota dat není povolena."
    case ModbusErrorCode.SlaveDeviceFailure:
      return "Interní chyba zařízení."
    case ModbusErrorCode.Acknowledge:
      return "Požadavek přijat, zpracování trvá déle."
    case ModbusErrorCode.SlaveDeviceBusy:
      return "Zařízení je zaneprázdněno."
    case ModbusErrorCode.MemoryParityError:
      return "Paritní chyba v paměti."
    case ModbusErrorCode.GatewayPathUnavailable:
      return "Cesta k cílovému zařízení není dostupná."
    case ModbusErrorCode.GatewayTargetFailedToRespond:
      return "Cílové zařízení neodpovědělo."
    default:
      return "Neznámý kód chyby."
  }
}
