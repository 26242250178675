import { PrismaClientExtended } from "../prisma/createClient"

type GetCommandCodesParams = {
  distributorCode: string
  distributionTariff: string

  db: PrismaClientExtended
}

export async function getCommandCodes(params: GetCommandCodesParams) {
  const { db, distributionTariff, distributorCode } = params

  const commandCodes = await db.distributionCommandCode.findMany({
    where: { distributorCode, distributionTariff },
    select: { commandCode: true },
  })

  return commandCodes.map((code) => code.commandCode)
}
