import { Prisma } from "@prisma/client"
import merge from "deepmerge"

import { PrismaClientExtended } from "./prisma/createClient"

export async function hasAccessToInverter(prisma: PrismaClientExtended, inverterId: string, portalUserId: string) {
  const portalUser = await prisma.portalUser.findUnique({ where: { id: portalUserId } })
  const inverter = await prisma.inverter.findUnique({ select: { id: true }, where: { id: inverterId } })

  if (!inverter || !portalUser) {
    return { result: "NOT_FOUND" } as const
  }

  const householdRelation = await prisma.inverter.findFirst({
    where: { id: inverterId, household: { householdToUsers: { some: { portalUserId } } } },
  })

  if (!householdRelation) {
    return { result: "FORBIDDEN" } as const
  }

  return { result: "OK" } as const
}

export async function hasAccessToElectricVehicleDevice(
  prisma: PrismaClientExtended,
  electricVehicleId: string,
  portalUserId: string,
) {
  const portalUser = await prisma.portalUser.findUnique({ where: { id: portalUserId } })
  const electricVehicleDevice = await prisma.electricVehicle.findUnique({
    select: { id: true },
    where: { id: electricVehicleId },
  })

  if (!electricVehicleDevice || !portalUser) {
    return { result: "NOT_FOUND" } as const
  }

  const householdRelation = await prisma.electricVehicle.findFirst({
    where: { id: electricVehicleId, household: { householdToUsers: { some: { portalUserId } } } },
  })

  if (!householdRelation) {
    return { result: "FORBIDDEN" } as const
  }

  return { result: "OK" } as const
}

export async function getHouseholdsForUser(
  prisma: PrismaClientExtended,
  portalUserId: string,
  findManyParams?: Prisma.HouseholdFindManyArgs,
) {
  return await prisma.household.findMany(
    merge<Prisma.HouseholdFindManyArgs>(findManyParams ?? {}, {
      where: { householdToUsers: { some: { portalUserId } } },
    }),
  )
}
