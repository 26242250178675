import { StrictOmit } from "@deltagreen/utils"

import { Register, RWType } from "../../modbus/dataTypes"
import { createU16ToSingleEnumConvertor } from "../../modbus/enumUtils"

type RKey = string

export const SystemStatus = {
  Waiting: 0,
  CheckingGrid: 1,
  GridConnected: 2,
  GridUFP: 3,
  EmergencyPowerSupply: 4,
  RecoverableFault: 5,
  PermanentFault: 6,
  SelfCharging: 7,
} as const

export const systemStatus = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(SystemStatus),
    multiple: 1,
    length: 1,
  }
}

// Tohle neni v dokumentaci, ale Fedderico nam to poslal mailem :)
export const ModeControl = {
  SelfUse: 0,
  TOU: 1, // tohle tam bylo na zacatku
  TimingOfChargeAndDischarge: 2,
  Passive: 3,
  PeakShaving: 4,
  OffGrid: 5,
  Generator: 6,
} as const

export const modeControl = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(ModeControl),
    multiple: 1,
    length: 1,
  }
}

export type ZucchettiSpecificRegisters = ReturnType<typeof systemStatus> | ReturnType<typeof modeControl>
