import { Logger } from "@deltagreen/logger"
import { assertUnreachable, isNil } from "@deltagreen/utils"

export type UDPScanVendor = "goodwe" | "zucchetti" | "goodwe+zucchetti"

type UDPScanParams = {
  port: number
  payload: string
  ip: string
}

const goodweZucchettiScanParam = {
  port: 48899,
  payload: "..WIFIKIT-214028-READ",
  ip: "255.255.255.255",
}

const udpScanParams: Record<UDPScanVendor, UDPScanParams> = {
  goodwe: goodweZucchettiScanParam,
  zucchetti: goodweZucchettiScanParam,
  "goodwe+zucchetti": goodweZucchettiScanParam,
}

function isSolarmanSerialNumber(serialNumber: string) {
  // is 10 digit numeric string
  return /^\d{10}$/.test(serialNumber)
}

function getGoodweZucchettiUdpResult(reply: string, logger?: Logger) {
  // additionalParam differs between Goodwe and Zucchetti:
  // Goodwe: AP ssid (Solar_WiFiXXXXXX)
  // Zucchetti: Solarman serial number
  const [ip, mac, additionalParam] = reply.split(",")
  if (isNil(ip) || isNil(mac) || isNil(additionalParam)) {
    logger?.error({ ip, mac, additionalParam }, "Invalid Goodwe UDP scan reply")
    throw new Error("Invalid Goodwe UDP scan reply")
  }

  if (!ip.startsWith("..")) {
    logger?.error({ ip, mac, additionalParam }, "Invalid Goodwe UDP scan reply")
    throw new Error("Invalid Goodwe UDP scan result")
  }

  const ipAddress = ip.slice(2)
  if (!/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ipAddress)) {
    logger?.error({ ipAddress, mac, additionalParam }, "Invalid IP address received")
    throw new Error("Invalid IP address received")
  }

  const inverterMac = mac.match(/.{1,2}/g)?.join(":")
  if (isNil(inverterMac) || mac.length !== 12) {
    logger?.error({ ipAddress, mac, additionalParam }, "Invalid Inverter MAC address received")
    throw new Error("Invalid Inverter MAC address received")
  }

  return {
    ipAddress: ipAddress,
    macAddress: inverterMac,
    secondaryVendorId: additionalParam && isSolarmanSerialNumber(additionalParam) ? additionalParam : undefined,
  }
}

export function getUdpResult(reply: string, vendor: UDPScanVendor, logger?: Logger) {
  switch (vendor) {
    case "goodwe":
    case "zucchetti":
    case "goodwe+zucchetti":
      return getGoodweZucchettiUdpResult(reply, logger)
    default:
      return assertUnreachable(vendor)
  }
}

export function getUdpResultSafe(reply: string, vendor: UDPScanVendor, logger?: Logger) {
  try {
    return getUdpResult(reply, vendor, logger)
  } catch (error) {
    logger?.error({ error }, "Failed to parse UDP scan result")
    return null
  }
}

export function getUdpScanParams(vendor: UDPScanVendor) {
  return udpScanParams[vendor]
}
