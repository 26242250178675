import { f32, s16, s32, string, u16, u32 } from "../../../modbus/dataTypes"
import { appMode, emsPowerMode, time, workMode, workWeek } from "./dataTypes"

const GW10BaseRegisters = [
  u16({
    key: "modbusProtocolVersion",
    address: 35000,
    name: "Modbus protocol version",
    rw: "RO",
    unit: null,
    multiple: 1,
    remark: "",
  }),
  u16({
    key: "ratedPower",
    address: 35001,
    name: "Rated power",
    rw: "RO",
    unit: null,
    multiple: 1,
    remark: "",
  }),
  u16({
    key: "acOutputType",
    address: 35002,
    name: "AC output type",
    rw: "RO",

    unit: null,
    multiple: 1,
    remark: "0:single phase\n1: three phase four wire system\n2: three phase three wire system",
  }),
  string({
    key: "serialNumber",
    address: 35003,
    name: "Serial number",
    rw: "RO",
    length: 8,
    remark: "ASCII 16 bytes",
  }),
  string({
    key: "deviceType",
    address: 35011,
    name: "Device type",
    rw: "RO",
    length: 5,
    remark: "ASCII 10 bytes",
  }),

  string({
    key: "firmwareNeco",
    address: 35021,
    name: "nevim",
    rw: "RO",
    length: 10,
    // remark: "ASCII 10 bytes",
  }),

  u32({
    key: "pv1Power",
    address: 35105,
    name: "Ppv1",
    unit: "W",
    rw: "RO",
  }),
  u32({
    key: "pv2Power",
    address: 35109,
    name: "Ppv2",
    unit: "W",
    rw: "RO",
  }),
  u32({
    key: "pv3Power",
    address: 35113,
    name: "Ppv3",
    unit: "W",
    rw: "RO",
  }),
  u32({
    key: "pv4Power",
    address: 35117,
    name: "Ppv4",
    unit: "W",
    rw: "RO",
  }),

  s16({
    key: "acActivePower",
    address: 35140,
    name: "AC ActivePower",
    unit: "W",
    rw: "RO",
  }),

  s16({
    key: "acReactivePower",
    address: 35142,
    name: "AC ReactivePower",
    unit: "Var",
    rw: "RO",
  }),

  s16({
    key: "acApparentPower",
    address: 35144,
    name: "AC ApparentPower",
    unit: "VA",
    rw: "RO",
  }),

  u32({
    key: "pvEnergyTotal",
    address: 35191,
    name: "PV E-Total",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Total PV Energy",
  }),
  u32({
    key: "pvEnergyToday",
    address: 35193,
    name: "PV E-Day",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "PV engery in today",
  }),

  s32({
    key: "MTTotalActivePower",
    address: 36025,
    name: "Pmeter",
    unit: "W",
    rw: "RO",
  }),

  u32({
    key: "toGridEnergyTotal",
    address: 35195,
    name: "E-Total",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Total Feed Energy to grid",
  }),

  u32({
    key: "totalFeedingHours",
    address: 35197,
    name: "h-Total",
    unit: "H",
    multiple: 1,
    rw: "RO",
    remark: "Total feeding hour",
  }),

  u16({
    key: "toGridEnergyToday",
    address: 35199,
    name: "E-Total",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Feed Engery to grid in today",
  }),

  u32({
    key: "fromGridEnergyTotal",
    address: 35200,
    name: "E-Total-Buy",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "",
  }),

  u16({
    key: "fromGridEnergyToday",
    address: 35202,
    name: "E-Day-Buy",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "",
  }),

  u32({
    key: "energyTotalLoad",
    address: 35203,
    name: "E-Total-Load",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Total Energy of Load",
  }),

  u16({
    key: "energyTodayLoad",
    address: 35205,
    name: "E-Day-Load",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Energy of load in day",
  }),

  u32({
    key: "energyBatteryChargeTotal",
    address: 35206,
    name: "E-BatteryCharge",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Charge energy",
  }),

  u16({
    key: "energyBatteryChargeToday",
    address: 35208,
    name: "E-Charge-Day",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Energy of charge in day",
  }),

  u32({
    key: "energyBatteryDischargeTotal",
    address: 35209,
    name: "E-BatteryDischarge",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Discharge energy",
  }),

  u16({
    key: "energyBatteryDischargeToday",
    address: 35211,
    name: "E-Discharge-Day",
    unit: "kWh",
    multiple: 10,
    rw: "RO",
    remark: "Energy of discharge in day",
  }),

  u16({
    key: "batteryStrings",
    address: 35212,
    name: "BatStrings",
    unit: "Pcs",
    multiple: 1,
    rw: "RO",
  }),

  u16({
    key: "dsp1SoftwareWersion",
    address: 35016,
    name: "DSP1 software version",
    rw: "RO",
    unit: null,
    multiple: 1,
    remark: "",
  }),
  u16({
    key: "dsp2SoftwareWersion",
    address: 35017,
    name: "DSP2 software version",
    rw: "RO",
    unit: null,
    multiple: 1,
    remark: "",
  }),
  u16({
    key: "armVersion",
    address: 35019,
    name: "TODO",
    rw: "RO",
    unit: null,
    multiple: 1,
    remark: "",
  }),
  u16({
    key: "voltagePhaseR",
    address: 35121,
    name: "Vgrid_R",
    rw: "RO",
    unit: "V",
    multiple: 10,
    remark: "R phase Grid voltage",
  }),
  // todo dalsi volty

  u16({
    key: "l1PowerLimit",
    address: 47542,
    name: "L1 Power Limit",
    rw: "RO",
    unit: "V",
    multiple: 1,
  }),

  s16({
    key: "totalInverterPower",
    address: 35138,
    name: "Total INV Power",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Total Power of inverter",
  }),

  s16({
    key: "totalLoadPower",
    address: 35172,
    name: "Total Load Power",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Total Power of load",
  }),
  u16({
    key: "voltageBattery1",
    address: 35180,
    name: "VBattery1",
    rw: "RO",
    unit: "V",
    multiple: 10,
    remark: "First group battery current",
  }),
  s16({
    key: "currentBattery1",
    address: 35181,
    name: "IBattery1",
    rw: "RO",
    unit: "A",
    multiple: 10,
    remark: "First group battery current",
  }),
  s16({
    key: "powerBattery1",
    address: 35183,
    name: "Pbattery1",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "First group battery power",
  }),
  s32({
    key: "mtTotalReactivePower",
    address: 36033,
    name: "MTTotalReactivePower",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Total reactive power",
  }),

  f32({
    key: "energyTotalSell",
    address: 36015,
    name: "E-Total-Sell",
    rw: "RO",
    unit: "kWh",
    multiple: 1000,
    remark: "",
  }),
  f32({
    key: "energyTotalBuy",
    address: 36017,
    name: "E-Total-Buy",
    rw: "RO",
    unit: "kWh",
    multiple: 1000,
    remark: "",
  }),

  s32({
    key: "mtTotalApparentPower",
    address: 36041,
    name: "MTTotalApparentPower",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Total apparent power",
  }),

  u16({
    key: "mSoc",
    address: 45356,
    name: "BattSOCUnderMin",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "[0,100]",
  }),

  u16({
    key: "bmsStatus",
    address: 37002,
    name: "BMS Status",
    rw: "RO",
    multiple: 1,
    remark: "BMS Work status",
  }),

  u16({
    key: "bmsPackTemperature",
    address: 37003,
    name: "BMS Pack Temperature",
    rw: "RO",
    unit: "°C",
    multiple: 10,
    remark: "",
  }),

  u16({
    key: "bmsChargeIMax",
    address: 37004,
    name: "BMS Charge Imax",
    rw: "RO",
    unit: "A",
    multiple: 1,
    remark: "",
  }),
  u16({
    key: "bmsDischargeIMax",
    address: 37005,
    name: "BMS Discharge Imax",
    rw: "RO",
    unit: "A",
    multiple: 1,
    remark: "",
  }),

  u16({
    key: "bmsErrorCodeL",
    address: 37006,
    name: "BMS Error Code L",
    rw: "RO",
    remark: "Bti 0-16 refer ot Table 8-7",
  }),

  // je divnej
  // u16({
  //   key: "bmsErrorCodeH",
  //   address: 370012,
  //   name: "BMS Error Code H",
  //   rw: "RO",
  //   remark: "",
  // }),

  u16({
    key: "soc",
    address: 37007,
    name: "SOC",
    rw: "RO",
    unit: "%",
    multiple: 1,
    remark: "First group battery power",
  }),

  u16({
    key: "bmsSoh",
    address: 37008,
    name: "BMS SOH",
    rw: "RO",
    unit: "%",
    multiple: 1,
    remark: "",
  }),

  u16({
    key: "maximumCellVoltage",
    address: 37022,
    name: "Maximum Cell Voltage",
    rw: "RO",
    unit: "V",
    multiple: 1000,
    remark: "",
  }),

  u16({
    key: "minimumCellVoltage",
    address: 37023,
    name: "Minimum Cell Voltage",
    rw: "RO",
    unit: "V",
    multiple: 1000,
    remark: "",
  }),

  // settings

  string({
    key: "routerSSID",
    address: 45024,
    length: 30,
    name: "Router SSID",
    rw: "RW",
  }),
  string({
    key: "routerPassword",
    address: 45054,
    length: 20,
    name: "Router pass",
    rw: "RW",
  }),

  string({
    key: "routerEncryptionMethod",
    address: 45074,
    length: 1,
    name: "Router encrytion",
    rw: "RW",
  }),
  string({
    key: "domain1",
    address: 45075,
    length: 25,
    name: "Domain 1",
    rw: "RW",
  }),
  u16({
    key: "portNumber1",
    address: 45100,
    name: "portNumber1",
    rw: "RW",
  }),
  string({
    key: "domain2",
    address: 45101,
    length: 25,
    name: "Domain 2",
    rw: "RW",
  }),
  u16({
    key: "portNumber2",
    address: 45126,
    name: "portNumber2",
    rw: "RW",
  }),
  u16({
    key: "modbusAddress",
    address: 45127,
    name: "modbusAdress",
    rw: "RW",
  }),

  string({
    key: "manufacturer",
    address: 45128,
    length: 4,
    name: "manufacturer",
    rw: "RW",
  }),

  u16({
    key: "batChargeCurMax",
    address: 45353,
    name: "BatChargeCurMax",
    rw: "RW",
    multiple: 10,
    remark: "[0,10000]",
  }),
  u16({
    key: "batDisChgCurMax",
    address: 45355,
    name: "BatDisChgCurMax",
    rw: "RW",
    multiple: 10,
    remark: "[0,10000]",
  }),

  appMode({
    key: "appMode",
    address: 47000,
    name: "AppModeIndex",
    rw: "RW",
    remark: "Refer to table 8-16",
  }),

  u16({
    key: "modbusTCPWithoutInternet",

    address: 47017,
    name: "Modbus TCP Without Internet",
    rw: "RW",
    remark: "[0,1]",
  }),

  u16({
    key: "restart",
    address: 45220,
    name: "Inverter will recheck and reconnect to utility again. Inverter does not shutdown.",
    rw: "WO",
    remark: "[1]",
  }),

  u16({
    key: "wifiModbusTCPEnable",

    address: 47040,
    name: "WIFI Modbus TCP Enable",
    rw: "RW",
    remark: "[0,1]",
  }),

  emsPowerMode({
    key: "emsPowerMode",
    address: 47511,
    name: "EMSPowerMode",
    rw: "RW",
    remark: "Refer to table 8-16",
  }),

  u16({
    key: "emsPowerSet",
    address: 47512,
    name: "EMSPowerSet",
    rw: "RW",
    remark: "[0,10000]",
  }),

  time({
    key: "ecoMode1Start",
    address: 47515,
    name: "StartTime_1",
    rw: "RW",
  }),
  time({
    key: "ecoMode1End",
    address: 47516,
    name: "EndTime_1",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  s16({
    // podle doc u16
    key: "ecoMode1Percent",
    address: 47517,
    name: "BatPowerPercent1",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "[-100, 100]",
  }),
  u16({
    key: "ecoMode1Soc",
    address: 47551,
    name: "wireshark",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "wireshark",
  }),

  // TODO tabulka 8-1 enum
  workMode({
    key: "workMode",
    address: 35187,
    name: "workmode",
    rw: "RW",
    unit: "",
    multiple: 1,
    remark: "table 8-1",
  }),

  // TODO operation mode 35188

  workWeek({
    key: "ecoMode1WorkWeek",
    address: 47518,
    name: "WorkWeek_1",
    rw: "RW",
    remark: "table 8-20",
  }),

  time({
    key: "ecoMode2Start",
    address: 47519,
    name: "StartTime_2",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  time({
    key: "ecoMode2End",
    address: 47520,
    name: "EndTime_2",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  s16({
    // podle doc u16
    key: "ecoMode2Percent",
    address: 47521,
    name: "BatPowerPercent2",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "[-100, 100]",
  }),
  u16({
    key: "ecoMode2Soc",
    address: 47557,
    name: "wireshark",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "wireshark",
  }),

  workWeek({
    key: "ecoMode2WorkWeek",
    address: 47522,
    name: "WorkWeek_2",
    rw: "RW",
    remark: "table 8-20",
  }),

  time({
    key: "ecoMode3Start",
    address: 47523,
    name: "StartTime_3",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  time({
    key: "ecoMode3End",
    address: 47524,
    name: "EndTime_3",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  s16({
    // podle doc u16
    key: "ecoMode3Percent",
    address: 47525,
    name: "BatPowerPercent3",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "[-100, 100]",
  }),

  u16({
    key: "ecoMode3Soc",
    address: 47563,
    name: "wireshark",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "wireshark",
  }),

  workWeek({
    key: "ecoMode3WorkWeek",
    address: 47526,
    name: "WorkWeek_3",
    rw: "RW",
    remark: "table 8-20",
  }),

  time({
    key: "ecoMode4Start",
    address: 47527,
    name: "StartTime_4",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  time({
    key: "ecoMode4End",
    address: 47528,
    name: "EndTime_4",
    rw: "RW",
    remark: "[0,23],[0,59]",
  }),
  s16({
    // podle doc u16
    key: "ecoMode4Percent",
    address: 47529,
    name: "BatPowerPercent4",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "[-100, 100]",
  }),
  u16({
    key: "ecoMode4Soc",
    address: 47569,
    name: "wireshark",
    rw: "RW",
    unit: "%",
    multiple: 1,
    remark: "wireshark",
  }),

  workWeek({
    key: "ecoMode4WorkWeek",
    address: 47530,
    name: "WorkWeek_4",
    rw: "RW",
    remark: "table 8-20",
  }),

  u16({
    key: "feedPowerEnable3P",
    address: 47534,
    name: "3Phase FeedPowerEnable",
    rw: "RW",
    remark: "[0,1]",
  }),

  u16({
    key: "feedPowerParaR",
    address: 47535,
    name: "",
    rw: "RW",
    remark: "[0,5000]",
  }),
  u16({
    key: "feedPowerParaS",
    address: 47536,
    name: "",
    rw: "RW",
    remark: "[0,5000]",
  }),
  u16({
    key: "feedPowerParaT",
    address: 47537,
    name: "",
    rw: "RW",
    remark: "[0,5000]",
  }),

  u16({
    key: "armFeedPowerEnable",
    address: 47509,
    name: "",
    rw: "RW",
    remark: "[0,1]",
  }),

  u16({
    key: "armFeedPowerPara",
    address: 47510,
    name: "FeedPowerPara",
    rw: "RW",
    remark: "[0,10000]",
  }),

  u16({
    key: "bmsStatus485",
    address: 47915,
    name: "BMSStatus",
    rw: "RW",
    multiple: 1,
    remark: "Bit set 1means: \nBit: force to charge\nBit1: Stop charging\nBit2: Stop discharging",
  }),

  u32({
    key: "flashPgmWriteCount",
    address: 36901,
    name: "FlashPgmWriteCount",
    unit: "",
    rw: "RO",
  }),

  u32({
    key: "flashSysWriteCount",
    address: 36904,
    name: "FlashSysWriteCount",
    unit: "",
    rw: "RO",
  }),

  u32({
    key: "flashBatWriteCount",
    address: 36907,
    name: "FlashBatWriteCount",
    unit: "",
    rw: "RO",
  }),

  u32({
    key: "flashEepromWriteCount",
    address: 36910,
    name: "FlashEepromWriteCount",
    unit: "",
    rw: "RO",
  }),

  s32({
    key: "totalBackupLoadPower",
    address: 35169,
    name: "Total Back-Up Load Power",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Load Power of Back-Up",
  }),
]

// type GoodweBaseRegisterKeys = (typeof GW10BaseRegisters)[number]["key"]
// const calculated = createCalculated<GoodweBaseRegisterKeys>()

// const GW10CalculatedRegisters = [
//   calculated({
//     key: "pvPowerTotal",
//     name: "PV Power Total",
//     unit: "W",
//     calculate: (data) => {
//       return [data.pv1Power, data.pv2Power, data.pv3Power, data.pv4Power]
//         .filter(isNotNil)
//         .reduce<number | undefined>((acc, cur) => (acc ?? 0) + cur.value, undefined)
//     },
//   }),
// ]

// export const GW10Registers = [...GW10BaseRegisters, ...GW10CalculatedRegisters]

export const GW10Registers = GW10BaseRegisters
