import { isNil, isNotNil, randomUInt16WithTrailingZerosInSecondByte } from "@deltagreen/utils"

import { Device, GenericDevicesRegister } from "../devices"
import { ModBusFunctionCode } from "../modbus/modbusProtocol"
import { EmodbusMessage } from "../proto/deltalink"

type Register = {
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type RegisterTypesRW = Device<GenericDevicesRegister>["registers"][number]["rw"][]

export function buildModbusMessageFromRegisters<TDevice extends Device<GenericDevicesRegister>>(
  device: TDevice,
  registerValues: Register[],
  registersFilter?: RegisterTypesRW,
  requestId?: number,
): EmodbusMessage {
  if (registerValues.length === 0) {
    throw new Error("No registers to create message from")
  }

  // Find all registers, convert values to Buffers and sort them by address
  const registers = registerValues
    .map((registerValue) => {
      const deviceRegisters = registersFilter
        ? device.registers.filter((r) => registersFilter.includes(r.rw))
        : device.registers
      const register = deviceRegisters.find((register) => register.key === registerValue.key)
      if (!register) {
        return null
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = register.convertor.encode(registerValue.value, register)

      return {
        register,
        data,
      }
    })
    .filter(isNotNil)
    .sort((a, b) => a.register.address - b.register.address)

  if (registers.length !== registerValues.length) {
    // find missting registers
    const missingRegisters = registerValues
      .filter((registerValue) => !registers.find((r) => r.register.key === registerValue.key))
      .map((r) => r.key)
      .join(", ")

    throw new Error("Some registers were not found: " + missingRegisters)
  }

  if (!registers[0]) {
    throw new Error("No registers found")
  }

  const firstRegister = registers[0].register

  // Fill gaps between registers with zeros

  // start with an empty buffer
  let wholeData = Buffer.from([])

  // iterate over all registers
  for (let i = 0; i < registers.length; i++) {
    const register = registers[i]

    if (!register) {
      throw new Error("Register not found")
    }

    if (register.register.functionCode !== firstRegister.functionCode) {
      throw new Error("Function codes do not match")
    }

    // append register data to the buffer
    wholeData = Buffer.concat([wholeData, register.data])

    const nextRegister = registers[i + 1]

    // if there is next register to be added fill the gap with zeros
    if (nextRegister) {
      const gap = nextRegister.register.address - (register.register.address + register.register.length)
      if (gap > 0) {
        wholeData = Buffer.concat([wholeData, Buffer.alloc(gap * 2)])
      }
    }
  }

  if (wholeData.length > 128) {
    throw new Error(`Memory requested is too big: ${wholeData.length}`)
  }

  const _requestId = isNil(requestId) ? randomUInt16WithTrailingZerosInSecondByte() : requestId

  return EmodbusMessage.fromPartial({
    address: firstRegister.address,
    functionCode: firstRegister.functionCode ?? ModBusFunctionCode.ReadMultipleHoldingRegisters,
    requestId: _requestId,
    data: wholeData,
  })
}

export function createModbusMessageFromRegisters<TDevice extends Device<GenericDevicesRegister>>(
  device: TDevice,
  registerValues: Register[],
): Buffer {
  const message = buildModbusMessageFromRegisters(device, registerValues)
  const messageBuffer = EmodbusMessage.encode(message).finish()

  return Buffer.from(messageBuffer)
}
