import { PrismaClientExtended } from "../prisma/createClient"

type GetDistributionTimesParams = {
  distributorCode: string
  distributionTariff: string
  commandCode: string

  db: PrismaClientExtended
}

export async function getDistributionTimesData(params: GetDistributionTimesParams) {
  const { db, distributionTariff, commandCode, distributorCode } = params

  const distributionTimes = await db.lastDistributionTime.findMany({
    where: { distributorCode, distributionTariff, commandCode },
  })

  return distributionTimes
}
