import { StrictOmit } from "@deltagreen/utils"

import { ModBusFunctionCode } from "./modbusProtocol"

type DecodeFunction<RT> = (data: Buffer, register: RegisterBase) => { value: RT; debug: string }

export type EncodeFunction<RT> = (from: RT, register: RegisterBase) => Buffer

export interface ModbusRegisterConversion<ReturnT> {
  encode: EncodeFunction<ReturnT>
  decode: DecodeFunction<ReturnT>
}

type RKey = string

export type RWType = "RW" | "RO" | "WO"

interface RegisterBase {
  multiple?: number
  unit?: string | null
  remark?: string | null
  calculated?: boolean
  targetDevice?: string
}

export interface Register<T extends string, RW extends RWType, ReturnT> extends RegisterBase {
  key: T
  address: number
  name: string
  rw: RW
  length: number
  convertor: ModbusRegisterConversion<ReturnT>
  functionCode?: ModBusFunctionCode | undefined // ReadRegisterFunctionCode
  calculate?: (data: Record<RKey, { value: number }>) => ReturnT
  // __type: "Register";
}

// interface DeclaredRegister {
//   __type: "Register";
// }

export const declareRegister = <T extends RKey, RW extends RWType, ReturnT>(
  r: Omit<Register<T, RW, ReturnT>, "__type">,
) => {
  return r as Register<T, RW, ReturnT>
}

export const string = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, convertor: stringConvertor })
}

export const u8H = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 1, convertor: u8Hconvertor })
}

export const u8L = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 1, convertor: u8Lconvertor })
}

export const u16 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 1, convertor: u16convertor })
}
export const s16 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 1, convertor: s16convertor })
}

export const s32 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 2, convertor: s32convertor })
}

export const u32 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 2, convertor: u32convertor })
}

export const u64 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 4, convertor: u64convertor })
}

export const s64 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 4, convertor: s64convertor })
}

export const f32 = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 2, convertor: f32convertor })
}

export const u32LSB = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 2, convertor: u32convertorLSB })
}

export const s32LSB = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "length" | "convertor" | "calculate">,
) => {
  return declareRegister({ ...r, length: 2, convertor: s32convertorLSB })
}

export function createConvertor<RT>(
  encode: EncodeFunction<RT>,
  decode: DecodeFunction<RT>,
): ModbusRegisterConversion<RT> {
  return { encode, decode }
}

const s16convertor = createConvertor<number>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }
    const buffer = Buffer.allocUnsafe(2)
    buffer.writeInt16BE(o)
    return buffer
  },
  (data, register) => {
    const d = data.readInt16BE(0)
    const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const u32convertor = createConvertor<number>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }

    const buffer = Buffer.allocUnsafe(4)
    buffer.writeUInt32BE(o)
    return buffer
  },
  (data, register) => {
    const d = data.readUInt32BE(0)
    const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const u64convertor = createConvertor<bigint>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }
    const buffer = Buffer.allocUnsafe(8)
    buffer.writeBigInt64BE(o)
    return buffer
  },
  (data, register) => {
    const d = data.readBigUInt64BE(0)
    const numberValue = d / BigInt(register.multiple || 1)
    // const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const s64convertor = createConvertor<bigint>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }
    const buffer = Buffer.allocUnsafe(8)
    buffer.writeBigInt64BE(o)
    return buffer
  },
  (data, register) => {
    const d = data.readBigInt64BE(0)
    const numberValue = d / BigInt(register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const f32convertor = createConvertor<number>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }
    const buffer = Buffer.allocUnsafe(4)
    buffer.writeFloatBE(o)
    return buffer
  },
  (data, register) => {
    const d = data.readFloatBE(0)
    const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

export const s32convertorLSB = createConvertor<number>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }

    const buffer = Buffer.allocUnsafe(4)
    buffer.writeInt32BE(o)
    return Buffer.concat([buffer.subarray(2, 4), buffer.subarray(0, 2)])
    // return buffer
  },
  (data, register) => {
    const b = Buffer.concat([data.subarray(2, 4), data.subarray(0, 2)])
    // console.log(b)
    const d = b.readInt32BE(0)
    // console.log(d)
    const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const u32convertorLSB = createConvertor<number>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }

    const buffer = Buffer.allocUnsafe(4)
    buffer.writeUInt32LE(o)
    return buffer
  },
  (data, register) => {
    const LSB = data.readUInt16BE(0)
    const MSB = data.readUInt16BE(2)
    const d = LSB + (MSB << 8)

    // const b = Buffer.concat([data.subarray(0, 2).reverse(), data.subarray(2, 4)])
    // console.log(data, d)
    // const d = data.readUInt32LE(0)
    // console.log(data.reverse())

    const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const s32convertor = createConvertor<number>(
  (o, register) => {
    // TODO multiple
    if ((register.multiple || 1) != 1) {
      throw new Error("Nekdo to musi naprogramovat, kuk na u16")
    }

    const buffer = Buffer.allocUnsafe(4)
    buffer.writeInt32BE(o)
    return buffer
    // throw new Error("Not implemented")
  },
  (data, register) => {
    const d = data.readInt32BE(0)
    const numberValue = d / (register.multiple || 1)
    return {
      ...register,
      value: numberValue,
      debug: `${numberValue}${register.unit}`,
    }
  },
)

const u16convertor = createConvertor<number>(
  (x, register) => {
    const numberValue = x * (register.multiple || 1)

    const buffer = Buffer.allocUnsafe(2)
    buffer.writeUInt16BE(numberValue)
    return buffer
  },
  (data: Buffer, register: RegisterBase) => {
    // TODO UINT  ??
    const d = data.readInt16BE(0)
    const numberValue = d / (register.multiple || 1)
    return {
      value: numberValue,
      debug: `${numberValue}${register.unit ? register.unit : ""}`,
    }
  },
)

const u8Lconvertor = createConvertor<number>(
  () => {
    throw new Error("Nemuzes zapisovat pul bajtu, pouzij u16")
  },
  (data: Buffer, register: RegisterBase) => {
    const d = data.readInt8(1)
    const numberValue = d / (register.multiple || 1)
    return {
      value: numberValue,
      debug: `${numberValue}${register.unit ? register.unit : ""}`,
    }
  },
)

const u8Hconvertor = createConvertor<number>(
  () => {
    throw new Error("Nemuzes zapisovat pul bajtu, pouzij u16")
  },
  (data: Buffer, register: RegisterBase) => {
    const d = data.readInt8(0)
    const numberValue = d / (register.multiple || 1)
    return {
      value: numberValue,
      debug: `${numberValue}${register.unit ? register.unit : ""}`,
    }
  },
)

const stringConvertor = createConvertor(
  () => {
    throw new Error("not noimiplplp")
  },
  (data: Buffer) => {
    let value = data.toString().trim()

    if (value.indexOf("\0") !== -1) {
      value = value.substring(0, value.indexOf("\0"))
    }

    return { value, debug: value }
  },
)

const notImplementedConvertor = createConvertor<number>(
  () => {
    throw new Error("Not implemented")
  },
  () => {
    throw new Error("Not implemented")
  },
)

export const createCalculated = <BaseRKey extends RKey>() => {
  return <T extends string, RW extends RWType, ReturnT>(
    r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length" | "rw" | "address"> & {
      calculate: (data: Record<BaseRKey, { value: number }>) => number | undefined
    },
  ) => {
    return {
      ...r,
      rw: "RO" as RWType,
      calculated: true,
      address: 0x0,
      length: 0,
      convertor: notImplementedConvertor,
    }
  }
}
