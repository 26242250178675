import { createLogger } from "@deltagreen/logger"
import Decimal from "decimal.js"

import {
  Block,
  BlockReservation,
  Command,
  Household,
  Inverter,
  InverterLastState,
  InverterPhotovoltaicInstallation,
  InverterStateV2,
  LinkBox,
  PriceList,
  Tenant,
} from ".."

export const testLogger = createLogger({ logLevel: "fatal", pretty: false })

type InverterWithInstallations = Inverter & { inverterPhotovoltaicInstallations: InverterPhotovoltaicInstallation[] }

export function buildInverter(override?: Partial<InverterWithInstallations>): InverterWithInstallations {
  return {
    id: "some-inverter-id",
    accessRights: "READ_WRITE",
    linkBoxId: null,
    initializedAt: null,
    accessToken: null,
    amortizationCzkPerkWh: 1.5,
    inverterCredentialsId: null,
    batteryCapacity: null,
    batteryMaxChargePower: null,
    batteryMaxDischargePower: null,
    batteryMinimumStateOfCharge: new Decimal(0.2),
    controlEnabled: true,
    controlEnabledChangedAt: new Date(),
    controlMode: "AUTOMATIC",
    createdAt: new Date(),
    electricityPriceMarginCzkPerkWh: 0.45,
    fixedConsumptionPriceCzkPerkWh: null,
    featureFlags: [],
    gridOverflowEnabled: true,
    householdId: "some-household-id",
    maxGridOverflowPower: null,
    ipAddress: null,
    postalCode: null,
    modbusAddress: null,
    macAddress: null,
    port: null,
    manufacturer: "VICTRON_ENERGY",
    model: null,
    name: null,
    hostname: null,
    secondaryVendorId: null,
    updatedAt: new Date(),
    vendorId: "vendor-id",
    vendorName: null,
    collectData: true,
    dongleVersion: null,
    circuitBreakerPhases: null,
    gridCurrentLimit: null,
    controlsVisible: true,
    longitude: null,
    latitude: null,
    elevation: null,
    userCircuitBreakerSettings: false,
    inverterPhotovoltaicInstallations: [],
    predictionSources: [
      "CONSUMPTION_NANOGREEN",
      "CONSUMPTION_PROTEUS",
      "CONSUMPTION_VICTRON_ENERGY",
      "PRODUCTION_FORECAST_SOLAR",
      "PRODUCTION_VICTRON_ENERGY",
    ],
    addressSource: null,
    street: null,
    streetNumber: null,
    city: null,
    country: null,

    cbpRegistered: false,
    cbpVenFingerprint: null,
    cbpVenId: null,
    cbpStatus: "NONE",
    markedAsDeadAt: null,
    blockId: null,
    svrId: null,
    linkBoxBridgeEnabled: false,
    ...override,
  }
}

export function buildValidInverter(id: string, override?: Partial<InverterWithInstallations>) {
  // all settings entered
  return buildInverter({
    id,
    householdId: "h1",
    manufacturer: "VICTRON_ENERGY",
    vendorId: "victron",
    vendorName: "victron",
    secondaryVendorId: "secondary-victron",
    collectData: true,
    initializedAt: new Date(),
    //
    latitude: new Decimal(49.8),
    longitude: new Decimal(15.1),
    elevation: new Decimal(10), // ?
    street: "Vltavínová",
    streetNumber: "3",
    city: "Plzeň",
    postalCode: "32600",
    country: "ČR",
    //
    controlMode: "AUTOMATIC",
    controlEnabled: true,
    featureFlags: [],
    batteryCapacity: new Decimal(1000),
    amortizationCzkPerkWh: 5,
    batteryMinimumStateOfCharge: new Decimal(20),
    batteryMaxChargePower: new Decimal(10000),
    batteryMaxDischargePower: new Decimal(10000),
    gridOverflowEnabled: true,
    maxGridOverflowPower: new Decimal(100),
    gridCurrentLimit: 1000,
    circuitBreakerPhases: 1,
    //
    inverterPhotovoltaicInstallations: [
      {
        id: "installation-1",
        name: "Strecha zapad",
        azimuth: new Decimal(100),
        declination: new Decimal(45),
        peakPower: new Decimal(10000),
        vendorId: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        inverterId: id,
      },
    ],
    ...override,
  })
}

export function buildHouseholdWithTenant(
  overrideHousehold?: Partial<Household>,
  overrideTenant?: Partial<Tenant>,
): Household & { tenant: Tenant } {
  return {
    id: "some-household-id",
    commandCodeScanState: "PENDING",
    name: null,
    opmId: null,
    tenantId: "TID_DELTA_GREEN",
    vendorId: null,
    createdAt: new Date(),
    updatedAt: new Date(),
    commandCode: null,
    featureFlags: [],
    initializedAt: new Date(),
    sendEventsToSlack: false,
    commandCodeScannedAt: null,
    automaticControlEnabledOverride: null,
    flexibilityState: "NOT_USABLE",
    flexibilityEnabled: false,
    flexibilityCapabilities: [],
    flexibilityCapabilitiesEnabled: [],
    flexibilityFirstAllowedAt: null,
    termsAcceptedAt: null,
    termsAcceptedByPortalUserId: null,
    priceListTypeId: null,
    tenant: buildTenant(overrideTenant),
    ...overrideHousehold,
  }
}

export function buildInverterLastState(override?: Partial<InverterLastState>): InverterLastState {
  return {
    time: new Date(),
    inverterId: "some-inverter-id",

    acExcessFeedInEnabled: null,
    batteryCurrent: null,
    batteryMaxChargeCurrent: null,
    batteryInEnergy: null,
    batteryMaxChargePower: null,
    batteryMaxChargeVoltage: null,
    batteryMaxDischargeCurrent: null,
    batteryMaxDischargePower: null,
    batteryMinimumStateOfCharge: null,
    batteryOutEnergy: null,
    batteryPower: null,
    batteryStateOfCharge: null,
    batteryTemperature: null,
    batteryVoltage: null,
    consumptionEnergy: null,
    consumptionPower: null,
    dcExcessFeedInEnabled: null,
    gridCurrent: null,
    gridCurrentLimit: null,
    gridInEnergy: null,
    gridOutEnergy: null,
    gridPower: null,
    photovoltaicEnergy: null,
    photovoltaicPower: null,
    powerSetpoint: null,
    lockState: null,
    modbusAddress: null,

    ...override,
  }
}

export function buildLinkBox(override?: Partial<LinkBox>): LinkBox {
  return {
    id: "some-link-box-id",
    notes: null,
    tags: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    macAddress: "",
    mqttUserId: null,
    description: null,
    householdId: null,
    markedAsDeadAt: null,
    healingDisabled: false,
    requiredFirmware: null,
    collectingTelemeteryStartedNotificationSentAt: null,
    partner: "DELTA_GREEN",
    scanDisabled: false,
    // enable/disable DL configuration reconciliation
    // leave disabled in local and dev environments to prevent unnecessary writes to persistent storage of link box
    // enable if necessary for testing (should be enabled in one environment at a time)
    configVersion: "some-config-version",
    boardType: "ESP32",
    ...override,
  }
}

export function buildTenant(override?: Partial<Tenant>): Tenant {
  const now = new Date()
  return {
    defaultLocale: "cs",
    id: "some-tenant-id",
    supportedLocales: ["cs", "en"],
    createdAt: now,
    name: "tenant",
    updatedAt: now,
    daktelaAccountId: null,
    tenantFeatureSet: "FLEXIBILITY_ONLY",
    hasHouseholdIntro: false,
    allowMultipleLanguages: false,
    defaultFlexibilityState: "NOT_USABLE",
    hasRequiredTermsForUsers: false,
    defaultFlexibilityEnabled: false,
    defaultFlexibilityCapabilities: ["UP_POWER", "DOWN_SOLAR_CURTAILMENT_POWER", "DOWN_BATTERY_POWER"],
    persistInvertersOnCommands: false,
    availableFlexibilityNullThreshold: null,
    isAutomaticCommandManagementEnabled: false,
    isAutomaticCommandManagementVisible: false,
    syncInverterSettingsFromRealTimeData: false,
    inverterControlPercentageCalculationType: "ALL_TIME",
    checkFlexibilityStateOnInverterInitialization: false,
    inverterCommandMinimalAvailableFlexibilityThreshold: null,
    flexibilityActivationPriceCalculationType: null,
    ...override,
  }
}

export function buildInverterStateV2(override?: Partial<InverterStateV2>): InverterStateV2 {
  const now = new Date()
  return {
    time: now,
    createdAt: now,
    batteryPower: null,
    inverterId: "inverter-id",

    totalBackupLoadPower: null,
    acExcessFeedInEnabled: null,
    activePowerReal: null,
    activePowerTarget: null,
    armFeedPowerEnable: null,
    armFeedPowerPara: null,
    backupConsumptionPower: null,
    backupSocMin: null,
    batteryCurrent: null,
    batteryInEnergy: null,
    batteryMaxChargeCurrent: null,
    batteryMaxChargePower: null,
    batteryMaxChargeVoltage: null,
    batteryMaxDischargeCurrent: null,
    batteryMaxDischargePower: null,
    batteryMinimumStateOfCharge: null,
    batteryOutEnergy: null,
    batteryStateOfCharge: null,
    batteryTemperature: null,
    batteryVoltage: null,
    bmsMaxChargeCurrent: null,
    bmsMaxDischargeCurrent: null,
    chargeDischargePower: null,
    circuitBreakerPhases: null,
    consumptionEnergy: null,
    consumptionPower: null,
    consumptionPowerV2: null,
    dcExcessFeedInEnabled: null,
    eepromWriteCount: null,
    emsPowerMode: null,
    emsPowerSet: null,
    flashPgmWriteCount: null,
    flashSysWriteCount: null,
    gridCurrent: null,
    gridCurrentLimit: null,
    gridInEnergy: null,
    gridOutEnergy: null,
    gridOverflowPowerLimit: null,
    gridPower: null,
    gridPowerV2: null,
    inverterPower: null,
    inverterVersion: null,
    lockState: null,
    manualMode: null,
    modbusAddress: null,
    modbusPowerControl: null,
    photovoltaicEnergy: null,
    photovoltaicPower: null,
    powerSetpoint: null,
    runMode: null,
    selfUseSocMin: null,
    solarChargerUseMode: null,
    tcpQueueSize: null,
    unlockPassword: null,
    activePowerControlMode: null,
    ...override,
  }
}

export function buildInverterWithHousehold(
  override?: Parameters<typeof buildInverter>[0],
  overrideHousehold?: Parameters<typeof buildHouseholdWithTenant>[0],
  overrideTenant?: Parameters<typeof buildHouseholdWithTenant>[1],
) {
  return {
    ...buildInverter(override),
    household: buildHouseholdWithTenant(overrideHousehold, overrideTenant),
  }
}

export function buildCommand(override?: Partial<Command>): Command {
  return {
    id: "command-id",
    createdAt: new Date(),
    updatedAt: new Date(),
    blockReservationId: null,
    canceledAt: null,
    canceledNotificationSentAt: null,
    createdById: null,
    createdNotificationSentAt: null,
    endAt: new Date(),
    startAt: new Date(),
    isTesting: null,
    slackThreadId: null,
    source: "API",
    tenantId: "some-tenant-id",
    type: "UP_POWER",
    updatedNotificationSentAt: null,
    ...override,
  }
}

export function buildBlock(override?: Partial<Block>): Block {
  return {
    id: "block-id",
    available: true,
    calculateBlockState: true,
    controlEnabled: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    sendBlockState: true,
    svrId: "svr-id",
    reportId: "report-id",
    virtualDeviceId: null,
    consumerId: null,
    ownerId: null,
    clampingEnabled: true,
    clampingValueMw: new Decimal(0.1),
    emaEnabled: true,
    emaPeriod: 45,
    emaWindowSize: 60,
    pidControllerD: new Decimal(0),
    pidControllerI: new Decimal(0),
    pidControllerP: new Decimal(1),
    pidIntegralLimit: new Decimal(0),
    pidEnabled: true,
    invertersLastMovedAt: null,
    primaryBlockId: null,
    ...override,
  }
}

export function buildBlockReservation(override?: Partial<BlockReservation>): BlockReservation {
  return {
    id: "reservation-id",
    bidDownPower: new Decimal(-1),
    bidUpPower: new Decimal(1),
    blockId: "block-id",
    createdAt: new Date(),
    updatedAt: new Date(),
    startAt: new Date(),
    endAt: new Date(),
    priceDownPowerCzk: new Decimal(-1000),
    priceUpPowerCzk: new Decimal(1000),
    priceDownPowerEur: new Decimal(-40),
    priceUpPowerEur: new Decimal(40),
    confirmed: false,
    deletedAt: null,
    errorMessage: null,
    upPowerConfirmedAt: null,
    downPowerConfirmedAt: null,
    blockReservationCandidateId: null,
    ...override,
  }
}

export function buildPriceList(override?: Partial<PriceList>): PriceList {
  return {
    id: "price-list-id",
    createdAt: new Date(),
    tenantId: "some-tenant-id",
    priceListTypeId: "price-list-type-id",
    from: new Date(),
    to: null,
    priceUpKwh: new Decimal(0),
    priceDownKwh: new Decimal(0),
    priceSolarCurtailmentKwh: new Decimal(0),
    ...override,
  }
}
