import { isNotNil } from "@deltagreen/utils"

import { createCalculated, s16, s32, string, u16, u32 } from "../../modbus/dataTypes"
import { ModBusFunctionCode } from "../../modbus/modbusProtocol"
import { modeControl, systemStatus } from "./dataTypes"

// https://github.com/StephanJoubert/home_assistant_solarman/blob/d4296b16415dc9187a74fbaf249bfd71417c38aa/custom_components/solarman/inverter_definitions/sofar_g3hyd.yaml

const ZucchettiBaseRegisters = [
  string({
    key: "serialNumber",
    address: 0x0445,
    name: "Serial number",
    rw: "RO",
    length: 7,
    remark: "ASCII 16 bytes",
  }),

  u16({
    key: "chargeCycleBattery1",
    address: 0x060a,
    name: "ChargeCycle_Bat1",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery2",
    address: 0x0611,
    name: "ChargeCycle_Bat2",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery3",
    address: 0x0618,
    name: "ChargeCycle_Bat3",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery4",
    address: 0x061f,
    name: "ChargeCycle_Bat4",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery5",
    address: 0x0626,
    name: "ChargeCycle_Bat5",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery6",
    address: 0x062d,
    name: "ChargeCycle_Bat6",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery7",
    address: 0x0634,
    name: "ChargeCycle_Bat7",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery8",
    address: 0x063b,
    name: "ChargeCycle_Bat8",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery9",
    address: 0x064a,
    name: "ChargeCycle_Bat9",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery10",
    address: 0x0651,
    name: "ChargeCycle_Bat10",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery11",
    address: 0x0658,
    name: "ChargeCycle_Bat11",
    rw: "RO",
    unit: "cycles",
  }),
  u16({
    key: "chargeCycleBattery12",
    address: 0x065f,
    name: "ChargeCycle_Bat12",
    rw: "RO",
    unit: "cycles",
  }),

  u16({
    key: "soh1",
    address: 0x0609,
    name: "SOH_Bat1",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh2",
    address: 0x0610,
    name: "SOH_Bat2",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh3",
    address: 0x0617,
    name: "SOH_Bat3",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh4",
    address: 0x061e,
    name: "SOH_Bat4",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh5",
    address: 0x0625,
    name: "SOH_Bat5",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh6",
    address: 0x062c,
    name: "SOH_Bat6",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh7",
    address: 0x0633,
    name: "SOH_Bat7",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh8",
    address: 0x063a,
    name: "SOH_Bat8",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh9",
    address: 0x0649,
    name: "SOH_Bat9",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh10",
    address: 0x0650,
    name: "SOH_Bat10",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh11",
    address: 0x0657,
    name: "SOH_Bat11",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soh12",
    address: 0x065e,
    name: "SOH_Bat12",
    rw: "RO",
    unit: "%",
  }),

  u16({
    key: "soc1",
    address: 0x0608,
    name: "SOC_Bat1",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc2",
    address: 0x060f,
    name: "SOC_Bat2",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc3",
    address: 0x0616,
    name: "SOC_Bat3",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc4",
    address: 0x061d,
    name: "SOC_Bat4",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc5",
    address: 0x0624,
    name: "SOC_Bat5",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc6",
    address: 0x062b,
    name: "SOC_Bat6",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc7",
    address: 0x0632,
    name: "SOC_Bat7",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc8",
    address: 0x0639,
    name: "SOC_Bat8",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc9",
    address: 0x0648,
    name: "SOC_Bat9",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc10",
    address: 0x064f,
    name: "SOC_Bat10",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc11",
    address: 0x0656,
    name: "SOC_Bat11",
    rw: "RO",
    unit: "%",
  }),
  u16({
    key: "soc12",
    address: 0x065d,
    name: "SOC_Bat12",
    rw: "RO",
    unit: "%",
  }),

  u16({
    key: "pvPower1",
    address: 0x0586,
    name: "Power_PV1",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower2",
    address: 0x0589,
    name: "Power_PV2",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower3",
    address: 0x058c,
    name: "Power_PV3",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower4",
    address: 0x058f,
    name: "Power_PV4",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower5",
    address: 0x0592,
    name: "Power_PV5",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower6",
    address: 0x0595,
    name: "Power_PV6",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower7",
    address: 0x0598,
    name: "Power_PV7",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower8",
    address: 0x059b,
    name: "Power_PV8",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower9",
    address: 0x059e,
    name: "Power_PV9",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower10",
    address: 0x05a1,
    name: "Power_PV10",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower11",
    address: 0x05a4,
    name: "Power_PV11",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower12",
    address: 0x05a7,
    name: "Power_PV12",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower13",
    address: 0x05aa,
    name: "Power_PV13",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower14",
    address: 0x05ad,
    name: "Power_PV14",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower15",
    address: 0x05b0,
    name: "Power_PV15",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  u16({
    key: "pvPower16",
    address: 0x05b3,
    name: "Power_PV16",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),

  u16({
    key: "batteryVoltage1",
    address: 0x0604,
    name: "Voltage_Bat1",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage2",
    address: 0x060b,
    name: "Voltage_Bat2",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage3",
    address: 0x0612,
    name: "Voltage_Bat3",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage4",
    address: 0x0619,
    name: "Voltage_Bat4",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage5",
    address: 0x0620,
    name: "Voltage_Bat5",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage6",
    address: 0x0627,
    name: "Voltage_Bat6",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage7",
    address: 0x062e,
    name: "Voltage_Bat7",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage8",
    address: 0x0635,
    name: "Voltage_Bat8",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage9",
    address: 0x0644,
    name: "Voltage_Bat9",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage10",
    address: 0x064b,
    name: "Voltage_Bat10",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage11",
    address: 0x0652,
    name: "Voltage_Bat11",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),
  u16({
    key: "batteryVoltage12",
    address: 0x0659,
    name: "Voltage_Bat12",
    rw: "RO",
    multiple: 10,
    unit: "V",
  }),

  s16({
    key: "batteryCurrent1",
    address: 0x0605,
    name: "Current_Bat1",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent2",
    address: 0x060c,
    name: "Current_Bat2",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent3",
    address: 0x0613,
    name: "Current_Bat3",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent4",
    address: 0x061a,
    name: "Current_Bat4",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent5",
    address: 0x0621,
    name: "Current_Bat5",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent6",
    address: 0x0628,
    name: "Current_Bat6",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent7",
    address: 0x062f,
    name: "Current_Bat7",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent8",
    address: 0x0636,
    name: "Current_Bat8",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent9",
    address: 0x0645,
    name: "Current_Bat9",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent10",
    address: 0x064c,
    name: "Current_Bat10",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent11",
    address: 0x0653,
    name: "Current_Bat11",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  s16({
    key: "batteryCurrent12",
    address: 0x065a,
    name: "Current_Bat12",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),

  s16({
    key: "batteryPower1",
    address: 0x0606,
    name: "Power_Bat1",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower2",
    address: 0x060d,
    name: "Power_Bat2",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower3",
    address: 0x0614,
    name: "Power_Bat3",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower4",
    address: 0x061b,
    name: "Power_Bat4",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower5",
    address: 0x0622,
    name: "Power_Bat5",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower6",
    address: 0x0629,
    name: "Power_Bat6",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower7",
    address: 0x0630,
    name: "Power_Bat7",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower8",
    address: 0x0637,
    name: "Power_Bat8",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower9",
    address: 0x0646,
    name: "Power_Bat9",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower10",
    address: 0x064d,
    name: "Power_Bat10",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower11",
    address: 0x0654,
    name: "Power_Bat11",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),
  s16({
    key: "batteryPower12",
    address: 0x065b,
    name: "Power_Bat12",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),

  s16({
    key: "batteryTemperature1",
    address: 0x0607,
    name: "Temperature_Env_Bat1",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature2",
    address: 0x060e,
    name: "Temperature_Env_Bat2",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature3",
    address: 0x0615,
    name: "Temperature_Env_Bat3",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature4",
    address: 0x061c,
    name: "Temperature_Env_Bat4",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature5",
    address: 0x0623,
    name: "Temperature_Env_Bat5",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature6",
    address: 0x062a,
    name: "Temperature_Env_Bat6",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature7",
    address: 0x0631,
    name: "Temperature_Env_Bat7",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature8",
    address: 0x0638,
    name: "Temperature_Env_Bat8",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature9",
    address: 0x0647,
    name: "Temperature_Env_Bat9",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature10",
    address: 0x064e,
    name: "Temperature_Env_Bat10",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature11",
    address: 0x0655,
    name: "Temperature_Env_Bat11",
    rw: "RO",
    unit: "C",
  }),
  s16({
    key: "batteryTemperature12",
    address: 0x065c,
    name: "Temperature_Env_Bat12",
    rw: "RO",
    unit: "C",
  }),

  u16({
    key: "GridOutput_Rsvd1_NETUSIMTYP",
    address: 0x048b,
    name: "GridOutput_Rsvd1",
    rw: "RO",
  }),

  u16({
    key: "GridOutput_Rsvd2_NETUSIMTYP",
    address: 0x048c,
    name: "GridOutput_Rsvd2",
    rw: "RO",
  }),

  u16({
    key: "ESOutput_Rsvd1_NETUSIMTYP",
    address: 0x0508,
    name: "ESOutput_Rsvd1",
    rw: "RO",
  }),

  u16({
    key: "ESOutput_Rsvd2_NETUSIMTYP",
    address: 0x0509,
    name: "ESOutput_Rsvd1",
    rw: "RO",
  }),

  u16({
    key: "activePowerPVExt",
    address: 0x04ae,
    name: "ActivePower_PV_Ext",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),

  s16({
    key: "activePowerLoadTotal",
    address: 0x0504,
    name: "ActivePower_Load_Total",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),

  s16({
    key: "activePowerOutputTotal",
    address: 0x0485,
    name: "ActivePower_Output_Total",
    rw: "RO",
    multiple: 0.1,
    unit: "W",
  }),

  u32({
    key: "pvGenerationToday",
    address: 0x0684,
    name: "PV_Generation_Today",
    rw: "RO",
    multiple: 0.1,
    unit: "Wh",
  }),

  u32({
    key: "pvGenerationTotal",
    address: 0x0686,
    name: "PV_Generation_Total",
    rw: "RO",
    multiple: 0.01,
    unit: "Wh",
  }),

  u32({
    key: "loadConsumptionToday",
    address: 0x0688,
    name: "Load_Consumption_Today",
    rw: "RO",
    multiple: 0.1,
    unit: "Wh",
  }),

  u32({
    key: "loadConsumptionTotal",
    address: 0x068a,
    name: "Load_Consumption_Total",
    rw: "RO",
    multiple: 0.01,
    unit: "Wh",
  }),

  u32({
    key: "energyPurchaseTotal",
    address: 0x068e,
    name: "Energy_Purchase_Total",
    rw: "RO",
    multiple: 0.01,
    unit: "Wh",
  }),
  u32({
    key: "energySellingToday",
    address: 0x0690,
    name: "Energy_Selling_Today",
    rw: "RO",
    multiple: 0.1,
    unit: "Wh",
  }),
  u32({
    key: "energySellingTotal",
    address: 0x0692,
    name: "Energy_Selling_Total",
    rw: "RO",
    multiple: 0.01,
    unit: "Wh",
  }),
  u32({
    key: "batChargeToday",
    address: 0x0694,
    name: "Bat_Charge_Today",
    rw: "RO",
    multiple: 0.1,
    unit: "Wh",
  }),
  u32({
    key: "batChargeTotal",
    address: 0x0696,
    name: "Bat_Charge_Total",
    rw: "RO",
    multiple: 0.01,
    unit: "Wh",
  }),
  u32({
    key: "batDischargeToday",
    address: 0x0698,
    name: "Bat_Discharge_Today",
    rw: "RO",
    multiple: 0.1,
    unit: "Wh",
  }),
  u32({
    key: "batDischargeTotal",
    address: 0x069a,
    name: "Bat_Discharge_Total",
    rw: "RO",
    multiple: 0.01,
    unit: "Wh",
  }),

  u16({
    key: "batteryCurrentChargeLimit",
    address: 0x104b,
    name: "BatConfig_Current_Charge_Limit",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),
  u16({
    key: "batteryCurrentDischargeLimit",
    address: 0x104c,
    name: "BatConfig_Current_Discharge_Limit",
    rw: "RO",
    multiple: 100,
    unit: "A",
  }),

  u16({
    key: "batteryConfigDepthOfDischarge",
    address: 0x104d,
    name: "BatConfig_Depth_of_Discharge",
    rw: "RO",
    unit: "%",
  }),

  u16({
    key: "batteryConfigEndOfDischarge",
    address: 0x104e,
    name: "BatConfig_End_of_Discharge",
    rw: "RO",
    unit: "%",
  }),

  u16({
    key: "batteryConfigCapacity",
    address: 0x104f,
    name: "BatConfig_Capacity",
    rw: "RO",
    unit: "Ah",
  }),

  u16({
    key: "batteryConfigVoltageNominal",
    address: 0x1050,
    name: "BatConfig_Voltage_Nominal",
    rw: "RO",
    multiple: 100,
    unit: "V",
  }),

  s32({
    key: "passiveModeGridPower",
    address: 0x1187,
    name: "Passive_Manual_Gdes",
    functionCode: ModBusFunctionCode.WriteRegisters,
    rw: "WO",
    // multiple: 100,
    unit: "W",
  }),

  s32({
    key: "passiveModeBatteryMin",
    address: 0x1189,
    name: "Passive_Manual_Blo",
    functionCode: ModBusFunctionCode.WriteRegisters,
    rw: "WO",
    // multiple: 100,
    unit: "W",
  }),
  s32({
    key: "passiveModeBatteryMax",
    address: 0x118b,
    name: "Passive_Manual_Bup",
    functionCode: ModBusFunctionCode.WriteRegisters,
    rw: "WO",
    unit: "W",
  }),

  s32({
    key: "passiveModeBatteryMin",
    address: 0x1189,
    name: "Passive_Manual_Blo",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
    rw: "RO",
    // multiple: 100,
    unit: "W",
  }),
  s32({
    key: "passiveModeBatteryMax",
    address: 0x118b,
    name: "Passive_Manual_Bup",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
    rw: "RO",
    unit: "W",
  }),

  s32({
    key: "passiveModeGridPower",
    address: 0x1187,
    name: "Passive_Manual_Gdes",
    // multiwriteRange: [0x111  , 0x1187],
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
    // multiple: 100,
    unit: "W",
  }),

  systemStatus({
    key: "systemStatus",
    address: 0x0404,
    name: "SysState",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
    rw: "RO",
  }),

  modeControl({
    key: "energyStorageModeControl",
    address: 0x1110,
    name: "Energy_Storage_Mode_Control",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
    rw: "RO",
  }),

  modeControl({
    key: "energyStorageModeControl",
    address: 0x1110,
    name: "Energy_Storage_Mode_Control",
    functionCode: ModBusFunctionCode.WriteRegisters,
    rw: "WO",
  }),

  u16({
    key: "timingId",
    address: 0x1111,
    name: "Timing_ID",
    rw: "RO",
    // unit: "%",
  }),
  u16({
    key: "timingId",
    address: 0x1111,
    name: "Timing_ID",
    functionCode: ModBusFunctionCode.WriteRegisters,
    rw: "WO",
    // unit: "%",
  }),

  u16({
    key: "countdown",
    address: 0x0417,
    name: "ccc",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
    rw: "RO",
    // unit: "%",
  }),
]

type ZucchettiBaseRegisterKeys = (typeof ZucchettiBaseRegisters)[number]["key"]
const calculated = createCalculated<ZucchettiBaseRegisterKeys>()

const ZucchettiCalculatedRegisters = [
  calculated({
    key: "pvPowerTotal",
    name: "Power_PV_Total",
    unit: "W",
    calculate: (data) => {
      return [
        data.pvPower1,
        data.pvPower2,
        data.pvPower3,
        data.pvPower4,
        data.pvPower5,
        data.pvPower6,
        data.pvPower7,
        data.pvPower8,
        data.pvPower9,
        data.pvPower10,
        data.pvPower11,
        data.pvPower12,
        data.pvPower13,
        data.pvPower14,
        data.pvPower15,
        data.pvPower16,
      ]
        .filter(isNotNil)
        .reduce<number | undefined>((acc, cur) => (acc ?? 0) + cur.value, undefined)
    },
  }),
  calculated({
    key: "socTotal",
    name: "SOC_Total",
    unit: "%",
    calculate: (data) => {
      return (
        (data.soc1?.value ?? 0) +
        (data.soc2?.value ?? 0) +
        (data.soc3?.value ?? 0) +
        (data.soc4?.value ?? 0) +
        (data.soc5?.value ?? 0) +
        (data.soc6?.value ?? 0) +
        (data.soc7?.value ?? 0) +
        (data.soc8?.value ?? 0)
        // TODO expirable objecty - tohle se musí číst na 2 dotazy a takhle by to nefungovalo
        // soc9,. soc10...
      )
    },
  }),
]

export const ZucchettiRegisters = [...ZucchettiBaseRegisters, ...ZucchettiCalculatedRegisters]
