import { isNil } from "@deltagreen/utils"
import crc from "crc/crc16modbus"

import { Device, GenericDevicesRegister, GenericPartialResponse } from "../devices"
import { RWType } from "./dataTypes"
import { ModBusFunctionCode } from "./modbusProtocol"

export const DEVICE_STATE_EXPIRATION = 20000

type ParsedRegisterValue = GenericDevicesRegister & {
  raw: Buffer
  value?: string | number | bigint | number[]
  debug?: string
  cause?: unknown
  errorMessage?: string
  type: "decoded" | "decodeError"
}

export const parseRegisterValue = (register: GenericDevicesRegister & { raw: Buffer }): ParsedRegisterValue => {
  const data = register.raw

  try {
    const { value, debug } = register.convertor.decode(data, register)
    return {
      ...register,
      value,
      debug,
      type: "decoded" as const,
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : "Unknown error"
    return { ...register, type: "decodeError" as const, cause: error, errorMessage }
  }
}

export const buildMultiReadRequest = (registers: ReadableRegister<GenericDevicesRegister>[], keys: string[]) => {
  // TODO kontrola ze se nic neprekrejva

  // console.log(registers)
  const found = registers
    .filter((r) => {
      return keys.includes(r.key)
    })
    .filter((r) => r.rw === "RO" || r.rw === "RW")

  // console.log("found", found)

  // TODO POP A UNSHIFT
  const start = found
    .map((r) => r.address)
    .sort((a, b) => a - b)
    .shift()
  if (start === undefined) {
    throw new Error(`Registers not found: ${keys.join(",")}`)
  }
  // NEMAZAT = debugovat na registri za sebou
  // found.sort((a, b) => a.address - b.address)
  // let prev = 0
  // for (let i = 0; i < found.length; i++) {
  //   const r = found[i]!
  //   console.log(r.address - prev)
  //   console.log(r.key, r.address, r.length)
  //   prev = r.address + r.length
  // }
  // console.log(found.filter((r) => r.functionCode === 4).map((o) => o.key))

  if (!found.every((r) => r.functionCode === found[0]?.functionCode)) {
    throw new Error(`Cannot mix different function codes ${keys.join(",")}`)
  }

  if (!found.every((r) => r.targetDevice === found[0]?.targetDevice)) {
    throw new Error(`Cannot mix different targetDevices ${keys.join(",")}`)
  }

  const end = found
    .map((r) => {
      return r.address + r.length
    })
    .sort((a, b) => a - b)
    .pop()
  if (end === undefined) {
    throw new Error("register not found")
  }

  const length = end - start
  // TODO asi je to per device + limit na krabicce
  if (length > 128) {
    throw new Error(`ptas se na moc velkej kus ${start}:${end} - ${length}  `)
  }
  const functionCode = found[0]?.functionCode || ModBusFunctionCode.ReadMultipleHoldingRegisters

  const targetDevice = found[0]?.targetDevice

  if (
    functionCode !== ModBusFunctionCode.ReadInputRegisters &&
    functionCode !== ModBusFunctionCode.ReadMultipleHoldingRegisters
  ) {
    throw new Error(`Invalid read function code  ${functionCode}`)
  }
  return {
    address: start,
    functionCode,
    length,
    targetDevice,
  }
}

export const mapResponseToRegisters = (
  functionCode: ModBusFunctionCode,
  registers: GenericDevicesRegister[],
  baseAddress: number,
  buffer: Buffer,
  targetDevice?: string | undefined,
) => {
  const end = baseAddress + buffer.length / 2 - 1

  const containedRegisters = registers
    .filter((o) => o.targetDevice === targetDevice)
    .filter((o) => {
      const fc = o.functionCode || ModBusFunctionCode.ReadMultipleHoldingRegisters

      return o.address >= baseAddress && o.address <= end && fc === functionCode && !o.calculated
    })

  const withRaw = containedRegisters.map((r) => {
    const length = r.length * 2
    const aa = (r.address - baseAddress) * 2
    const bb = aa + length
    const raw = buffer.subarray(aa, bb)
    return { ...r, raw } as const
  })

  return withRaw.map(parseRegisterValue)
}

export const mapCalculatedRegisters = <TDevice extends Device<GenericDevicesRegister>>(
  deviceState: GenericPartialResponse<TDevice["registers"][number]>,
  registers: GenericDevicesRegister[],
) => {
  const calculatedRegisters = registers.filter((r) => r.calculated)
  const calculatedState: Record<string, unknown> = {}

  const mappedOriginalRegistersMap: Record<string, { value: number }> = {}
  for (const [key, r] of Object.entries(deviceState)) {
    if (typeof r === "object" && r !== null && "value" in r && typeof r["value"] === "number") {
      mappedOriginalRegistersMap[key] = { value: r.value }
    }
  }

  for (const r of calculatedRegisters) {
    const { calculate, ...rest } = r

    const value = calculate?.(mappedOriginalRegistersMap)

    if (isNil(value)) {
      continue
    }

    const val = {
      ...rest,
      debug: "calculated",
      type: "decoded" as const,
      value,
    }

    calculatedState[r.key] = val
  }

  return calculatedState as GenericPartialResponse<TDevice["registers"][number]>
}

export type ModBusReply = {
  address: number
  command: ModBusFunctionCode
  txId: number
  data: Buffer
}

type FilteredRegister<T, K extends RWType> = T extends { rw: infer KK } ? (KK extends K ? T : never) : never

export type ReadableRegister<R extends GenericDevicesRegister> = FilteredRegister<R, "RW"> | FilteredRegister<R, "RO">
export type WritableRegister<R extends GenericDevicesRegister> = FilteredRegister<R, "RW"> | FilteredRegister<R, "WO">

export const checkSum = (buffer: Buffer) => {
  const sum = crc(buffer)
  const crcBuffer = Buffer.allocUnsafe(2)
  crcBuffer.writeUInt16LE(sum)
  return crcBuffer
}

export const validateCheckSum = (data: Buffer, dataCrc: Buffer) => {
  const calculatedCrc = checkSum(data)
  return Buffer.compare(calculatedCrc, dataCrc) === 0
}
