import { Logger } from "@deltagreen/logger"
import { InverterManufacturer, InverterPredictionSources } from "@prisma/client"
import dayjs from "dayjs"

import { PrismaClientExtended } from "../prisma/createClient"

const OLDEST_INVERTERS_TO_CLEAR_COUNT = 5

export async function clearVrmPredictionSourceFromOldestInverters(params: {
  prisma: PrismaClientExtended
  logger?: Logger
}) {
  const { prisma, logger } = params
  logger?.info({}, `Clearing VRM prediction source on oldest inverters`)
  const oldestInverters = await prisma.inverter.findMany({
    where: {
      createdAt: { lte: dayjs().subtract(7, "days").toDate() },
      manufacturer: InverterManufacturer.VICTRON_ENERGY,
      predictionSources: {
        hasSome: [
          InverterPredictionSources.CONSUMPTION_VICTRON_ENERGY,
          InverterPredictionSources.PRODUCTION_VICTRON_ENERGY,
        ],
      },
    },
    orderBy: { createdAt: "asc" },
    take: OLDEST_INVERTERS_TO_CLEAR_COUNT,
    select: {
      id: true,
      predictionSources: true,
    },
  })

  for (const inverter of oldestInverters) {
    logger?.info({}, `Clearing VRM prediction source on inverter ${inverter.id}`)
    await prisma.inverter.update({
      data: {
        predictionSources: inverter.predictionSources.filter(
          (source) =>
            source !== InverterPredictionSources.CONSUMPTION_VICTRON_ENERGY &&
            source !== InverterPredictionSources.PRODUCTION_VICTRON_ENERGY,
        ),
      },
      where: { id: inverter.id },
    })
  }

  logger?.info({}, `Cleared VRM prediction source on ${oldestInverters.length} inverters`)
}
