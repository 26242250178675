import { StrictOmit } from "@deltagreen/utils"

import { Register, RWType } from "../../../modbus/dataTypes"
import { createU16ToSingleEnumConvertor } from "../../../modbus/enumUtils"

type RKey = string

export const StorageStatus = { Offline: 0, Standby: 1, Running: 2, Fault: 3, SleepMode: 4 } as const

export const storageStatus = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageStatus),
    multiple: 1,
    length: 1,
  }
}

// s16?
export const StorageWorkingModesA = {
  Unlimited: 0,
  GridConnectionWithZeroPower: 1,
  GridConnectionWithLimitedPower: 2,
} as const

export const storageWorkingModesA = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageWorkingModesA),
    multiple: 1,
    length: 1,
  }
}

export const StorageWorkingmodesB = {
  None: 0,
  ForcibleChargeDischarge: 1,
  TimeOfUseLg: 2,
  FixedChargeDischarge: 3,
  MaximiseSelfConsumption: 4,
  FullyFedToGrid: 5,
  TimeOfUseLuna2000: 6,
  RemoteSchedulingMaximumSelfUse: 7,
  RemoteSchedulingFullInternetAccess: 8,
  RemoteSchedulingTou: 9,
  AiEnergyManagementAndScheduling: 10,
  RemoteSchedulingAiControl: 11,
} as const

export const storageWorkingmodesB = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageWorkingmodesB),
    multiple: 1,
    length: 1,
  }
}

export const StoragewWrkingmodesC = {
  Adaptive: 0,
  FixedChargeDischarge: 1,
  MaximiseSelfConsumption: 2,
  TimeOfUseLg: 3,
  FullyFedToGrid: 4,
  TimeOfUseLuna2000: 5,
} as const

export const storageWorkingmodesC = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StoragewWrkingmodesC),
    multiple: 1,
    length: 1,
  }
}

export const StorageProductModel = { None: 0, LgResu: 1, HuaweiLuna2000: 2 } as const

export const storageProductModel = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageProductModel),
    multiple: 1,
    length: 1,
  }
}

export const StorageForcibleChargeDischarge = { Stop: 0, Charge: 1, Discharge: 2 } as const

export const storageForcibleChargeDischarge = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageForcibleChargeDischarge),
    multiple: 1,
    length: 1,
  }
}

export const StorageForcibleChargeDischargeTargetMode = { Time: 0, Soc: 1 } as const

export const storageForcibleChargeDischargeTargetMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageForcibleChargeDischargeTargetMode),
    multiple: 1,
    length: 1,
  }
}

export const ActivePowerControlMode = {
  Unlimited: 0,
  DiActiveScheduling: 1,
  ZeroPowerGridConnection: 5,
  PowerLimitedGridConnectionWatt: 6,
  PowerLimitedGridConnectionPercent: 7,
} as const

export const activePowerControlMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(ActivePowerControlMode),
    multiple: 1,
    length: 1,
  }
}

export const MeterStatus = { Offline: 0, Normal: 1 } as const

export const meterStatus = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(MeterStatus),
    multiple: 1,
    length: 1,
  }
}

export const MeterType = { SinglePhase: 0, ThreePhase: 1 } as const

export const meterType = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(MeterType),
    multiple: 1,
    length: 1,
  }
}

export const MeterTypeCheck = { Recognizing: 0, MatchesWithMeter: 1, MatchesNotWithMeter: 2 } as const

export const meterTypeCheck = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(MeterTypeCheck),
    multiple: 1,
    length: 1,
  }
}

export const BackupVoltageIndependentOperation = { Bv101v: 0, Bv202v: 1 } as const

export const backupVoltageIndependentOperation = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(BackupVoltageIndependentOperation),
    multiple: 1,
    length: 1,
  }
}

export const StorageCapacityControlMode = { Disable: 0, ActiveCapacityControl: 1, ApparentPowerLimit: 2 } as const

export const storageCapacityControlMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageCapacityControlMode),
    multiple: 1,
    length: 1,
  }
}

export const WlanWakeup = { WakenUp: 0, Disabled: 1 } as const

export const wlanWakeup = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(WlanWakeup),
    multiple: 1,
    length: 1,
  }
}

export const RemoteChargeDischargeControlMode = {
  LocalControl: 0,
  RemoteControlMaximumSelfConsumption: 1,
  RemoteControlFullyFedToGrid: 2,
  RemoteControlTou: 3,
  RemoteControlAiControl: 4,
} as const

export const remoteChargeDischargeControlMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(RemoteChargeDischargeControlMode), // taky prej s16? wut?
    multiple: 1,
    length: 1,
  }
}

export const EmmaExternalMeterRunningStatus = { Online: 0, Offline: 1 } as const

export const emmaExternalMeterRunningStatus = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(EmmaExternalMeterRunningStatus),
    multiple: 1,
    length: 1,
  }
}

export const EmmaLimitationMode = { TotalPower: 0, SinglePhasePower: 1 } as const

export const emmaLimitationMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(EmmaLimitationMode),
    multiple: 1,
    length: 1,
  }
}

export const EmmaPowerSupplyConfiguration = { None: 0, MainsOnly: 1, MainsAndGenerator: 2, GeneratorOnly: 3 } as const

export const emmaPowerSupplyConfiguration = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(EmmaPowerSupplyConfiguration),
    multiple: 1,
    length: 1,
  }
}

export const EmmaConsiderMainsFaultyIf = { Open: 0, Closed: 1 } as const

export const emmaConsiderMainsFaultyIf = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(EmmaConsiderMainsFaultyIf),
    multiple: 1,
    length: 1,
  }
}

export const DeviceStatus = {
  StandbyInitializing: 0,
  StandbyDetectingInsulationResistance: 1,
  StandbyDetectingIrradiation: 2,
  StandbyGridDetecting: 3,
  Starting: 256,
  OnGrid: 512,
  GridConnectionPowerLimited: 513,
  GridConnectionSelfDerating: 514,
  OffGridModeRunning: 515,
  ShutdownFault: 768,
  ShutdownCommand: 769,
  ShutdownOVGR: 770,
  ShutdownCommunicationDisconnected: 771,
  ShutdownPowerLimited: 772,
  ShutdownManualStartupRequired: 773,
  ShutdownDCSwitchesDisconnected: 774,
  ShutdownRapidCutoff: 775,
  ShutdownInputUnderpowered: 776,
  GridSchedulingCosphiPCurve: 1025,
  GridSchedulingQUCurve: 1026,
  GridSchedulingPFUCurve: 1027,
  GridSchedulingDryContact: 1028,
  GridSchedulingQPCurve: 1029,
  SpotCheckReady: 1280,
  SpotChecking: 1281,
  Inspecting: 1536,
  AFCISelfCheck: 1792,
  IVScanning: 2048,
  DCInputDetection: 2304,
  RunningOffGridCharging: 2560,
  StandbyNoIrradiation: 40960,
} as const

export const deviceStatus = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(DeviceStatus),
    multiple: 1,
    length: 1,
  }
}

export const EmmaEssControlMode = {
  Reserved1: 1,
  MaximumSelfConsumption: 2,
  Reserved3: 3,
  FullyFedToGrid: 4,
  TimeOfUse: 5,
  ThirdPartyDispatch: 6,
} as const

export const emmaEssControlMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(EmmaEssControlMode), // TODO podle pythonovyho package je to s16, ale imho je to blbost (v dokumentaci se to tvari jako enum16 vsechno)
    multiple: 1,
    length: 1,
  }
}

export const StorageExcessPVEnergyUseInTOU = { FedToGrid: 0, Charge: 1 } as const

export const storageExcessPVEnergyUseInTOU = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(StorageExcessPVEnergyUseInTOU),
    multiple: 1,
    length: 1,
  }
}

export type HuaweiSpecificRegisters =
  | ReturnType<typeof storageStatus>
  | ReturnType<typeof storageWorkingModesA>
  | ReturnType<typeof storageWorkingmodesB>
  | ReturnType<typeof storageWorkingmodesC>
  | ReturnType<typeof storageProductModel>
  | ReturnType<typeof storageForcibleChargeDischarge>
  | ReturnType<typeof storageForcibleChargeDischargeTargetMode>
  | ReturnType<typeof activePowerControlMode>
  | ReturnType<typeof meterStatus>
  | ReturnType<typeof meterType>
  | ReturnType<typeof meterTypeCheck>
  | ReturnType<typeof backupVoltageIndependentOperation>
  | ReturnType<typeof storageCapacityControlMode>
  | ReturnType<typeof wlanWakeup>
  | ReturnType<typeof remoteChargeDischargeControlMode>
  | ReturnType<typeof emmaExternalMeterRunningStatus>
  | ReturnType<typeof emmaLimitationMode>
  | ReturnType<typeof emmaPowerSupplyConfiguration>
  | ReturnType<typeof emmaConsiderMainsFaultyIf>
  | ReturnType<typeof deviceStatus>
  | ReturnType<typeof emmaEssControlMode>
  | ReturnType<typeof storageExcessPVEnergyUseInTOU>
