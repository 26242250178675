import { Maybe } from "@deltagreen/utils"
import { CommandType } from "@prisma/client"

import { PrismaClientExtended } from "../prisma/createClient"

type FindTenantCommandsParams = {
  from?: Maybe<Date>
  to?: Maybe<Date>
  limit?: Maybe<number>
  offset?: Maybe<number>
  includeTesting?: boolean
  types?: CommandType[]
}

export async function findTenantCommands(db: PrismaClientExtended, tenantId: string, params: FindTenantCommandsParams) {
  const { to, from, limit, offset, includeTesting } = params

  return await db.command.findMany({
    where: {
      startAt: to ? { lte: to } : undefined,
      endAt: from ? { gte: from } : undefined,
      tenantId,
      isTesting: !!includeTesting,
      type: params.types?.length ? { in: params.types } : undefined,
    },
    orderBy: {
      startAt: "asc",
    },
    skip: offset ?? undefined,
    take: limit ?? undefined,
  })
}
