import { debugFactory } from "../debugFactory"
import { checkSum } from "./utils"

const debug = debugFactory("protocol")
// @see https://en.wikipedia.org/wiki/Modbus#Functions_and_commands
export const ModBusFunctionCode = {
  ReadInputRegisters: 4,
  ReadMultipleHoldingRegisters: 3,
  WriteSingleHoldingRegister: 6,

  // Etrel only?
  WriteRegisters: 16,
  // TODO nevim jestli tohle neni GoodWe specific ?
  WriteSingleHoldingRegisterError: 0x86, // 0x06 + 80
  WriteMultipleRegisterError: 0x90, // 0x10 + 80
} as const
export type ObjectValues<TObject extends Record<string, unknown>> = TObject[keyof TObject]
export type ModBusFunctionCode = ObjectValues<typeof ModBusFunctionCode>

// export const ModBusFunctionCode = {
//   // ReadInputRegisters = 4,
//   ReadMultipleHoldingRegisters = 3,
//   // WriteSingleHoldingRegister = 6,
//   // TODO nevim jestli tohle neni GoodWe specific ?
//   // WriteSingleHoldingRegisterError = 0x86,
// } as const

// @see https://github.com/yaacov/node-modbus-serial/blob/master/index.js

export type ReadRegisterFunctionCode =
  | typeof ModBusFunctionCode.ReadMultipleHoldingRegisters
  | typeof ModBusFunctionCode.ReadInputRegisters
export function writeFC4(code: ReadRegisterFunctionCode, address: number, dataAddress: number, length: number) {
  const codeLength = 6
  const buf = Buffer.alloc(codeLength) // add 2 crc bytes

  buf.writeUInt8(address, 0)
  buf.writeUInt8(code, 1)
  buf.writeUInt16BE(dataAddress, 2)
  buf.writeUInt16BE(length, 4)

  const crc = checkSum(buf)
  const b = Buffer.concat([buf, crc])
  debug(`>>> REQUEST: ${b.toString("hex")} address:${address} register:${dataAddress} length:${length}`)

  return b
}

const writeFC6 = (code: ModBusFunctionCode, address: number, dataAddress: number, value: Buffer) => {
  if (value.length != 2) {
    throw new Error("Buffer musi bejt 2")
  }

  // const code = 6

  const codeLength = 6 // 1B deviceAddress + 1B functionCode + 2B dataAddress + 2B value
  const buf = Buffer.alloc(codeLength)

  buf.writeUInt8(address, 0)
  buf.writeUInt8(code, 1)
  buf.writeUInt16BE(dataAddress, 2)

  value.copy(buf, 4)

  // add crc bytes to buffer
  const crc = checkSum(buf)

  return Buffer.concat([buf, crc])
}

/**
 * Write a Modbus "Preset Multiple Registers" (FC=16) to serial port.
 */
const writeFC16 = (address: number, dataAddress: number, array: Buffer) => {
  const code = 16

  let dataLength = array.length
  if (Buffer.isBuffer(array)) {
    // if array is a buffer it has double length
    dataLength = array.length / 2
  }

  // console.log("DATALLL", dataLength, array.toString("hex"))

  const codeLength = 7 + 2 * dataLength
  const buf = Buffer.alloc(codeLength)

  buf.writeUInt8(address, 0)
  buf.writeUInt8(code, 1)
  buf.writeUInt16BE(dataAddress, 2)
  buf.writeUInt16BE(dataLength, 4)
  buf.writeUInt8(dataLength * 2, 6)

  // copy content of array to buf
  // if (Buffer.isBuffer(array)) {
  array.copy(buf, 7)

  // console.log("XXXXXXX", buf)
  // } else {
  // for (let i = 0; i < dataLength; i++) {
  // buf.writeUInt16BE(array[i], 7 + 2 * i)
  // }
  // }

  // add crc bytes to buffer
  // buf.writeUInt16LE(crc16(buf.slice(0, -2)), codeLength)

  // write buffer to serial port
  // _writeBufferToPort.call(this, buf, this._port._transactionIdWrite)
  const crc = checkSum(buf)

  return Buffer.concat([buf, crc])
}

export function readRegisterRequest(
  code: ReadRegisterFunctionCode,
  address: number,
  dataAddress: number,
  length: number,
) {
  return writeFC4(code, address, dataAddress, length)
}

export function writeRegisterRequest(code: ModBusFunctionCode, address: number, dataAddress: number, value: Buffer) {
  // console.log("!!!!writeRegisterRequest", code, address, dataAddress, value)
  if (code == ModBusFunctionCode.WriteRegisters) {
    return writeFC16(address, dataAddress, value)
  } else {
    return writeFC6(code, address, dataAddress, value)
  }
}
