import { z } from "zod"

import type { PrismaClientExtended } from "../prisma/createClient"

const blocksSchema = z.array(
  z.object({
    blockId: z.string(),
    svrId: z.string(),
    reportId: z.string().nullable(),
    virtualDeviceId: z.string().nullable(),
    reservationExists: z.boolean(),
    ownerId: z.string().nullable(),
    devices: z
      .array(
        z.object({
          id: z.string(),
          svrId: z.string(),
          hasReservation: z.boolean(),
          reservations: z
            .array(
              z.object({
                from: z.coerce.date(),
                to: z.coerce.date(),
              }),
            )
            .nullable(),
        }),
      )
      .nullable(),
  }),
)

export async function getBlocksWithDevicesInRange(db: PrismaClientExtended, from: Date, to: Date) {
  const blocksRaw = await db.$queryRaw`
	    WITH blocks AS (
	      SELECT b.id, b.svr_id, b.report_id, b.available, b.virtual_device_id, b.owner_id, b.sys_period FROM block b
		WHERE b.available = true
	      UNION ALL
	      SELECT bh.id, bh.svr_id, bh.report_id, bh.available, bh.virtual_device_id, bh.owner_id, bh.sys_period FROM block_history bh
		WHERE bh.available = true
	    ),
	    latest_blocks_in_range AS (
	    	SELECT b.id, MAX(LOWER(b.sys_period)) AS max_valid_since FROM blocks b
		WHERE tstzrange(${from},${to}) && b.sys_period
		GROUP BY b.id
	    ),
	    blocks_in_range AS (
		SELECT b.* FROM blocks b
		INNER JOIN latest_blocks_in_range lb
		  ON lb.id = b.id AND lb.max_valid_since = LOWER(b.sys_period)
	    ),
	    inverters AS (
		SELECT i.id, i.block_id, i.svr_id, i.sys_period FROM inverter i
		WHERE i.block_id IS NOT NULL AND tstzrange(${from},${to}) && i.sys_period
		UNION ALL
		SELECT ih.id, ih.block_id, ih.svr_id, ih.sys_period FROM inverter_history ih
		WHERE ih.block_id IS NOT NULL AND tstzrange(${from},${to}) && ih.sys_period
	    ),
	    reserved_inverters_in_range as (
	      SELECT br.block_id, bri.inverter_id, br.start_at as r_start_at, COALESCE(ci.flexibility_disabled_at, br.end_at) as r_end_at
	      FROM block_reservation br
	      LEFT JOIN block_reservation_inverter bri
	        ON bri.block_reservation_id = br.id
	      LEFT JOIN command c
		ON c.block_reservation_id = br.id
	      LEFT JOIN command_inverter ci
		ON ci.command_id = c.id AND bri.inverter_id = ci.inverter_id
	      -- confirmed reservations should never be deleted, so just to be sure to not generate obsolete reports
	      WHERE br.confirmed = true AND br.deleted_at IS NULL AND (br.start_at, br.end_at) OVERLAPS (${from},${to})
	    ),
	    inverters_in_range AS (
		SELECT i.id, i.block_id, i.svr_id, COUNT(ri.inverter_id) > 0 AS has_reservation,
			JSONB_AGG(JSONB_BUILD_OBJECT('from', ri.r_start_at, 'to', ri.r_end_at)) FILTER (WHERE ri.r_start_at IS NOT NULL) as "reservations"
		FROM inverters i
		LEFT JOIN reserved_inverters_in_range ri
    		  ON ri.inverter_id = i.id
		WHERE tstzrange(ri.r_start_at,ri.r_end_at) && i.sys_period
		GROUP BY i.id, i.block_id, i.svr_id
	    )
	    SELECT
		b.id AS "blockId",
		b.svr_id AS "svrId",
		b.report_id AS "reportId",
		b.virtual_device_id AS "virtualDeviceId",
		b.owner_id as "ownerId",
		EXISTS (SELECT 1 FROM reserved_inverters_in_range ri WHERE ri.block_id = b.id) as "reservationExists",
		JSONB_AGG(JSONB_BUILD_OBJECT('id', i.id, 'svrId', i.svr_id, 'hasReservation', i.has_reservation, 'reservations', i.reservations)) FILTER (WHERE i.id IS NOT NULL) as "devices"
	    FROM blocks_in_range b
	    LEFT JOIN inverters_in_range i
		ON i.block_id = b.id
	    GROUP BY b.id, b.svr_id, b.report_id, b.virtual_device_id, b.owner_id;
	`

  const blocks = blocksSchema.parse(blocksRaw)
  return blocks
}
