import { isNil, StrictOmit } from "@deltagreen/utils"
import { Prisma, PrismaClient } from "@prisma/client"

import { readReplicas } from "./replicas"

export function createPrismaClient(
  url: string,
  options?: Omit<Prisma.PrismaClientOptions, "datasources" | "omit">,
  replicaUrls?: string | string[],
) {
  const prisma = new PrismaClient({
    datasources: { db: { url } },
    ...options,
  })

  const urls = isNil(replicaUrls) ? [url] : Array.isArray(replicaUrls) ? replicaUrls : [replicaUrls]
  return prisma.$extends(
    readReplicas({
      ...options,
      replicas: urls.map((replicaUrl) => new PrismaClient({ datasourceUrl: replicaUrl, ...options })),
    }),
  )
}

export type PrismaClientExtended = ReturnType<typeof createPrismaClient>

export type PrismaClientTransaction = StrictOmit<
  PrismaClientExtended,
  "$connect" | "$disconnect" | "$transaction" | "$extends"
>
