import { StrictOmit } from "@deltagreen/utils"

import { declareRegister, Register, RWType } from "../../../modbus/dataTypes"
import {
  appModeCovertor,
  emsPowerModeConvertor,
  timeConvertor,
  weeklyScheduleConvertor,
  workModeConvertor,
} from "./convertors"

type RKey = string

export const workWeek = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "multiple" | "length" | "convertor" | "calculate">,
) => {
  return declareRegister({
    ...r,
    convertor: weeklyScheduleConvertor,
    unit: "HH:MM",
    multiple: 1,
    length: 1,
    remark: "[0,23],[0,59]",
  })
}

export const time = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: timeConvertor,
    unit: "HH:MM",
    multiple: 1,
    length: 1,
    remark: "[0,23],[0,59]",
  }
}

export const appMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: appModeCovertor,
    multiple: 1,
    length: 1,
  }
}

export const workMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: workModeConvertor,
    multiple: 1,
    length: 1,
  }
}

export const emsPowerMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: emsPowerModeConvertor,
    multiple: 1,
    length: 1,
  }
}

export type GoodweSpecificRegisters =
  | ReturnType<typeof workWeek>
  | ReturnType<typeof time>
  | ReturnType<typeof appMode>
  | ReturnType<typeof workMode>
  | ReturnType<typeof emsPowerMode>
