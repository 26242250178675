import { assertUnreachable } from "@deltagreen/utils"

import { ModBusFunctionCode } from "../modbus/modbusProtocol"
import { ModBusReply } from "../modbus/utils"

export function parseModbusTCPReply(txId: number, buffer: Buffer): ModBusReply {
  // console.log("parseModbusTCPReply", buffer.toString("hex"))
  const address = buffer.readUInt8(0)
  const command = buffer.readUInt8(1) as ModBusFunctionCode
  // console.log("KOMA", command)
  let len = 0
  let data = buffer.subarray(3)
  // kdyz je 16, tak muze bejt dylka na dva bajty ?
  if (command == ModBusFunctionCode.WriteRegisters) {
    len = buffer.readUInt16BE(2)
    data = buffer.subarray(4)
  } else if (command == ModBusFunctionCode.WriteSingleHoldingRegister) {
    data = buffer.subarray(4)
  } else {
    len = buffer.readUInt8(2)
  }

  // console.log("parseModbusTCPReply", { address, command, len, data: data.toString("hex") })
  // u 16 se to chova nejak jinak a nevraci se co sem zapsal
  // console.log("CC", command, data, len, buffer)
  if (
    command != ModBusFunctionCode.WriteSingleHoldingRegister &&
    command != ModBusFunctionCode.WriteRegisters &&
    len !== data.length
  ) {
    throw new Error("Invalid length")
  }

  switch (command) {
    case ModBusFunctionCode.WriteRegisters:
    case ModBusFunctionCode.WriteSingleHoldingRegister: {
      return {
        txId,
        address,
        command,
        // melo by to vracet to samy co se tam poslalo
        data,
      }
    }
    case ModBusFunctionCode.ReadInputRegisters:
    case ModBusFunctionCode.ReadMultipleHoldingRegisters: {
      // const length = buffer.readUInt8(4)
      // const data = buffer.subarray(5, 5 + length)
      return {
        txId,
        address,
        command,
        data,
      }
    }
    case ModBusFunctionCode.WriteMultipleRegisterError:
    case ModBusFunctionCode.WriteSingleHoldingRegisterError: {
      throw new Error(`Write error:${buffer.toString("hex")}`)
    }
    default:
      assertUnreachable(command)
  }
}
