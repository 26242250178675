import { ParamsWithLogger } from "@deltagreen/logger"
import { assertUnreachable, generateDateArray } from "@deltagreen/utils"
import { InverterPhotovoltaicPrediction, InverterPhotovoltaicPredictionSource } from "@prisma/client"
import dayjs from "dayjs"
import Decimal from "decimal.js"

import { PrismaClientExtended } from "../prisma/createClient"

function getLastPrediction(predictions: InverterPhotovoltaicPrediction[]) {
  const lastCreationDate = predictions
    .map((p) => p.createdAt)
    .sort((a, b) => dayjs(b).diff(a))
    .at(0)

  if (!lastCreationDate) {
    return {
      powerW: new Decimal(0),
      energyWh: new Decimal(0),
    }
  }

  const lastPrediction = predictions.filter((p) => dayjs(p.createdAt).isSame(lastCreationDate, "hour")).at(0)
  const energyWh = lastPrediction?.photovoltaicEnergy ?? new Decimal(0)
  const powerW = lastPrediction ? (lastPrediction.photovoltaicPower ?? new Decimal(0)) : new Decimal(0)

  return {
    powerW,
    energyWh,
  }
}

type PVEnergyPredictionResult = Map<string, { powerW: Decimal; energyWh: Decimal }>

type GetPhotovoltaicEnergyPredictionsParams = ParamsWithLogger<{
  prisma: PrismaClientExtended
  startDate: Date
  endDate: Date
  inverterId: string
  source: InverterPhotovoltaicPredictionSource
}>

export async function getPhotovoltaicEnergyPredictionByInstallation(params: GetPhotovoltaicEnergyPredictionsParams) {
  const { inverterId, source, prisma, logger } = params

  const startDate = dayjs(params.startDate).startOf("hour").toDate()
  const endDate = dayjs(params.endDate).add(1, "hour").startOf("hour").toDate()
  const results: Map<string, PVEnergyPredictionResult> = new Map()

  const installations = await prisma.inverterPhotovoltaicInstallation.findMany({
    select: { id: true },
    where: { inverterId },
  })

  logger.info({}, `Found ${installations.length} installations for inverter ${inverterId}.`)
  for (const installation of installations) {
    const predictions = await prisma.inverterPhotovoltaicInstallationPredictionLatest.findMany({
      where: {
        time: { gte: startDate, lte: dayjs(endDate).add(1, "hour").toDate() },
        inverterPhotovoltaicInstallationId: installation.id,
        source,
      },
    })

    logger.info({}, `Found ${predictions.length} predictions for installation ${installation.id}.`)
    const result: PVEnergyPredictionResult = new Map()
    for (const date of generateDateArray(startDate, endDate, { unit: "hour" })) {
      const currentPredictions = predictions.filter((prediction) => dayjs(prediction.time).isSame(date, "hour"))
      if (!currentPredictions) {
        result.set(date.toISOString(), { powerW: new Decimal(0), energyWh: new Decimal(0) })
        continue
      }

      const energyWh = currentPredictions.reduce((acc, curr) => acc.add(curr.photovoltaicEnergy ?? 0), new Decimal(0))
      const powerW =
        currentPredictions.length === 0
          ? new Decimal(0)
          : currentPredictions
              .reduce((acc, curr) => acc.add(curr.photovoltaicPower ?? 0), new Decimal(0))
              .div(currentPredictions.length)

      result.set(date.toISOString(), { powerW, energyWh })
    }

    results.set(installation.id, result)
  }

  return results
}

export async function getPhotovoltaicEnergyPredictions(params: GetPhotovoltaicEnergyPredictionsParams) {
  const { inverterId, source, prisma, logger } = params

  if (
    source === InverterPhotovoltaicPredictionSource.VICTRON_ENERGY ||
    source === InverterPhotovoltaicPredictionSource.AMPER_METEO
  ) {
    const startDate = dayjs(params.startDate).startOf("hour").toDate()
    const endDate = dayjs(params.endDate).add(1, "hour").startOf("hour").toDate()

    const result: PVEnergyPredictionResult = new Map()
    const predictions = await prisma.inverterPhotovoltaicPrediction.findMany({
      where: {
        source,
        inverterId,
        time: { gte: startDate, lte: dayjs(endDate).add(1, "hour").toDate() },
      },
    })

    for (const date of generateDateArray(startDate, endDate, { unit: "hour" })) {
      const datePredictions = predictions.filter((prediction) => dayjs(prediction.time).isSame(date, "hour"))
      const lastPrediction = getLastPrediction(datePredictions)
      if (!lastPrediction) {
        result.set(date.toISOString(), { powerW: new Decimal(0), energyWh: new Decimal(0) })
        continue
      }

      result.set(date.toISOString(), { powerW: lastPrediction.powerW, energyWh: lastPrediction.energyWh })
    }

    return [...result.entries()].map(([date, { powerW, energyWh }]) => ({
      date: new Date(date),
      powerW: powerW.toNumber(),
      energyWh: energyWh.toNumber(),
    }))
  }

  if (
    source === InverterPhotovoltaicPredictionSource.FORECAST_SOLAR ||
    source === InverterPhotovoltaicPredictionSource.FORECAST_SOLAR_WITH_ACTUAL
  ) {
    const predictions = await getPhotovoltaicEnergyPredictionByInstallation(params)
    const result: PVEnergyPredictionResult = new Map()
    logger.info({}, `Found ${predictions.size} installations for inverter ${inverterId}.`)

    for (const prediction of predictions.values()) {
      for (const [date, { powerW, energyWh }] of prediction.entries()) {
        const datePrediction = result.get(date)
        if (datePrediction) {
          result.set(date, {
            powerW: datePrediction.powerW.add(powerW),
            energyWh: datePrediction.energyWh.add(energyWh),
          })

          continue
        }

        result.set(date, { powerW, energyWh })
      }
    }

    return [...result.entries()].map(([date, { powerW, energyWh }]) => ({
      date: new Date(date),
      powerW: powerW.toNumber(),
      energyWh: energyWh.toNumber(),
    }))
  }

  if (source === InverterPhotovoltaicPredictionSource.SOLCAST) {
    throw new Error("Not implemented.")
  }

  return assertUnreachable(source)
}

export type PhotovoltaicEnergyPrediction = Awaited<ReturnType<typeof getPhotovoltaicEnergyPredictions>>[number]
