import { tariffTypes } from "@deltagreen/constants"
import { GoodweEnums, SolaxEnums } from "@deltagreen/proteus-clients-modbus-bridge"
import { booleanSchema } from "@deltagreen/utils"
import { z } from "zod"

//
//
//
export const flexalgoPvSchema = z.enum(["unrestricted", "fully_restricted", "restricted_to_household", "unknown"])

export const flexalgoBatterySchema = z.enum([
  "charge_from_grid",
  "charge_from_pv",
  "default",
  "do_not_charge",
  "do_not_discharge",
  "discharge_to_household",
  "discharge_to_grid",
  "unknown",
])

export const socSettingSchema = z.union([
  z.number().min(0).max(100),
  z.object({
    targetSoC: z.number().min(0).max(100),
    ignoreIfCurrentLessThanDesired: z.boolean().nullish(),
  }),
])

export const genericInstructionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("FLEXIBILITY_ACTIVATION"),
    value: z.object({
      expiresAt: z.date(),
      mode: z.union([z.literal("charge_battery"), z.literal("discharge_battery"), z.literal("do_not_use_battery")]),
    }),
  }),
  z.object({
    type: z.literal("AUTOMATION"),
    value: z.object({
      expiresAt: z.date(),
      battery: flexalgoBatterySchema,
      pv: flexalgoPvSchema,
      targetSoC: z.number().min(0).max(1).nullish(),
    }),
  }),
])

export const deltaInstructionSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("LATCH_BATTERY_CHARGE_ON_OFF"), value: booleanSchema }),
  z.object({ type: z.literal("LATCH_AC_GRID_ON_OFF"), value: booleanSchema }),
])

export const goodweInstructionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("ARM_FEED_POWER_ENABLE"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("ARM_FEED_POWER_PARA"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("EMS_POWER_MODE"),
    value: z.nativeEnum(GoodweEnums.EMSPowerMode),
  }),
  z.object({
    type: z.literal("APP_MODE"),
    value: z.nativeEnum(GoodweEnums.AppMode),
  }),
  z.object({
    type: z.literal("EMS_POWER_SET"),
    value: z.union([
      z.number(),
      z.object({ mode: z.literal("discharge_battery"), targetSoC: z.number() }),
      z.object({ mode: z.literal("charge_battery"), targetSoC: z.number(), endAt: z.string().datetime().nullish() }),
    ]),
  }),
  // TODO: this is not used - remove in next version
  z.object({
    type: z.literal("MINIMUM_SOC"),
    value: socSettingSchema,
  }),
])

const victronScheduleIndex = z.number().min(0).max(4)

export const inverterVictronInstructionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("MINIMUM_SOC"),
    value: socSettingSchema,
  }),
  z.object({
    type: z.literal("AC_POWER_SETPOINT"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("GRID_OVERFLOW"),
    value: z.object({
      target: z.union([z.literal("battery"), z.literal("grid")]),
      disableGridOverflow: z.boolean().default(false),
      disableWhenBatteryFullyCharged: z.boolean().default(false),
    }),
  }),
  z.object({
    type: z.literal("CHARGE_BATTERY_WITH_AC_POWER_SETPOINT"),
    value: z.object({ targetSoC: z.number().min(0).max(100), endAt: z.string().datetime().nullish() }),
    useSteadyPower: z.boolean().optional(),
  }),
  z.object({
    type: z.literal("BATTERY_POWER_CONTROL_WITH_AC_POWER_SETPOINT"),
    value: z.object({ targetPower: z.number(), batteryPowerLeewayOverride: z.number().nullish() }),
  }),
  z.object({
    type: z.literal("GRID_OVERFLOW_DISCHARGE"),
    value: z.object({ targetSoC: z.number().min(0).max(100) }),
  }),
  z.object({
    type: z.literal("DISABLED_DISCHARGE"),
    // TODO: make required in v2
    value: z
      .object({
        scheduleIndex: victronScheduleIndex.default(0),
        takeStateOfChargeFromDate: z.string().datetime().nullish(),
        allowDischarge: z.boolean().optional(),
        desiredSoC: z.number().min(0).max(100).nullish(),
      })
      .nullish(),
  }),
  z.object({
    type: z.literal("AC_EXCESS_FEED_IN"),
    value: z.boolean(),
  }),
  z.object({
    type: z.literal("DC_EXCESS_FEED_IN"),
    value: z.boolean(),
  }),
  z.object({
    type: z.literal("BATTERY_LIFE_STATE"),
    value: z.object({ target: z.number(), allowedValues: z.array(z.number()).nullish() }),
  }),
  z.object({
    type: z.literal("MAX_FEED_IN_POWER"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("DISABLED_SCHEDULED_CHARGE"),
    value: z.object({ excludedScheduleIndexes: z.array(victronScheduleIndex.default(0)).default([]) }).nullish(),
  }),
  z.object({
    type: z.literal("SCHEDULED_CHARGING"),
    value: z.object({
      targetSoC: z.number().min(0).max(100),
      allowDischarge: z.boolean(),
      // TODO: make required in v2
      scheduleIndex: victronScheduleIndex.default(0),
    }),
  }),
  z.object({
    type: z.literal("SCHEDULE"),
    value: z.object({
      scheduleIndex: z.number(),
      scheduleDay: z.number().nullish(),
      scheduleDuration: z.number().nullish(),
      scheduleStateOfCharge: z.number().min(0).max(100).nullish(),
      scheduleStart: z.number().nullish(),
      scheduleAllowDischarge: z.number().min(0).max(1).nullish(),
    }),
  }),
  z.object({
    type: z.literal("FVE_DISABLED"),
    value: z.boolean(),
  }),
])

export const inverterSolaxInstructionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("MANUAL_MODE"),
    value: z.nativeEnum(SolaxEnums.ManualMode),
  }),
  z.object({
    type: z.literal("SOLAR_CHARGER_USE_MODE"),
    value: z.nativeEnum(SolaxEnums.UseMode),
  }),
  z.object({
    type: z.literal("SOLAR_CHARGER_USE_MODE_MULTIPLE"),
    value: z.object({
      allowedUseModes: z.array(z.nativeEnum(SolaxEnums.UseMode)).readonly(),
      defaultUseMode: z.nativeEnum(SolaxEnums.UseMode),
    }),
  }),
  z.object({
    type: z.literal("CHARGE_PERIOD"),
    value: z.union([
      z.object({ mode: z.literal("onoff").or(z.undefined()), state: z.union([z.literal("on"), z.literal("off")]) }),
      z.object({
        mode: z.literal("raw"),
        startHours: z.number().nullish(),
        startMinutes: z.number().nullish(),
        endHours: z.number().nullish(),
        endMinutes: z.number().nullish(),
      }),
    ]),
  }),
  z.object({
    type: z.literal("DISCHARGE_PERIOD"),
    value: z.union([
      z.object({ mode: z.literal("onoff").or(z.undefined()), state: z.union([z.literal("on"), z.literal("off")]) }),
      z.object({
        mode: z.literal("raw"),
        startHours: z.number().nullish(),
        startMinutes: z.number().nullish(),
        endHours: z.number().nullish(),
        endMinutes: z.number().nullish(),
      }),
    ]),
  }),
  z.object({
    type: z.literal("PUSH_MODE_POWER"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("EXPORT_LIMIT"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("SELF_USE_GRID_CHARGE_ENABLE"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("SELF_USE_SOC_MIN"),
    value: socSettingSchema,
  }),
  z.object({
    type: z.literal("SELF_USE_SOC_MAX"),
    value: socSettingSchema,
  }),
  z.object({
    type: z.literal("BACKUP_SOC_MIN"),
    value: socSettingSchema,
  }),
  z.object({
    type: z.literal("SOC_MIN"),
    value: socSettingSchema,
  }),
  z.object({
    type: z.literal("UNLOCK_INVERTER"),
    value: z.unknown().nullish(),
  }),
  z.object({
    type: z.literal("CHECKING_TIME"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("MODBUS_POWER_CONTROL"),
    value: z.nativeEnum(SolaxEnums.ModbusPowerControl),
  }),
  z.object({
    type: z.literal("TARGET_SET_TYPE"),
    value: z.nativeEnum(SolaxEnums.TargetSetType),
  }),
  z.object({
    type: z.literal("ACTIVE_POWER_TARGET"),
    value: z.union([
      z.number(),
      z.object({ mode: z.literal("do_not_charge") }),
      z.object({ mode: z.literal("do_not_discharge") }),
      z.object({ mode: z.literal("do_not_discharge_no_eeprom") }),
      z.object({ mode: z.literal("battery_charge_full_power") }),
    ]),
  }),
  z.object({
    type: z.literal("TIME_OF_DURATION"),
    value: z.number(),
  }),
  z.object({
    type: z.literal("TARGET_SOC"),
    value: socSettingSchema,
  }),
  z.object({
    type: z.literal("CHARGE_DISCHARGE_POWER"),
    value: z.union([
      z.number(),
      z.object({ targetSoC: z.number().min(0).max(100), endAt: z.string().datetime().nullish() }),
    ]),
  }),
  z.object({
    type: z.literal("REMOTE_CTRL_TIME_OUT"),
    value: z.number(),
  }),
])

export const modbusConfigSchema = z.object({
  modbusAddress: z.number(),
  modbusAddressMap: z.object({ SDONGLE: z.number(), SUN2000: z.number() }).nullish(), // TODO
  macAddress: z.string(),
  ipAddress: z.string(),
  port: z.number(),
  inverterModel: z
    .enum(["GOODWE_10", "SOLAX_GEN3", "SOLAX_GEN4", "SOLAX_GEN5", "ZUCCHETTI_HYD", "HUAWEI_MAP0"])
    .nullish(),
})

// NOTE: discriminatedUnions should be much faster then unions (at least for now https://github.com/colinhacks/zod/issues/3407) - so where we can, we should use then instead of unions
// TODO: cannot use discriminatedUnions here, because of object merging / using and
export const instructionSchema = z.union([
  // Inverters
  z.object({ vendor: z.literal("victron"), inverterId: z.string().cuid2() }).and(inverterVictronInstructionSchema),
  z.object({ vendor: z.literal("solax"), inverterId: z.string().cuid2() }).and(inverterSolaxInstructionSchema),
  z.object({ vendor: z.literal("goodwe"), inverterId: z.string().cuid2() }).and(goodweInstructionSchema),
  z.object({ vendor: z.literal("generic"), inverterId: z.string().cuid2() }).and(genericInstructionSchema),
  // Vantage Delta
  z.object({ vendor: z.literal("delta"), deltaId: z.string().cuid2() }).and(deltaInstructionSchema),
])

export const controlPlanStepState = z.object({
  startedAt: z.string().datetime().nullish(),
  finishedAt: z.string().datetime().nullish(),
})

export const interruptionConditionNumberOperator = z.union([
  z.literal("eq"),
  z.literal("neq"),
  z.literal("lt"),
  z.literal("lte"),
  z.literal("gt"),
  z.literal("gte"),
])

export const interruptionConditionBooleanOperator = z.union([z.literal("eq"), z.literal("neq")])

export const interruptionCondition = z.discriminatedUnion("property", [
  z.object({
    property: z.literal("STATE_OF_CHARGE"),
    operator: interruptionConditionNumberOperator,
    value: z.number().min(0).max(100),
    deviceType: z.literal("inverter"),
    inverterId: z.string().cuid2(),
  }),
  z.object({
    // INFO: https://www.notion.so/delta-green/WR-fallback-c4ba5121aeb44f78bbc501dd5aacfe56?pvs=4
    property: z.literal("MODEL_NET_SIGN_MATCHES"),
    operator: interruptionConditionBooleanOperator,
    value: z.boolean(),
    deviceType: z.literal("inverter"),
    inverterId: z.string().cuid2(),
  }),
  z.object({
    property: z.literal("COMPARE_VALUES"),
    operator: interruptionConditionNumberOperator,
    value: z.tuple([
      z.union([z.literal("CONSUMPTION_POWER"), z.literal("PHOTOVOLTAIC_POWER")]),
      z.union([z.literal("CONSUMPTION_POWER"), z.literal("PHOTOVOLTAIC_POWER")]),
    ]),
    deviceType: z.literal("inverter"),
    inverterId: z.string().cuid2(),
  }),
])

const baseInterruption = z.object({
  condition: interruptionCondition,
  instructions: z.array(instructionSchema),
})

export const controlPlanStepInterruption = baseInterruption.extend({
  interruptions: z.array(baseInterruption).nullish(),
})

export const priceComponentsSchema = z.object({
  distributionPrice: z.number(),
  distributionTariffType: z.nativeEnum(tariffTypes),
  feeElectricityBuy: z.number(),
  feeElectricitySell: z.number(),
  taxElectricity: z.number(),
  systemServices: z.number(),
  poze: z.number(),
  vatRate: z.number(),
})

export const stepMetadata = z
  .object({
    flexalgoBattery: flexalgoBatterySchema.nullish(),
    flexalgoBatteryFallback: flexalgoBatterySchema.nullish(),
    flexalgoPv: flexalgoPvSchema.nullish(),
    targetSoC: z.number().min(0).max(100).nullish(),
    priceMwh: z.number().nullish(),
    priceMwhConsumption: z.number().nullish(),
    priceMwhProduction: z.number().nullish(),
    priceComponents: priceComponentsSchema.nullish(),
    isPrediction: z.boolean().nullish(),
    netSign: z.number().nullish(),
    manuallyEdited: z.boolean().nullish(),
    predictedProduction: z.number().nullish(),
    predictedConsumption: z.number().nullish(),
  })
  .passthrough()
  .nullish()

// V1 without instructions and interruptions
export const controlPlanPayloadStepV2 = z.object({
  id: z.string().cuid2(),
  // extra information
  metadata: stepMetadata,
  // time when to start the step - if null, step is started as soon as possible
  startAt: z.string().datetime().nullish(),
  // how long should the step run
  durationMinutes: z.number().default(60),
  // steps that are required to be finished before this step can be started
  dependsOn: z.union([z.string().cuid2(), z.array(z.string().cuid2())]).nullish(),
  // current state of the step
  state: controlPlanStepState,
  // array of steps, that were deduplicates to this step
  deduplicatedSteps: z
    .array(
      z.object({
        id: z.string().cuid2(),
        // time when to start the step - if null, step is started as soon as possible
        startAt: z.string().datetime().nullish(),
        // how long should the step run
        durationMinutes: z.number().nullish(),
        // extra information
        metadata: stepMetadata,
      }),
    )
    .nullish(),
  instructionOverrides: z
    .object({
      victron: z
        .object({
          disabledDischarge: z
            .object({
              takeStateOfChargeFromDate: z.string().datetime().nullish(),
            })
            .nullish(),
        })
        .nullish(),
    })
    .nullish(),
})

export const controlPlanPayload = z.object({
  version: z.literal("v2"),
  steps: z.array(controlPlanPayloadStepV2),
})
