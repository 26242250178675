import { s16, s32, string, u16, u32 } from "../../modbus/dataTypes"

export const AplhaESSRegisters = [
  u16({
    key: "soc",
    multiple: 10,
    address: 0x0102,
    rw: "RO",
    unit: "%",
    name: "SOC",
  }),
  u16({
    key: "voltageGridA",
    address: 0x0014,
    rw: "RO",
    unit: "V",
    name: "Voltage of A phase(Grid)",
  }),
  u16({
    key: "batteryCapacity",
    address: 0x0119,
    rw: "RO",
    unit: "KWh",
    multiple: 10,
    name: "Battery capacity",
  }),
  s32({
    key: "totalActivePowerGrid",
    address: 0x0021,
    rw: "RO",
    name: "Total Active power(Grid Meter)",
    unit: "W",
  }),

  u32({
    key: "totalGridOut",
    address: 0x0010,
    rw: "RO",
    name: "Total energy feed to grid(Grid)",
    unit: "kWh",
    multiple: 100,
  }),

  u32({
    key: "totalGridIn",
    address: 0x0012,
    rw: "RO",
    name: "Total energy feed from grid(Grid)",
    unit: "kWh",
    multiple: 100,
  }),

  u32({
    key: "inverterTotalPVEnergy",
    address: 0x043e,
    rw: "RO",
    name: "Inverter total PV Energy",
    unit: "kWh",
    multiple: 10,
  }),
  // u32({
  //   key: "totalEnergyFromGridPV",
  //   address: 0x0092,
  //   rw: "RO",
  //   name: "Total energy feed from Grid(PV)",
  //   unit: "kWh",
  //   multiple: 100,
  // }),

  u32({
    key: "batteryChargeEnergy",
    address: 0x0120,
    rw: "RO",
    name: "Battery charge energy",
    unit: "kWh",
    multiple: 10,
  }),

  u32({
    key: "batteryDischargeEnergy",
    address: 0x0122,
    rw: "RO",
    name: "Battery discharge energy",
    unit: "kWh",
    multiple: 10,
  }),

  // PV if AC coupled is via  PV CT and results are stored in
  // REG_PV_METER_R_TOTAL_ACTIVE_POWER_1
  // PV if hybrid is via the individual string readings from within the Alpha, namely
  // REG_INVERTER_HOME_R_PV1_POWER_1 ... 6
  s32({
    key: "totalActivePowerPV",
    address: 0x00a1,
    rw: "RO",
    name: "Total Active power(PV Meter)",
    unit: "W",
  }),

  // s32({
  //   key: "pvPowerA",
  //   address: 0x009b,
  //   rw: "RO",
  //   name: "Active power of A phase(PV)",
  //   unit: "W",
  // }),
  // s32({
  //   key: "pvPowerB",
  //   address: 0x009d,
  //   rw: "RO",
  //   name: "Active power of B phase(PV)",
  //   unit: "W",
  // }),
  // s32({
  //   key: "pvPowerC",
  //   address: 0x009f,
  //   rw: "RO",
  //   name: "Active power of C phase(PV)",
  //   unit: "W",
  // }),

  // u32({
  //   key: "pvPowerX",
  //   address: 0x043e,
  //   rw: "RO",
  //   name: "XXX",
  //   unit: "W",
  //   multiple: 10,
  // }),

  // s16({
  //   key: "totalActivePowerLoad",
  //   address: 0x523f,
  //   rw: "RO",
  //   name: "XXX",
  //   unit: "W",
  //   // multiple: 10,
  // }),

  // s32({
  //   key: "intverterPowerTotal",
  //   address: 0x040c,
  //   rw: "RO",
  //   name: "Inverter_Power_Total",
  //   unit: "W",
  // }),

  u32({
    key: "pv1Power",
    address: 0x041f,
    rw: "RO",
    name: "PV1 power",
    unit: "W",
  }),

  u32({
    key: "pv2Power",
    address: 0x0423,
    rw: "RO",
    name: "PV2 power",
    unit: "W",
  }),
  u32({
    key: "pv3Power",
    address: 0x0427,
    rw: "RO",
    name: "PV3 power",
    unit: "W",
  }),

  u32({
    key: "pv4Power",
    address: 0x042b,
    rw: "RO",
    name: "PV4 power",
    unit: "W",
  }),

  u32({
    key: "pv5Power",
    address: 0x042f,
    rw: "RO",
    name: "PV5 power",
    unit: "W",
  }),

  u32({
    key: "pv6Power",
    address: 0x0433,
    rw: "RO",
    name: "PV6 power",
    unit: "W",
  }),

  s16({
    key: "batteryPower",
    address: 0x0126,
    rw: "RO",
    name: "Battery Power",
    remark: "+ charge, - discharge",
    // 1W/bit (-: Charge, +: Discharge)
    multiple: -1,

    unit: "W",
  }),

  string({
    key: "inverterSN",
    address: 0x064a,
    name: "Inverter SN",
    rw: "RO",
    length: 10,
    remark: "ASCII 20 bytes",
  }),
]
