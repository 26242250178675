import { createConvertor } from "./dataTypes"

type ValueOf<T> = T[keyof T]

export function decodeEnum<T extends Record<string, number>>(enumType: T, value: number) {
  return Object.keys(enumType)
    .map((k) => {
      const val = enumType[k] as number
      return val & value ? val : undefined
    })
    .filter((o: number | undefined): o is number => o !== undefined)
}

export function dumpEnum<T extends Record<string, number>>(enumType: T, value: number) {
  const enabled = decodeEnum(enumType, value)
  const xa = Object.entries(enumType)
    .filter(([, v]) => {
      return enabled.includes(v)
    })
    .map(([k]) => k)

  return `[${xa.join(",")}]`
}

export const createU16ToMultiEnumConvertor = <T extends Record<string, number>>(anyEnum: T) => {
  return createConvertor(
    (o) => {
      const n = o.reduce((prev, current) => {
        return prev | (current as number)
      }, 0)

      const buffer = Buffer.allocUnsafe(2)
      buffer.writeUInt16BE(n)
      return buffer
    },
    (data: Buffer) => {
      const int = data.readUInt16BE(0)
      return {
        value: decodeEnum(anyEnum, int),
        debug: dumpEnum(anyEnum, int),
      }
    },
  )
}

function getEnumKeyByEnumValue<T extends { [index: string]: string | number }>(
  myEnum: T,
  enumValue: string | number,
): keyof T | null {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue)
  return keys.length > 0 ? (keys[0] as keyof T) : null
}

export type ObjectValues<TObject extends Record<string, unknown>> = TObject[keyof TObject]

export const createU16ToSingleEnumConvertor = <T extends Record<string, number>>(anyEnum: T) => {
  return createConvertor<ValueOf<T>>(
    (o) => {
      const buffer = Buffer.allocUnsafe(2)
      buffer.writeUInt16BE(o)
      return buffer
    },

    (data: Buffer) => {
      const modeValue = data.readUInt16BE(0)
      const mode = getEnumKeyByEnumValue(anyEnum, modeValue)
      if (!mode) {
        throw new Error("Invalid enum value: " + modeValue)
      }

      const value = anyEnum[mode]

      return {
        value,
        debug: mode.toString(),
      }
    },
  )
}

export const createU32LSBToMultiEnumConvertor = <T extends Record<string, number>>(anyEnum: T) => {
  return createConvertor(
    (o) => {
      const n = o.reduce((prev, current) => {
        return prev | (current as number)
      }, 0)

      const buffer = Buffer.allocUnsafe(4)
      buffer.writeUInt32LE(n)
      return buffer
    },
    (data: Buffer) => {
      const LSB = data.readUInt16BE(0)
      const MSB = data.readUInt16BE(2)
      const int = LSB + (MSB << 8)

      return {
        value: decodeEnum(anyEnum, int),
        debug: dumpEnum(anyEnum, int),
      }
    },
  )
}
