import { assertUnreachable } from "@deltagreen/utils"

import { ModBusFunctionCode } from "../modbus/modbusProtocol"
import { ModBusReply, validateCheckSum } from "../modbus/utils"

export function parseAA55Message(buffer: Buffer): ModBusReply {
  if (buffer[0] != 0xaa && buffer[1] != 0x55) {
    throw new Error("Not an aa55 message")
  }
  const command = buffer.readUInt8(3) as ModBusFunctionCode
  const address = buffer.readUInt8(2)

  const response = buffer.subarray(2)
  const data = response.subarray(0, response.length - 2)
  const checksum = response.subarray(response.length - 2, response.length)

  const crcValid = validateCheckSum(data, checksum)
  if (!crcValid) {
    throw new Error("InvalidCRC")
  }

  switch (command) {
    case ModBusFunctionCode.WriteSingleHoldingRegister: {
      return {
        txId: 1,
        address,
        command,
        // melo by to vracet to samy co se tam poslalo
        data: buffer.subarray(6, 8),
      }
    }
    case ModBusFunctionCode.ReadInputRegisters:
    case ModBusFunctionCode.ReadMultipleHoldingRegisters: {
      const length = buffer.readUInt8(4)
      const data = buffer.subarray(5, 5 + length)
      return {
        txId: 1,
        address,
        command,
        data,
      }
    }
    case ModBusFunctionCode.WriteRegisters:
    case ModBusFunctionCode.WriteMultipleRegisterError:
    case ModBusFunctionCode.WriteSingleHoldingRegisterError: {
      throw new Error(`Write error:${buffer.toString("hex")}`)
    }
    default:
      assertUnreachable(command)
  }
}
