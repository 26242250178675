import { generateDateArray } from "@deltagreen/utils"
import { InverterConsumptionPredictionSource } from "@prisma/client"
import dayjs from "dayjs"
import Decimal from "decimal.js"

import { PrismaClientExtended } from "../prisma/createClient"

type ConsumptionPredictionResult = Map<string, { powerW: Decimal; energyWh: Decimal }>

type GetConsumptionPredictionParams = {
  prisma: PrismaClientExtended
  source: InverterConsumptionPredictionSource
  startDate: Date
  endDate: Date
  inverterId: string
}

export async function getConsumptionPredictions(params: GetConsumptionPredictionParams) {
  const { inverterId, source, prisma } = params

  const startDate = dayjs(params.startDate).startOf("hour").toDate()
  const endDate = dayjs(params.endDate).add(1, "hour").startOf("hour").toDate()
  const result: ConsumptionPredictionResult = new Map()

  const predictions = await prisma.inverterConsumptionPredictionLatest.findMany({
    where: {
      source,
      inverterId,
      time: { gte: startDate, lte: dayjs(endDate).add(1, "hour").toDate() },
    },
  })

  for (const date of generateDateArray(startDate, endDate, { unit: "hour" })) {
    const lastPrediction = predictions.filter((prediction) => dayjs(prediction.time).isSame(date, "hour")).at(0)
    if (!lastPrediction) {
      result.set(date.toISOString(), { powerW: new Decimal(0), energyWh: new Decimal(0) })
      continue
    }

    if (lastPrediction.interval === "INTERVAL_60_MINUTES") {
      // if we have intervals of 60 minutes, we can assume that the power and energy are interchangeable
      result.set(date.toISOString(), {
        powerW: lastPrediction.consumptionPower ?? lastPrediction.consumptionEnergy ?? new Decimal(0),
        energyWh: lastPrediction.consumptionEnergy ?? lastPrediction.consumptionPower ?? new Decimal(0),
      })

      continue
    }

    if (lastPrediction.interval === "INTERVAL_15_MINUTES") {
      result.set(date.toISOString(), {
        powerW: lastPrediction.consumptionPower ?? new Decimal(0),
        energyWh: lastPrediction.consumptionEnergy ?? new Decimal(0),
      })

      continue
    }

    result.set(date.toISOString(), { powerW: new Decimal(0), energyWh: new Decimal(0) })
  }

  return [...result.entries()].map(([date, { powerW, energyWh }]) => ({
    date: new Date(date),
    powerW: powerW.toNumber(),
    energyWh: energyWh.toNumber(),
  }))
}

export type ConsumptionPrediction = Awaited<ReturnType<typeof getConsumptionPredictions>>[number]
