import { Prisma } from "@prisma/client"

export function recordToPrismaInputJsonValue(record: Record<string, unknown> | unknown[]): Prisma.InputJsonValue {
  return JSON.parse(JSON.stringify(record))
}

export function prismaJsonValueToRecord(json: Prisma.JsonValue): Record<string, unknown> {
  if (json && typeof json === "object" && !Array.isArray(json)) {
    return { ...json }
  }

  return {}
}
