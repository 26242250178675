import { createConvertor } from "../../../modbus/dataTypes"
import { createU16ToMultiEnumConvertor, createU16ToSingleEnumConvertor } from "../../../modbus/enumUtils"

export const AppMode = {
  SelfUse: 0,
  OffGrid: 1,
  BackupMode: 2,
  EconomicMode: 3,
  PeakShaving: 4,
  SelfUse5: 5, // nevim, takze asi nula je general, ale nevim jakej je rozdil
  EcoCharge: 98,
  EcoDischarge: 99,
} as const

export const appModeCovertor = createU16ToSingleEnumConvertor(AppMode)

export const WorkMode = {
  Wait: 0,
  OnGrid: 1,
  OffGrid: 2,
  Fault: 3,
  Flash: 4,
  Check: 5,
} as const

export const workModeConvertor = createU16ToSingleEnumConvertor(WorkMode)

export const EMSPowerMode = {
  Stopped: 0x00ff,
  Auto: 0x0001,
  ChargePV: 0x0002,
  DischgPV: 0x0003,
  ImportAC: 0x0004,
  ExportAC: 0x0005,
  Conserve: 0x0006,
  OffGrid: 0x0007,
  BateryStandby: 0x0008,
  BuyPower: 0x0009,
  SellPower: 0x000a,
  ChargeBAT: 0x000b,
  DischargeBAT: 0x000c,
} as const

export const emsPowerModeConvertor = createU16ToSingleEnumConvertor(EMSPowerMode)

export const timeConvertor = createConvertor<[number, number]>(
  ([hh, mm]) => {
    const b = Buffer.alloc(2)
    b.writeInt8(hh, 0)
    b.writeInt8(mm, 1)
    return b
  },
  (b) => {
    const value = [b.readInt8(0), b.readInt8(1)] as [number, number]
    const debug = value.map((o) => String(o).padStart(2, "0")).join(":")
    return { value, debug }
  },
)

// export type ObjectValues<TObject extends Record<string, unknown>> = TObject[keyof TObject]

export const WeeklySchedule = {
  Enabled: 0xff_00,
  // 7 NA
  Saturday: 64, //1 << 6,
  Friday: 32, // 1 << 5,
  Thursday: 16, //1 << 4,
  Wednesday: 8, //1 << 3,
  Tuesday: 4, //1 << 2,
  Monday: 2, //1 << 1,
  Sunday: 1, //1 << 0,
} as const
// type WeeklySchedule = ObjectValues<typeof WeeklySchedule>

export const weeklyScheduleConvertor = createU16ToMultiEnumConvertor(WeeklySchedule)
