import { StrictOmit } from "@deltagreen/utils"

import { createConvertor, Register, RWType } from "../../modbus/dataTypes"
import { createU16ToSingleEnumConvertor, createU32LSBToMultiEnumConvertor } from "../../modbus/enumUtils"

type RKey = string

// sou tam obraceny indiani oproti goodwe, ale jenom u cteni :facepalm:
export const timeConvertor = createConvertor<[number, number]>(
  ([hh, mm]) => {
    const b = Buffer.alloc(2)
    b.writeUInt8(hh, 0)
    b.writeUInt8(mm, 1)
    return b
  },
  (b) => {
    const value = [b.readUInt8(1), b.readUInt8(0)] as [number, number]
    const debug = value.map((o) => String(o).padStart(2, "0")).join(":")
    return { value, debug }
  },
)

export const time = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: timeConvertor,
    unit: "HH:MM",
    multiple: 1,
    length: 1,
    remark: "[0,23],[0,59]",
  }
}

export const FaultCodes = {
  // byte 0
  TZProtectFault: 1,
  GridLostFault: 2,
  GridVoltFault: 4,
  GridFreqFault: 8,
  PVVoltFault: 16,
  BusVoltFault: 32,
  BatVoltFault: 64,
  AC10minsVoltFault: 128,
  // byte 1
  DCIOCPFault: 256,
  DCVOCPFault: 512,
  SWOCPFault: 1024,
  RCOCPFault: 2048,
  IsolationFault: 4096,
  TempOverFault: 8192,
  BatConnDirFault: 16384,
  OffGridOverload: 32768,
  // byte 2
  Overload: 65536,
  BatPowerLow: 131072,
  BMSLost: 262144,
  FanFault: 524288,
  LowTempFault: 1048576,
  ParallelFault: 2097152,
  HardLimitFault: 4194304,
  INVVoltSampleFault: 8388608,
  // byte 3
  InnerCommFault: 16777216,
  INVEEPROMFault: 33554432,
  RCDFault: 67108864,
  GridRelayFault: 134217728,
  OffGridRelayFault: 268435456,
  PVConnDirFault: 536870912,
  ChargerRelayFault: 1073741824,
  EarthRelayFault: 2147483648,
} as const

export const faultCode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU32LSBToMultiEnumConvertor(FaultCodes),
    multiple: 1,
    length: 2,
  }
}

export const TargetSetType = {
  Set: 1,
  Update: 2,
}
export const targetSetType = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(TargetSetType),
    multiple: 1,
    length: 1,
  }
}

export const ModbusPowerControl = {
  DisableRemoteControl: 0,
  EnablePowerControl: 1,
  EnableElectricQuantityControl: 2,
  EnableSOCTargetControl: 3,
  PushPower: 4,
  PushPowerZero: 5,
  SelfConsumeChargeDischarge: 6,
  SelfConsumeChargeOnly: 7,
} as const

export const modbusPowerControl = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(ModbusPowerControl),
    multiple: 1,
    length: 1,
  }
}

export const RunMode = {
  Waiting: 0,
  Checking: 1,
  NormalMode: 2,
  Fault: 3,
  PermanentFaultMode: 4,
  UpdateMode: 5,
  EPSCheckMode: 6, // Off grid waiting
  EPSMode: 7, // Off grid
  SelfTest: 8,
  IdleMode: 9,
  Standby: 10,
  NormalR: 20, // Normal (R)
  NormalModeTOUS: 21, // NormalMode(TOU-S)
  NormalModeTOUDischarging: 23,
} as const

export const runMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(RunMode),
    multiple: 1,
    length: 1,
  }
}

export const UseMode = {
  SelfUse: 0,
  FeedInPriority: 1,
  BackupMode: 2,
  ManualMode: 3,
  PeakShaving: 4,
  SmartSchedule: 5,
} as const

export const useMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(UseMode),
    multiple: 1,
    length: 1,
  }
}

export const ManualMode = {
  StopChargeDischarge: 0,
  ForceCharge: 1,
  ForceDischarge: 2,
} as const

export const manualMode = <T extends RKey, RW extends RWType, ReturnT>(
  r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
) => {
  return {
    ...r,
    convertor: createU16ToSingleEnumConvertor(ManualMode),
    multiple: 1,
    length: 1,
  }
}

// export const appMode = <T extends RKey, RW extends RWType, ReturnT>(
//   r: StrictOmit<Register<T, RW, ReturnT>, "convertor" | "length">,
// ) => {
//   return {
//     ...r,
//     convertor: useModeCovertor,
//     // unit: "HH:MM",
//     multiple: 1,
//     length: 1,
//     // remark: "[0,23],[0,59]",
//   }
// }

export type SolaxSpecificRegisters =
  | ReturnType<typeof time>
  | ReturnType<typeof faultCode>
  | ReturnType<typeof targetSetType>
  | ReturnType<typeof modbusPowerControl>
  | ReturnType<typeof runMode>
  | ReturnType<typeof useMode>
  | ReturnType<typeof manualMode>
