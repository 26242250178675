import { ApplicationPage } from "@prisma/client"

import {
  InverterControlPlanPageConfig,
  InverterOverviewPageConfig,
  InverterSettingsPageConfig,
  InverterStatsPageConfig,
  TenantCommonPageConfig,
} from "../schemas/tenantPageConfig"

export const inverterOverviewPageDefaultConfig = {
  controlSwitchSection: null,
  inverterConditionsSection: null,
} satisfies InverterOverviewPageConfig

export const invertersStatsPageDefaultConfig = {
  inverterMonthlyStats: null,
  commandsStats: null,
} satisfies InverterStatsPageConfig

export const invertersSettingsPageDefaultConfig = {
  inverterPositionInputType: "addressAutocomplete",
  shouldDisplayAmortizationField: true,
  hasTermsStepBeforeEnablingControl: false,
  shouldShowConnectToMyDelta: false,
  controlSwitchContentType: "commands",
  cleanupModalContentType: "rewardConditions",
} satisfies InverterSettingsPageConfig

export const inverterControlPlanPageDefaultConfig = null satisfies InverterControlPlanPageConfig

export const tenantCommonConfigSchema = {
  hasTopHeader: true,
} satisfies TenantCommonPageConfig

export const tenantPageDefaultConfigs = {
  [ApplicationPage.COMMON]: tenantCommonConfigSchema,
  [ApplicationPage.INVERTER_OVERVIEW]: inverterOverviewPageDefaultConfig,
  [ApplicationPage.INVERTER_STATS]: invertersStatsPageDefaultConfig,
  [ApplicationPage.INVERTER_SETTINGS]: invertersSettingsPageDefaultConfig,
  [ApplicationPage.INVERTER_CONTROL_PLAN]: inverterControlPlanPageDefaultConfig,
} as const
