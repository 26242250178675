// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.174.0
//   protoc               v5.27.0
// source: deltalink.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal.js"

export const protobufPackage = ""

/** import "nanopb.proto"; */

export enum EmodbusMessageResult {
  DELTA_MODBUS_SUCCESS = 0,
  DELTA_MODBUS_ENCODING_ERROR_CODE = 241,
  DELTA_MODBUS_UNKNOWN_FUNCTION_CODE = 256,
  DELTA_MODBUS_INVALID_DATA_LENGTH_READ = 257,
  DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_HOLD = 258,
  DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_MULT = 259,
  ILLEGAL_FUNCTION = 1,
  ILLEGAL_DATA_ADDRESS = 2,
  ILLEGAL_DATA_VALUE = 3,
  SERVER_DEVICE_FAILURE = 4,
  ACKNOWLEDGE = 5,
  SERVER_DEVICE_BUSY = 6,
  NEGATIVE_ACKNOWLEDGE = 7,
  MEMORY_PARITY_ERROR = 8,
  GATEWAY_PATH_UNAVAIL = 10,
  GATEWAY_TARGET_NO_RESP = 11,
  TIMEOUT = 224,
  INVALID_SERVER = 225,
  CRC_ERROR = 226,
  FC_MISMATCH = 227,
  SERVER_ID_MISMATCH = 228,
  PACKET_LENGTH_ERROR = 229,
  PARAMETER_COUNT_ERROR = 230,
  PARAMETER_LIMIT_ERROR = 231,
  REQUEST_QUEUE_FULL = 232,
  ILLEGAL_IP_OR_PORT = 233,
  IP_CONNECTION_FAILED = 234,
  TCP_HEAD_MISMATCH = 235,
  EMPTY_MESSAGE = 236,
  ASCII_FRAME_ERR = 237,
  ASCII_CRC_ERR = 238,
  ASCII_INVALID_CHAR = 239,
  BROADCAST_ERROR = 240,
  UNDEFINED_ERROR = 255,
  UNRECOGNIZED = -1,
}

export function emodbusMessageResultFromJSON(object: any): EmodbusMessageResult {
  switch (object) {
    case 0:
    case "DELTA_MODBUS_SUCCESS":
      return EmodbusMessageResult.DELTA_MODBUS_SUCCESS
    case 241:
    case "DELTA_MODBUS_ENCODING_ERROR_CODE":
      return EmodbusMessageResult.DELTA_MODBUS_ENCODING_ERROR_CODE
    case 256:
    case "DELTA_MODBUS_UNKNOWN_FUNCTION_CODE":
      return EmodbusMessageResult.DELTA_MODBUS_UNKNOWN_FUNCTION_CODE
    case 257:
    case "DELTA_MODBUS_INVALID_DATA_LENGTH_READ":
      return EmodbusMessageResult.DELTA_MODBUS_INVALID_DATA_LENGTH_READ
    case 258:
    case "DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_HOLD":
      return EmodbusMessageResult.DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_HOLD
    case 259:
    case "DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_MULT":
      return EmodbusMessageResult.DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_MULT
    case 1:
    case "ILLEGAL_FUNCTION":
      return EmodbusMessageResult.ILLEGAL_FUNCTION
    case 2:
    case "ILLEGAL_DATA_ADDRESS":
      return EmodbusMessageResult.ILLEGAL_DATA_ADDRESS
    case 3:
    case "ILLEGAL_DATA_VALUE":
      return EmodbusMessageResult.ILLEGAL_DATA_VALUE
    case 4:
    case "SERVER_DEVICE_FAILURE":
      return EmodbusMessageResult.SERVER_DEVICE_FAILURE
    case 5:
    case "ACKNOWLEDGE":
      return EmodbusMessageResult.ACKNOWLEDGE
    case 6:
    case "SERVER_DEVICE_BUSY":
      return EmodbusMessageResult.SERVER_DEVICE_BUSY
    case 7:
    case "NEGATIVE_ACKNOWLEDGE":
      return EmodbusMessageResult.NEGATIVE_ACKNOWLEDGE
    case 8:
    case "MEMORY_PARITY_ERROR":
      return EmodbusMessageResult.MEMORY_PARITY_ERROR
    case 10:
    case "GATEWAY_PATH_UNAVAIL":
      return EmodbusMessageResult.GATEWAY_PATH_UNAVAIL
    case 11:
    case "GATEWAY_TARGET_NO_RESP":
      return EmodbusMessageResult.GATEWAY_TARGET_NO_RESP
    case 224:
    case "TIMEOUT":
      return EmodbusMessageResult.TIMEOUT
    case 225:
    case "INVALID_SERVER":
      return EmodbusMessageResult.INVALID_SERVER
    case 226:
    case "CRC_ERROR":
      return EmodbusMessageResult.CRC_ERROR
    case 227:
    case "FC_MISMATCH":
      return EmodbusMessageResult.FC_MISMATCH
    case 228:
    case "SERVER_ID_MISMATCH":
      return EmodbusMessageResult.SERVER_ID_MISMATCH
    case 229:
    case "PACKET_LENGTH_ERROR":
      return EmodbusMessageResult.PACKET_LENGTH_ERROR
    case 230:
    case "PARAMETER_COUNT_ERROR":
      return EmodbusMessageResult.PARAMETER_COUNT_ERROR
    case 231:
    case "PARAMETER_LIMIT_ERROR":
      return EmodbusMessageResult.PARAMETER_LIMIT_ERROR
    case 232:
    case "REQUEST_QUEUE_FULL":
      return EmodbusMessageResult.REQUEST_QUEUE_FULL
    case 233:
    case "ILLEGAL_IP_OR_PORT":
      return EmodbusMessageResult.ILLEGAL_IP_OR_PORT
    case 234:
    case "IP_CONNECTION_FAILED":
      return EmodbusMessageResult.IP_CONNECTION_FAILED
    case 235:
    case "TCP_HEAD_MISMATCH":
      return EmodbusMessageResult.TCP_HEAD_MISMATCH
    case 236:
    case "EMPTY_MESSAGE":
      return EmodbusMessageResult.EMPTY_MESSAGE
    case 237:
    case "ASCII_FRAME_ERR":
      return EmodbusMessageResult.ASCII_FRAME_ERR
    case 238:
    case "ASCII_CRC_ERR":
      return EmodbusMessageResult.ASCII_CRC_ERR
    case 239:
    case "ASCII_INVALID_CHAR":
      return EmodbusMessageResult.ASCII_INVALID_CHAR
    case 240:
    case "BROADCAST_ERROR":
      return EmodbusMessageResult.BROADCAST_ERROR
    case 255:
    case "UNDEFINED_ERROR":
      return EmodbusMessageResult.UNDEFINED_ERROR
    case -1:
    case "UNRECOGNIZED":
    default:
      return EmodbusMessageResult.UNRECOGNIZED
  }
}

export function emodbusMessageResultToJSON(object: EmodbusMessageResult): string {
  switch (object) {
    case EmodbusMessageResult.DELTA_MODBUS_SUCCESS:
      return "DELTA_MODBUS_SUCCESS"
    case EmodbusMessageResult.DELTA_MODBUS_ENCODING_ERROR_CODE:
      return "DELTA_MODBUS_ENCODING_ERROR_CODE"
    case EmodbusMessageResult.DELTA_MODBUS_UNKNOWN_FUNCTION_CODE:
      return "DELTA_MODBUS_UNKNOWN_FUNCTION_CODE"
    case EmodbusMessageResult.DELTA_MODBUS_INVALID_DATA_LENGTH_READ:
      return "DELTA_MODBUS_INVALID_DATA_LENGTH_READ"
    case EmodbusMessageResult.DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_HOLD:
      return "DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_HOLD"
    case EmodbusMessageResult.DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_MULT:
      return "DELTA_MODBUS_INVALID_DATA_LENGTH_WRITE_MULT"
    case EmodbusMessageResult.ILLEGAL_FUNCTION:
      return "ILLEGAL_FUNCTION"
    case EmodbusMessageResult.ILLEGAL_DATA_ADDRESS:
      return "ILLEGAL_DATA_ADDRESS"
    case EmodbusMessageResult.ILLEGAL_DATA_VALUE:
      return "ILLEGAL_DATA_VALUE"
    case EmodbusMessageResult.SERVER_DEVICE_FAILURE:
      return "SERVER_DEVICE_FAILURE"
    case EmodbusMessageResult.ACKNOWLEDGE:
      return "ACKNOWLEDGE"
    case EmodbusMessageResult.SERVER_DEVICE_BUSY:
      return "SERVER_DEVICE_BUSY"
    case EmodbusMessageResult.NEGATIVE_ACKNOWLEDGE:
      return "NEGATIVE_ACKNOWLEDGE"
    case EmodbusMessageResult.MEMORY_PARITY_ERROR:
      return "MEMORY_PARITY_ERROR"
    case EmodbusMessageResult.GATEWAY_PATH_UNAVAIL:
      return "GATEWAY_PATH_UNAVAIL"
    case EmodbusMessageResult.GATEWAY_TARGET_NO_RESP:
      return "GATEWAY_TARGET_NO_RESP"
    case EmodbusMessageResult.TIMEOUT:
      return "TIMEOUT"
    case EmodbusMessageResult.INVALID_SERVER:
      return "INVALID_SERVER"
    case EmodbusMessageResult.CRC_ERROR:
      return "CRC_ERROR"
    case EmodbusMessageResult.FC_MISMATCH:
      return "FC_MISMATCH"
    case EmodbusMessageResult.SERVER_ID_MISMATCH:
      return "SERVER_ID_MISMATCH"
    case EmodbusMessageResult.PACKET_LENGTH_ERROR:
      return "PACKET_LENGTH_ERROR"
    case EmodbusMessageResult.PARAMETER_COUNT_ERROR:
      return "PARAMETER_COUNT_ERROR"
    case EmodbusMessageResult.PARAMETER_LIMIT_ERROR:
      return "PARAMETER_LIMIT_ERROR"
    case EmodbusMessageResult.REQUEST_QUEUE_FULL:
      return "REQUEST_QUEUE_FULL"
    case EmodbusMessageResult.ILLEGAL_IP_OR_PORT:
      return "ILLEGAL_IP_OR_PORT"
    case EmodbusMessageResult.IP_CONNECTION_FAILED:
      return "IP_CONNECTION_FAILED"
    case EmodbusMessageResult.TCP_HEAD_MISMATCH:
      return "TCP_HEAD_MISMATCH"
    case EmodbusMessageResult.EMPTY_MESSAGE:
      return "EMPTY_MESSAGE"
    case EmodbusMessageResult.ASCII_FRAME_ERR:
      return "ASCII_FRAME_ERR"
    case EmodbusMessageResult.ASCII_CRC_ERR:
      return "ASCII_CRC_ERR"
    case EmodbusMessageResult.ASCII_INVALID_CHAR:
      return "ASCII_INVALID_CHAR"
    case EmodbusMessageResult.BROADCAST_ERROR:
      return "BROADCAST_ERROR"
    case EmodbusMessageResult.UNDEFINED_ERROR:
      return "UNDEFINED_ERROR"
    case EmodbusMessageResult.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED"
  }
}

export enum LastWillStrategy {
  START_HOUR = 0,
  UNRECOGNIZED = -1,
}

export function lastWillStrategyFromJSON(object: any): LastWillStrategy {
  switch (object) {
    case 0:
    case "START_HOUR":
      return LastWillStrategy.START_HOUR
    case -1:
    case "UNRECOGNIZED":
    default:
      return LastWillStrategy.UNRECOGNIZED
  }
}

export function lastWillStrategyToJSON(object: LastWillStrategy): string {
  switch (object) {
    case LastWillStrategy.START_HOUR:
      return "START_HOUR"
    case LastWillStrategy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED"
  }
}

export interface EmodbusMessage {
  requestId: number
  functionCode: number
  address: number
  /** [(nanopb).max_size = 256]; */
  data: Buffer
}

export interface EmodbusErrorMessage {
  requestId: number
  address: number
  /** @deprecated */
  errorCode: number
  errorCodeEnum?: EmodbusMessageResult | undefined
}

export interface HttpResponseChunkMessage {
  /** [(nanopb).max_size = 64]; */
  requestId: Buffer
  seqNumber: number
  /** [(nanopb).max_size = 512]; */
  data: Buffer
}

export interface ModbusBridgeConfigType {
  /** [(nanopb).max_size = 4, (nanopb).fixed_length = true]; */
  ip: Buffer
  port: number
  aliasId: number
  serverId: number
}

export interface LastWillMessage {
  /** [(nanopb).max_size = 4, (nanopb).fixed_length = true]; */
  ip: Buffer
  port: number
  serverId: number
  message: EmodbusMessage | undefined
  strategy: LastWillStrategy
}

export interface ConfigMessage {
  /** [(nanopb).max_size = 64]; // hash: MD5, SHA-1, SHA-256 fits here */
  version: string
  /** [(nanopb).max_count = 5]; */
  bridgeConfigs: ModbusBridgeConfigType[]
  /** [(nanopb).max_count = 50, (nanopb).type = FT_POINTER]; */
  lastWill: LastWillMessage[]
}

function createBaseEmodbusMessage(): EmodbusMessage {
  return { requestId: 0, functionCode: 0, address: 0, data: Buffer.alloc(0) }
}

export const EmodbusMessage = {
  encode(message: EmodbusMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId)
    }
    if (message.functionCode !== 0) {
      writer.uint32(16).uint32(message.functionCode)
    }
    if (message.address !== 0) {
      writer.uint32(24).uint32(message.address)
    }
    if (message.data.length !== 0) {
      writer.uint32(34).bytes(message.data)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmodbusMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseEmodbusMessage()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.requestId = reader.uint32()
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.functionCode = reader.uint32()
          continue
        case 3:
          if (tag !== 24) {
            break
          }

          message.address = reader.uint32()
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.data = reader.bytes() as Buffer
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): EmodbusMessage {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      functionCode: isSet(object.functionCode) ? globalThis.Number(object.functionCode) : 0,
      address: isSet(object.address) ? globalThis.Number(object.address) : 0,
      data: isSet(object.data) ? Buffer.from(bytesFromBase64(object.data)) : Buffer.alloc(0),
    }
  },

  toJSON(message: EmodbusMessage): unknown {
    const obj: any = {}
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId)
    }
    if (message.functionCode !== 0) {
      obj.functionCode = Math.round(message.functionCode)
    }
    if (message.address !== 0) {
      obj.address = Math.round(message.address)
    }
    if (message.data.length !== 0) {
      obj.data = base64FromBytes(message.data)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<EmodbusMessage>, I>>(base?: I): EmodbusMessage {
    return EmodbusMessage.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<EmodbusMessage>, I>>(object: I): EmodbusMessage {
    const message = createBaseEmodbusMessage()
    message.requestId = object.requestId ?? 0
    message.functionCode = object.functionCode ?? 0
    message.address = object.address ?? 0
    message.data = object.data ?? Buffer.alloc(0)
    return message
  },
}

function createBaseEmodbusErrorMessage(): EmodbusErrorMessage {
  return { requestId: 0, address: 0, errorCode: 0, errorCodeEnum: undefined }
}

export const EmodbusErrorMessage = {
  encode(message: EmodbusErrorMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId)
    }
    if (message.address !== 0) {
      writer.uint32(16).uint32(message.address)
    }
    if (message.errorCode !== 0) {
      writer.uint32(24).uint32(message.errorCode)
    }
    if (message.errorCodeEnum !== undefined) {
      writer.uint32(32).int32(message.errorCodeEnum)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmodbusErrorMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseEmodbusErrorMessage()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.requestId = reader.uint32()
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.address = reader.uint32()
          continue
        case 3:
          if (tag !== 24) {
            break
          }

          message.errorCode = reader.uint32()
          continue
        case 4:
          if (tag !== 32) {
            break
          }

          message.errorCodeEnum = reader.int32() as any
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): EmodbusErrorMessage {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      address: isSet(object.address) ? globalThis.Number(object.address) : 0,
      errorCode: isSet(object.errorCode) ? globalThis.Number(object.errorCode) : 0,
      errorCodeEnum: isSet(object.errorCodeEnum) ? emodbusMessageResultFromJSON(object.errorCodeEnum) : undefined,
    }
  },

  toJSON(message: EmodbusErrorMessage): unknown {
    const obj: any = {}
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId)
    }
    if (message.address !== 0) {
      obj.address = Math.round(message.address)
    }
    if (message.errorCode !== 0) {
      obj.errorCode = Math.round(message.errorCode)
    }
    if (message.errorCodeEnum !== undefined) {
      obj.errorCodeEnum = emodbusMessageResultToJSON(message.errorCodeEnum)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<EmodbusErrorMessage>, I>>(base?: I): EmodbusErrorMessage {
    return EmodbusErrorMessage.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<EmodbusErrorMessage>, I>>(object: I): EmodbusErrorMessage {
    const message = createBaseEmodbusErrorMessage()
    message.requestId = object.requestId ?? 0
    message.address = object.address ?? 0
    message.errorCode = object.errorCode ?? 0
    message.errorCodeEnum = object.errorCodeEnum ?? undefined
    return message
  },
}

function createBaseHttpResponseChunkMessage(): HttpResponseChunkMessage {
  return { requestId: Buffer.alloc(0), seqNumber: 0, data: Buffer.alloc(0) }
}

export const HttpResponseChunkMessage = {
  encode(message: HttpResponseChunkMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId.length !== 0) {
      writer.uint32(10).bytes(message.requestId)
    }
    if (message.seqNumber !== 0) {
      writer.uint32(16).uint32(message.seqNumber)
    }
    if (message.data.length !== 0) {
      writer.uint32(26).bytes(message.data)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HttpResponseChunkMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseHttpResponseChunkMessage()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.requestId = reader.bytes() as Buffer
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.seqNumber = reader.uint32()
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.data = reader.bytes() as Buffer
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): HttpResponseChunkMessage {
    return {
      requestId: isSet(object.requestId) ? Buffer.from(bytesFromBase64(object.requestId)) : Buffer.alloc(0),
      seqNumber: isSet(object.seqNumber) ? globalThis.Number(object.seqNumber) : 0,
      data: isSet(object.data) ? Buffer.from(bytesFromBase64(object.data)) : Buffer.alloc(0),
    }
  },

  toJSON(message: HttpResponseChunkMessage): unknown {
    const obj: any = {}
    if (message.requestId.length !== 0) {
      obj.requestId = base64FromBytes(message.requestId)
    }
    if (message.seqNumber !== 0) {
      obj.seqNumber = Math.round(message.seqNumber)
    }
    if (message.data.length !== 0) {
      obj.data = base64FromBytes(message.data)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<HttpResponseChunkMessage>, I>>(base?: I): HttpResponseChunkMessage {
    return HttpResponseChunkMessage.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<HttpResponseChunkMessage>, I>>(object: I): HttpResponseChunkMessage {
    const message = createBaseHttpResponseChunkMessage()
    message.requestId = object.requestId ?? Buffer.alloc(0)
    message.seqNumber = object.seqNumber ?? 0
    message.data = object.data ?? Buffer.alloc(0)
    return message
  },
}

function createBaseModbusBridgeConfigType(): ModbusBridgeConfigType {
  return { ip: Buffer.alloc(0), port: 0, aliasId: 0, serverId: 0 }
}

export const ModbusBridgeConfigType = {
  encode(message: ModbusBridgeConfigType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip.length !== 0) {
      writer.uint32(10).bytes(message.ip)
    }
    if (message.port !== 0) {
      writer.uint32(16).uint32(message.port)
    }
    if (message.aliasId !== 0) {
      writer.uint32(24).uint32(message.aliasId)
    }
    if (message.serverId !== 0) {
      writer.uint32(32).uint32(message.serverId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModbusBridgeConfigType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseModbusBridgeConfigType()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.ip = reader.bytes() as Buffer
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.port = reader.uint32()
          continue
        case 3:
          if (tag !== 24) {
            break
          }

          message.aliasId = reader.uint32()
          continue
        case 4:
          if (tag !== 32) {
            break
          }

          message.serverId = reader.uint32()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ModbusBridgeConfigType {
    return {
      ip: isSet(object.ip) ? Buffer.from(bytesFromBase64(object.ip)) : Buffer.alloc(0),
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      aliasId: isSet(object.aliasId) ? globalThis.Number(object.aliasId) : 0,
      serverId: isSet(object.serverId) ? globalThis.Number(object.serverId) : 0,
    }
  },

  toJSON(message: ModbusBridgeConfigType): unknown {
    const obj: any = {}
    if (message.ip.length !== 0) {
      obj.ip = base64FromBytes(message.ip)
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port)
    }
    if (message.aliasId !== 0) {
      obj.aliasId = Math.round(message.aliasId)
    }
    if (message.serverId !== 0) {
      obj.serverId = Math.round(message.serverId)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ModbusBridgeConfigType>, I>>(base?: I): ModbusBridgeConfigType {
    return ModbusBridgeConfigType.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ModbusBridgeConfigType>, I>>(object: I): ModbusBridgeConfigType {
    const message = createBaseModbusBridgeConfigType()
    message.ip = object.ip ?? Buffer.alloc(0)
    message.port = object.port ?? 0
    message.aliasId = object.aliasId ?? 0
    message.serverId = object.serverId ?? 0
    return message
  },
}

function createBaseLastWillMessage(): LastWillMessage {
  return { ip: Buffer.alloc(0), port: 0, serverId: 0, message: undefined, strategy: 0 }
}

export const LastWillMessage = {
  encode(message: LastWillMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ip.length !== 0) {
      writer.uint32(10).bytes(message.ip)
    }
    if (message.port !== 0) {
      writer.uint32(16).uint32(message.port)
    }
    if (message.serverId !== 0) {
      writer.uint32(24).uint32(message.serverId)
    }
    if (message.message !== undefined) {
      EmodbusMessage.encode(message.message, writer.uint32(34).fork()).ldelim()
    }
    if (message.strategy !== 0) {
      writer.uint32(40).int32(message.strategy)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LastWillMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseLastWillMessage()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.ip = reader.bytes() as Buffer
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.port = reader.uint32()
          continue
        case 3:
          if (tag !== 24) {
            break
          }

          message.serverId = reader.uint32()
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.message = EmodbusMessage.decode(reader, reader.uint32())
          continue
        case 5:
          if (tag !== 40) {
            break
          }

          message.strategy = reader.int32() as any
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): LastWillMessage {
    return {
      ip: isSet(object.ip) ? Buffer.from(bytesFromBase64(object.ip)) : Buffer.alloc(0),
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      serverId: isSet(object.serverId) ? globalThis.Number(object.serverId) : 0,
      message: isSet(object.message) ? EmodbusMessage.fromJSON(object.message) : undefined,
      strategy: isSet(object.strategy) ? lastWillStrategyFromJSON(object.strategy) : 0,
    }
  },

  toJSON(message: LastWillMessage): unknown {
    const obj: any = {}
    if (message.ip.length !== 0) {
      obj.ip = base64FromBytes(message.ip)
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port)
    }
    if (message.serverId !== 0) {
      obj.serverId = Math.round(message.serverId)
    }
    if (message.message !== undefined) {
      obj.message = EmodbusMessage.toJSON(message.message)
    }
    if (message.strategy !== 0) {
      obj.strategy = lastWillStrategyToJSON(message.strategy)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<LastWillMessage>, I>>(base?: I): LastWillMessage {
    return LastWillMessage.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<LastWillMessage>, I>>(object: I): LastWillMessage {
    const message = createBaseLastWillMessage()
    message.ip = object.ip ?? Buffer.alloc(0)
    message.port = object.port ?? 0
    message.serverId = object.serverId ?? 0
    message.message =
      object.message !== undefined && object.message !== null ? EmodbusMessage.fromPartial(object.message) : undefined
    message.strategy = object.strategy ?? 0
    return message
  },
}

function createBaseConfigMessage(): ConfigMessage {
  return { version: "", bridgeConfigs: [], lastWill: [] }
}

export const ConfigMessage = {
  encode(message: ConfigMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== "") {
      writer.uint32(10).string(message.version)
    }
    for (const v of message.bridgeConfigs) {
      ModbusBridgeConfigType.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    for (const v of message.lastWill) {
      LastWillMessage.encode(v!, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfigMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseConfigMessage()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.version = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.bridgeConfigs.push(ModbusBridgeConfigType.decode(reader, reader.uint32()))
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.lastWill.push(LastWillMessage.decode(reader, reader.uint32()))
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ConfigMessage {
    return {
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      bridgeConfigs: globalThis.Array.isArray(object?.bridgeConfigs)
        ? object.bridgeConfigs.map((e: any) => ModbusBridgeConfigType.fromJSON(e))
        : [],
      lastWill: globalThis.Array.isArray(object?.lastWill)
        ? object.lastWill.map((e: any) => LastWillMessage.fromJSON(e))
        : [],
    }
  },

  toJSON(message: ConfigMessage): unknown {
    const obj: any = {}
    if (message.version !== "") {
      obj.version = message.version
    }
    if (message.bridgeConfigs?.length) {
      obj.bridgeConfigs = message.bridgeConfigs.map((e) => ModbusBridgeConfigType.toJSON(e))
    }
    if (message.lastWill?.length) {
      obj.lastWill = message.lastWill.map((e) => LastWillMessage.toJSON(e))
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ConfigMessage>, I>>(base?: I): ConfigMessage {
    return ConfigMessage.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ConfigMessage>, I>>(object: I): ConfigMessage {
    const message = createBaseConfigMessage()
    message.version = object.version ?? ""
    message.bridgeConfigs = object.bridgeConfigs?.map((e) => ModbusBridgeConfigType.fromPartial(e)) || []
    message.lastWill = object.lastWill?.map((e) => LastWillMessage.fromPartial(e)) || []
    return message
  },
}

function bytesFromBase64(b64: string): Uint8Array {
  return Uint8Array.from(globalThis.Buffer.from(b64, "base64"))
}

function base64FromBytes(arr: Uint8Array): string {
  return globalThis.Buffer.from(arr).toString("base64")
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
    ? globalThis.Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends {}
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never }

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
