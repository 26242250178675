import { s16, s32LSB, string, u8H, u8L, u16, u32LSB } from "../../modbus/dataTypes"
import { ModBusFunctionCode } from "../../modbus/modbusProtocol"
import { faultCode, manualMode, modbusPowerControl, runMode, targetSetType, time, useMode } from "./dataTypes"

// TOHLE JE PRO GEN4

// sn zacina na neco jako H34A10I75
// kuk sem https://github.com/wills106/homeassistant-solax-modbus/blob/main/custom_components/solax_modbus/plugin_solax.py

const SolaxBaseRegisters = [
  string({
    key: "serialNumber",
    address: 0,
    name: "InverterSN",
    rw: "RO",
    length: 7,
  }),

  string({
    key: "serialNumberWallBox",
    address: 0x600,
    name: "Wallbox InverterSN",
    rw: "RO",
    length: 7,
  }),

  u16({
    key: "chargeFloatVolt",
    address: 0x008e,
    name: "Charge_floatVolt",
    rw: "RO",
    remark: "Lead-acid battery charge_float voltage",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "batteryChargeMaxCurrent",
    address: 0x0090,
    name: "Battery_ChargeMaxCurrent",
    rw: "RO",
    remark: "Lead-acid battery charge maximum current",
    unit: "A",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "batteryDischargeMaxCurrent",
    address: 0x0091,
    name: "Battery_DischargeMaxCurrent",
    rw: "RO",
    remark: "Lead-acid battery discharge maximum current",
    unit: "A",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "absorptVoltage",
    address: 0x0092,
    name: "absorpt_voltage",
    rw: "RO",
    remark: "Lead-acid battery discharge maximum current",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "mainBreakerCurrentLimit",
    address: 0x00d7,
    name: "MainBreakerCurrentLimit",
    rw: "RO",
    unit: "A",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  // FC4
  u16({
    key: "busVolt",
    address: 0x0066,
    name: "BusVolt",
    rw: "RO",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "batteryVoltageCharge",
    address: 0x0014,
    name: "BatVoltage_Charge1",
    rw: "RO",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "gv1",
    address: 0x0000,
    name: "gv1",
    rw: "RO",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
  u16({
    key: "gridFrequency",
    address: 0x0007,
    name: "GridFrequency",
    rw: "RO",
    unit: "Hz",
    multiple: 100,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "feedInPower",
    address: 0x0046,
    name: "feedin_power",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Na pate domu - tzn grid power, plus znamená ven, mínus dovnutř",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "feedInEnergyToday",
    address: 0x0098,
    name: "feedin_energy_today energy to the grid (meter) (0x98:LSB,0x99:MSB)",
    rw: "RO",
    unit: "kWh",
    multiple: 100,
    remark: "Objem energie dodaný do sítě za dnešek",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "consumedEnergyToday",
    address: 0x009a,
    name: "energy form the grid (meter)(0x9A:LSB,0x9B:MSB)",
    rw: "RO",
    unit: "kWh",
    multiple: 100,
    remark: "Objem energie odebraný ze sítě za dnešek",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "energyToGrid",
    address: 0x0048,
    name: "feedin_energy_total(meter) ",
    rw: "RO",
    unit: "kWh",
    multiple: 100,
    remark: "Objem energie dodané do sítě celkem",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "energyFromGrid",
    address: 0x004a,
    name: "consum_energy_total(meter)",
    rw: "RO",
    unit: "kWh",
    multiple: 100, // v dokumentaci je 0.01
    // multiple: 10,
    remark: "Objem energie odebrané ze sítě celkem",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "chargeDischargePower",
    address: 0x0114,
    name: "Charge_Discharg_Power",
    rw: "RO",
    unit: "W",
    multiple: 1,
    remark: "Charge_Discharg_Power (0x114:LSB,0x115:MSB)",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "chargeableElectricCapacity",
    address: 0x0116,
    name: "ChargeableElectricCapacity",
    rw: "RO",
    unit: "Wh",
    multiple: 1,
    remark: "ChargeableElectricCapacity (0x116:LSB,0x117:MSB)",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "dischargeableElectricCapacity",
    address: 0x0118,
    name: "DischargeableElectricCapacity",
    rw: "RO",
    unit: "Wh",
    multiple: 1,
    remark: "DischargeableElectricCapacity (0x118:LSB,0x119:MSB)",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "totalSolarEnergyToday",
    address: 0x0096,
    name: "total_solar_yield_today",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    remark: "Objem energie vyrobený panely za dnešek - elektřina šla buď do baterie a nebo do invertoru přímo",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "bmsBatteryCapacity",
    address: 0x0026,
    name: "BMS_BatteryCapacity",
    rw: "RO",
    unit: "Wh",
    multiple: 1,
    remark: "BMS_BatteryCapacity",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "eChargeToday",
    address: 0x0091,
    name: "EchargeToday",
    rw: "RO",
    remark: "Objem elektřiny nabitý ze sítě do baterie za dnešek",
    unit: "kWh",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "eChargeTotal",
    address: 0x0092,
    name: "EchargeTotal",
    rw: "RO",
    remark: "Objem elektřiny nabitý ze sítě do baterie celkově",
    unit: "kWh",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "gridPower",
    address: 0x0002,
    name: "GridPower = vykon stridace",
    rw: "RO",
    unit: "W",
    remark:
      "Výkon na výstupu střídače - obsahuje tedy výkonu LOADu a GRIDu, je záporný, pokud se nabíjí baterka ze sítě",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "consumeOffPower",
    address: 0x0125,
    name: "Power consumption off trigger point",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s16({
    key: "battPowerCharge1",
    address: 0x0016,
    name: "Batpower Charge 1",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "totalSolarEnergy",
    address: 0x0094,
    name: "SolarEnergyTotal",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    remark: "Objem energie vyrobený panely celkem - elektřina šla buď do baterie a nebo do invertoru přímo",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "outputEnergyCharge",
    address: 0x001d,
    name: "Output energy charge",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    remark: "Objem energie vybitý z baterie celkem",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "inputEnergyCharge",
    address: 0x0021,
    name: "Input energy charge",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    remark: "Objem energie nabitý do baterie celkem",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "parallelSettings",
    address: 0x0130,
    name: "Parallel Setting ",
    remark: "",
    rw: "RO",

    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "eTodayToGrid",
    address: 0x0050,
    name: "Etoday_togrid",
    remark: "Objem energie vyrobený střídačem, tedy součet LOAD a GRID-OUT za dnešek",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u32LSB({
    key: "eTotalToGrid",
    address: 0x0052,
    name: "Etotal_togrid",
    remark: "Objem energie vyrobený střídačem, tedy součet LOAD a GRID-OUT celkem",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "BatPower_All",
    address: 0x01fa,
    name: "BatPower_All",
    rw: "RO",
    unit: "W",
    remark: "",
    multiple: 1,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "BatCurrent_All",
    address: 0x01fc,
    name: "BatCurrent_All",
    rw: "RO",
    unit: "A",
    remark: "",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "ChargePowerLimit_All",
    address: 0x01fe,
    name: "ChargePowerLimit_All",
    rw: "RO",
    unit: "W",
    remark: "",
    multiple: 1,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "DischargePowerLimit_All",
    address: 0x01fe,
    name: "DischargePowerLimit_All",
    rw: "RO",
    unit: "W",
    remark: "",
    multiple: 1,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "inputEnergyChargeToday",
    address: 0x0023,
    name: "Input energy charge TODAY",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    remark: "Objem energie nabitý do baterie za dnešek",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
  u16({
    key: "outputEnergyChargeToday",
    address: 0x0020,
    name: "Output energy charge TODAY",
    rw: "RO",
    unit: "kWh",
    multiple: 10,
    remark: "Objem energie vybitý z baterie za dnešek",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "cellVoltageHigh",
    address: 0x00bc,
    name: "Cell_Voltage_High",
    rw: "RO",
    unit: "V",
    multiple: 1000,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "cellVoltageLow",
    address: 0x00bd,
    name: "Cell_Voltage_Low",
    rw: "RO",
    unit: "V",
    multiple: 1000,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  faultCode({
    key: "inverterFaultMessage",
    address: 0x0040,
    name: "InvFaultMessage",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  // u16({
  //   key: "pwPowerA",
  //   address: 0x02,
  //   name: "PVPower Channel A ALL",
  //   rw: "RO",
  //   unit: "W",
  //   functionCode: ModBusFunctionCode.ReadInputRegisters,
  // }),

  // u32LSB({
  //   key: "pwPowerB",
  //   address: 0x01f4,
  //   name: "PVPower Channel A ALL",
  //   rw: "RO",
  //   unit: "W",
  //   functionCode: ModBusFunctionCode.ReadInputRegisters,
  // }),

  u16({
    key: "pvCurrent1",
    address: 0x0005,
    name: "pv1 current",
    rw: "RO",
    unit: "A",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "pvVoltage1",
    address: 0x0003,
    name: "pv1 voltage",
    rw: "RO",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "pvCurrent2",
    address: 0x0004,
    name: "pv2 current",
    rw: "RO",
    unit: "A",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "pvVoltage2",
    address: 0x0006,
    name: "pv2 voltage",
    rw: "RO",
    unit: "V",
    multiple: 10,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "powerDc1",
    address: 0x000a,
    name: "Powerdc1",
    rw: "RO",
    unit: "W",
    remark: "výkon solárů na stringu 1",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "powerDc2",
    address: 0x000b,
    name: "Powerdc2",
    rw: "RO",
    unit: "W",
    remark: "výkon solární na stringu 2",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "modbusAddress",
    address: 0x00af,
    name: "ModBusRTU_Address",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "exportLimit",
    address: 0x00b6,
    name: "Export control user limit",
    rw: "RO",
    unit: "W",
    // doukumentace blbe
    multiple: 0.1,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "exportLimitSoft",
    address: 0x00e0,
    name: "Export control user limit",
    rw: "RO",
    unit: "W",
    // doukumentace blbe
    // multiple: 0.1,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "exportLimitHard",
    address: 0x00e9,
    name: "Export control user limit",
    rw: "RO",
    unit: "W",
    // doukumentace blbe
    // multiple: 0.1,
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),
  // Povoleni pretoku
  u16({
    key: "exportLimit",
    address: 0x0042,
    name: "Export control User_Limit",
    rw: "WO",
    unit: "W",
    // doukumentace blbe
    multiple: 0.1,
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  // casy

  time({
    key: "chargePeriod1Start",
    address: 0x0097,
    name: "ChargePeriod1_Start",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  time({
    key: "chargePeriod1Start",
    address: 0x0068,
    name: "NightCharge_Period1_StartTime",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  time({
    key: "chargePeriod1End",
    address: 0x0098,
    name: "ChargePeriod1_End",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  time({
    key: "chargePeriod1End",
    address: 0x0069,
    name: "NightCharge_Period1_EndTime",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  time({
    key: "dischargePeriod1Start",
    address: 0x0099,
    name: "DischargePeriod1_Start",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  time({
    key: "dischargePeriod1Start",
    address: 0x006a,
    name: "Discharge_Period1_StartTime",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  time({
    key: "dischargePeriod1End",
    address: 0x009a,
    name: "DischargePeriod1_End",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  time({
    key: "dischargePeriod1End",
    address: 0x006b,
    name: "Discharge_Period1_EndTime",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  modbusPowerControl({
    key: "modbusPowerControl",
    address: 0x0100,
    name: "ModbusPowerControl - Remote control",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "MPPTCount",
    address: 0x001b,
    name: "ModbusPowerControl - Remote control",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
  u16({
    key: "EnableMPPT",
    address: 0x00bc,
    name: "",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),
  u16({
    key: "EnableMPPT",
    address: 0x0048,
    name: "",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  modbusPowerControl({
    key: "modbusPowerControl",
    address: 0x007c,
    name: "ModbusPowerControl - Remote control",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  u16({
    key: "targetFinishFlag",
    address: 0x0101,
    name: "TargetFinishFlag 0:unfinished 1:finish ",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "targetSOC",
    address: 0x0083,
    name: "Target soc",
    rw: "WO",
    unit: "%",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  s32LSB({
    key: "chargeDischargePower",
    address: 0x0086,
    name: "Charge_Discharg_Power",
    rw: "WO",
    unit: "%",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  u16({
    key: "targetSOC",
    address: 0x011b,
    name: "Target soc",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "socUpper",
    address: 0x011c,
    name: "SocUpper",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
  u16({
    key: "socLower",
    address: 0x011d,
    name: "SocLower",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "firmwareVersionDSPMajor",
    address: 0x007f,
    name: "FirmwareVersion_DSP_Major",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "firmwareVersionDSPMinor",
    address: 0x007d,
    name: "FirmwareVersion_DSP_Minor",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "firmwareVersionARMMajor",
    address: 0x0080,
    name: "FirmwareVersion_ARM_Major",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),
  u16({
    key: "firmwareVersionModbusRTU",
    address: 0x0082,
    name: "FirmwareVersion_ModbusRTU",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),
  u16({
    key: "firmwareVersionARMMinor",
    address: 0x0083,
    name: "FirmwareVersion_ARM_Minor",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  s32LSB({
    key: "activePowerReal",
    address: 0x0106,
    name: "ActivePowerReal",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "activePowerUpper",
    address: 0x010a,
    name: "wActivePower_Upper",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "activePowerLower",
    address: 0x010c,
    name: "wActivePower_Lower",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "activePowerTarget",
    address: 0x0102,
    name: "ActivePowerTarget",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "activePowerTarget",
    address: 0x007e,
    name: "RemoteControl ActivePower",
    rw: "WO",
    unit: "W",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  u16({
    key: "timeOfDuration",
    address: 0x011a,
    name: "power control mode  Time of Duration",
    rw: "RO",
    unit: "s",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "timeOfDuration",
    address: 0x0082,
    name: "power control mode  Time of Duration",
    rw: "WO",
    unit: "s",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  u16({
    key: "remoteCtrlTimeOut",
    address: 0x011e,
    name: "remoteCtrlTimeOut",
    rw: "RO",
    unit: "s",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "remoteCtrlTimeOut",
    address: 0x0088,
    name: "remoteCtrlTimeOut",
    rw: "WO",
    unit: "s",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  runMode({
    key: "runMode",
    address: 0x0009,
    name: "Run Mode",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  targetSetType({
    key: "targetSetType",
    address: 0x007d,
    name: "TargetSetType",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),

  useMode({
    key: "solarChargerUseMode",
    address: 0x008b,
    name: "SolarChargerUseMode",
    rw: "RO",
    // unit: "W",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  useMode({
    key: "solarChargerUseMode",
    address: 0x001f,
    name: "SolarChargerUseMode",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  // cteni je na jednej adrese, ale zapisy na dvou...
  u8H({
    key: "feedInSocUpper",
    address: 0x0095,
    name: "Feedin_NightCharge_UpperSoC",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u8L({
    key: "feedInSocMin",
    address: 0x0095,
    name: "Feedin_Discharge_MinSoC",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "feedInSocMin",
    address: 0x0065,
    name: "Feedin_Discharge_MinSoC",
    rw: "WO",
    unit: "%",

    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "systemOnOff",
    address: 0x001c,
    name: "SystemON_OFF",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u8H({
    key: "backupSocUpper",
    address: 0x0096,
    name: "BackUp_NightCharge_UpperSoC",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u8L({
    key: "backupSocMin",
    address: 0x0096,
    name: "BackUp_Discharge_MinSoC",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "backupSocMin",
    address: 0x0067,
    name: "BackUp_Discharge_MinSoC",
    rw: "WO",
    unit: "%",

    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "backupSocUpper",
    address: 0x0066,
    name: "BackUp_NightCharge_UpperSoC",
    rw: "WO",
    unit: "%",

    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "feedInSocUpper",
    address: 0x0064,
    name: "Feedin_NightCharge_UpperSoC",
    rw: "WO",
    unit: "%",

    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u8H({
    key: "selfUseSocMin",
    address: 0x0093,
    name: "SelfUse_Discharge_MinSoC",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "selfUseSocMin",
    address: 0x0061,
    name: "SelfUse_Discharge_MinSoC",
    rw: "WO",
    unit: "%",
    remark: "10%~100%",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u8L({
    key: "selfUseGridChargeEnable",
    address: 0x0093,
    name: "SelfUse_NightCharge_Enable",
    rw: "RO",
    remark: "0:Disable 1:Enable",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),
  u16({
    key: "selfUseGridChargeEnable",
    address: 0x0062,
    name: "SelfUse_NightCharge_Enable",
    rw: "WO",
    remark: "0:Disable 1:Enable",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "selfUseSocMax",
    address: 0x0094,
    name: "SelfUse_NightCharge_UpperSoC",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "selfUseSocMax",
    address: 0x0063,
    name: "SelfUse_NightCharge_UpperSoC",
    rw: "WO",
    unit: "%",
    remark: "This value will be enabled if SelfUse_NightCharge_Enable is 1.10%~100%",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "period2Enabled",
    address: 0x009b,
    name: "Set_Chrg&DischrgPeriod2_Enable",
    rw: "RO",
    remark: "Whether to use period 2. 0:Disable 1:Enable",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "period2Enabled",
    address: 0x006c,
    name: "Set_Chrg&DischrgPeriod2_Enable",
    rw: "WO",
    remark: "0:Disable 1:Enable",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),
  u16({
    key: "bmsChargeMaxCurrent",
    address: 0x0024,
    name: "BMS_ChargeMaxCurrent",
    rw: "RO",
    unit: "A",
    multiple: 10,
    remark: "BMS_ChargeMaxCurrent",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "bmsDischargeMaxCurrent",
    address: 0x0025,
    name: "BMS_DichargeMaxCurrent",
    rw: "RO",
    unit: "A",
    multiple: 10,
    remark: "BMS_DischargeMaxCurrent",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "unlockPassword",
    address: 0x0000,
    name: "UnlockPassword",
    rw: "WO",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "lockState",
    address: 0x0054,
    name: "lock state",
    rw: "RO",
    remark: "0: locked, 1:unlocked admin, 2:unlocked superadmin",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s16({
    key: "gridPowerR",
    address: 0x6c,
    name: "Grid Power R",
    rw: "RO",
    unit: "W",
    // multiple: 1000,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s16({
    key: "gridPowerS",
    address: 0x70,
    name: "Grid Power S",
    rw: "RO",
    unit: "W",
    // multiple: 1000,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
  s16({
    key: "gridPowerT",
    address: 0x74,
    name: "Grid Power T",
    rw: "RO",
    unit: "W",
    // multiple: 1000,
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "epsPowerR",
    address: 0x78,
    name: "EpsPowerActive_R",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "epsPowerS",
    address: 0x7c,
    name: "EpsPowerActive_S",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "epsPowerT",
    address: 0x80,
    name: "EpsPowerActive_T",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    // nedokumentovano - nasli sme v pameti podle hesla 2014
    key: "unlockPassword",
    address: 0xe1,
    name: "UnlockPassword",
    rw: "RO",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "checkingTime",
    address: 0x18,
    name: "CheckingTime",
    rw: "RO",
    unit: "s",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),

  u16({
    key: "checkingTime",
    address: 0x02,
    name: "CheckingTime",
    rw: "WO",
    unit: "s",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),

  u16({
    key: "batteryTemperature",
    address: 0x0018,
    name: "TemperatureBat",
    rw: "RO",
    unit: "C",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s32LSB({
    key: "pushModePower",
    address: 0x0089,
    name: "PushModePower",
    rw: "WO",
    unit: "W",
    functionCode: ModBusFunctionCode.WriteRegisters,
  }),
]

export const SolaxGEN3Registers = SolaxBaseRegisters

// manual mode available since GEN4
const SolaxBaseManualModeRegisters = [
  manualMode({
    key: "manualMode",
    address: 0x008c,
    name: "Manual mode",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadMultipleHoldingRegisters,
  }),
  manualMode({
    key: "manualMode",
    address: 0x0020,
    name: "Manual mode",
    rw: "WO",
    unit: "%",
    functionCode: ModBusFunctionCode.WriteSingleHoldingRegister,
  }),
]

export const SolaxGEN4Registers = [
  ...SolaxBaseRegisters,
  ...SolaxBaseManualModeRegisters,
  // jenom do GEN4
  u16({
    key: "soc",
    address: 0x001c,
    name: "Battery Capacity",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
]

export const SolaxGEN5Registers = [
  ...SolaxBaseRegisters,
  ...SolaxBaseManualModeRegisters,

  // GEN5 has two batteries - this register is average of both
  u16({
    key: "soc",
    address: 0x012e,
    name: "Battery Total Capacity",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "soc1",
    address: 0x001c,
    name: "Battery 1 Capacity",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
  u16({
    key: "soc2",
    address: 0x012d,
    name: "Battery 2 Capacity",
    rw: "RO",
    unit: "%",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  s16({
    key: "battPowerCharge2",
    address: 0x0129,
    name: "Batpower Charge 2",
    rw: "RO",
    unit: "W",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "powerDc3",
    address: 0x0124,
    name: "Powerdc3",
    rw: "RO",
    unit: "W",
    remark: "výkon solární na stringu 3",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),

  u16({
    key: "batteryTemperature2",
    address: 0x0131,
    name: "TemperatureBat2",
    rw: "RO",
    unit: "C",
    functionCode: ModBusFunctionCode.ReadInputRegisters,
  }),
]
