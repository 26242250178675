import {
  createCalculated,
  f32,
  RWType,
  s16,
  s32,
  s32LSB,
  s64,
  string,
  u8H,
  u8L,
  u16,
  u32,
  u32LSB,
  u64,
} from "../modbus/dataTypes"
import { ModBusReply } from "../modbus/utils"
import { parseAA55Message } from "../parsers/parseAA55Message"
import { parseModbusTCPReply } from "../parsers/parseModbusTCPReply"
import { parseSolarmanV5Message } from "../parsers/parseSolarmanV5Message"
import { AplhaESSRegisters } from "./alphaESS/registers"
import { AppMode, EMSPowerMode, WeeklySchedule, WorkMode } from "./goodwe/gw10/convertors"
import { GoodweSpecificRegisters } from "./goodwe/gw10/dataTypes"
import { GW10Registers } from "./goodwe/gw10/registers"
import { HuaweiSpecificRegisters } from "./huawei/map0/dataTypes"
import { HuaweiMAP0Registers } from "./huawei/map0/registers"
import {
  ManualMode,
  ModbusPowerControl,
  RunMode,
  SolaxSpecificRegisters,
  TargetSetType,
  UseMode,
} from "./solax/dataTypes"
import { SolaxGEN3Registers, SolaxGEN4Registers, SolaxGEN5Registers } from "./solax/registers"
import { ZucchettiSpecificRegisters } from "./zucchetti/dataTypes"
import { ZucchettiRegisters } from "./zucchetti/registers"

export { parseAA55Message } from "../parsers/parseAA55Message"
export { parseSolarmanV5Message } from "../parsers/parseSolarmanV5Message"

// | typeof EtrelInchHomeRegisters
// | typeof WattSonicAsiRegisters

export type GenericDevicesRegister<T extends { key: string } = { key: string; rw: RWType }> = T &
  (
    | ReturnType<typeof string>
    | ReturnType<typeof u8H>
    | ReturnType<typeof u8L>
    | ReturnType<typeof u16>
    | ReturnType<typeof s16>
    | ReturnType<typeof s32>
    | ReturnType<typeof u32>
    | ReturnType<typeof u64>
    | ReturnType<typeof s64>
    | ReturnType<typeof f32>
    | ReturnType<typeof u32LSB>
    | ReturnType<typeof s32LSB>
    | ReturnType<ReturnType<typeof createCalculated>> // createCalculated return type is function
    | GoodweSpecificRegisters
    | ZucchettiSpecificRegisters
    | SolaxSpecificRegisters
    | HuaweiSpecificRegisters
  )

export type GenericPartialResponse<R extends GenericDevicesRegister> = {
  [K in R["key"]]?: Extract<R, { key: K }> & {
    value: ReturnType<NonNullable<Extract<R, { key: K }>["convertor"]>["decode"]>["value"]
    raw: Buffer
    debug: string
  }
}

const assertDupes = (array: string[]) => {
  const dupes = array.filter((v) => array.filter((v1) => v1 === v).length > 1)
  if (dupes.length > 0) {
    throw new Error(`duplicates: ${dupes.join(", ")}`)
  }
}

type PreprocessResponseOpts = {
  solarmanSerialNumber?: string
}
export interface Device<R extends GenericDevicesRegister> {
  registers: R[]
  preprocessResponse: (data: Buffer, opts: PreprocessResponseOpts) => ModBusReply
}

function createDevice<T extends GenericDevicesRegister[]>(
  registers: T,
  preprocessResponse: (data: Buffer, opts: PreprocessResponseOpts) => ModBusReply,
): Device<T[number]> {
  const r = registers.filter((r) => r.rw === "RO" || r.rw == "RW").map((r) => r.key)
  assertDupes(r)

  const w = registers.filter((r) => r.rw === "WO" || r.rw == "RW").map((r) => r.key)
  assertDupes(w)

  return { registers, preprocessResponse }
}

export const tcpModbusPreprocessor = (buffer: Buffer): ModBusReply => {
  // console.log("parseModbusTCPReply", buffer.toString("hex"))
  // TODO txID - nejak provazat s requestem

  const id = buffer.readUInt16BE(0)
  const protocol = buffer.readUInt16BE(2)
  if (protocol != 0) {
    throw new Error("Invalid protocol " + protocol)
  }
  const length = buffer.readUInt16BE(4)
  const o = buffer.subarray(6, 6 + length)
  return parseModbusTCPReply(id, o)
}

export const SolaxEnums = {
  TargetSetType,
  ModbusPowerControl,
  RunMode,
  UseMode,
  ManualMode,
} as const

export type SolaxGEN4Registers = (typeof SolaxGEN4.registers)[number]
export type SolaxGEN5Registers = (typeof SolaxGEN5.registers)[number]
export type SolaxRegister = SolaxGEN4Registers | SolaxGEN5Registers

export type Goodwe10Registers = (typeof GoodWe10.registers)[number]
export type GoodweRegister = Goodwe10Registers

export type HuaweiMAP0Registers = (typeof HuaweiMAP0.registers)[number]
export type HuaweiRegister = HuaweiMAP0Registers

export const GoodweEnums = {
  AppMode,
  WorkMode,
  EMSPowerMode,
  WeeklySchedule,
} as const

export const GoodWe10Udp = createDevice(GW10Registers, parseAA55Message)
export const Zucchetti = createDevice(ZucchettiRegisters, parseSolarmanV5Message)
export const GoodWe10 = createDevice(GW10Registers, tcpModbusPreprocessor)
export const SolaxGEN3 = createDevice(SolaxGEN3Registers, tcpModbusPreprocessor)
export const SolaxGEN4 = createDevice(SolaxGEN4Registers, tcpModbusPreprocessor)
export const SolaxGEN5 = createDevice(SolaxGEN5Registers, tcpModbusPreprocessor)
export const AplhaESS = createDevice(AplhaESSRegisters, tcpModbusPreprocessor)
export const HuaweiMAP0 = createDevice(HuaweiMAP0Registers, tcpModbusPreprocessor)

// export const EtrelInchHome = createDevice(EtrelInchHomeRegisters, tcpModbusPreprocessor)
// export const WattSonicAsi = createDevice(WattSonicAsiRegisters, tcpModbusPreprocessor)

export type SolaxDevice = typeof SolaxGEN4 | typeof SolaxGEN5
export type GoodweDevice = typeof GoodWe10
export type HuaweiDevice = typeof HuaweiMAP0
