import { type Logger } from "@deltagreen/logger"
import { createExpirableObject, ExpirableObjectRT, randomUInt16WithTrailingZerosInSecondByte } from "@deltagreen/utils"
import { createId as cuid, init } from "@paralleldrive/cuid2"
import { type MqttClient } from "mqtt"
import { z } from "zod"

import { BaseEmitter, CommandQueue, MessageEvents } from "./commandQueue"
import { debugFactory } from "./debugFactory"
import { Device, GenericDevicesRegister, GenericPartialResponse } from "./devices"
import { ModBusFunctionCode, ReadRegisterFunctionCode } from "./modbus/modbusProtocol"
import {
  buildMultiReadRequest,
  DEVICE_STATE_EXPIRATION,
  mapCalculatedRegisters,
  mapResponseToRegisters,
  ReadableRegister,
  WritableRegister,
} from "./modbus/utils"
import {
  EmodbusErrorMessage,
  EmodbusMessage,
  EmodbusMessageResult,
  emodbusMessageResultToJSON,
} from "./proto/deltalink"

export type { Device, GenericDevicesRegister } from "./devices"
export {
  AplhaESS,
  // EtrelInchHome,
  GoodWe10,
  GoodWe10Udp,
  GoodweEnums,
  HuaweiMAP0,
  SolaxEnums,
  SolaxGEN3,
  SolaxGEN4,
  SolaxGEN5,
  Zucchetti,
} from "./devices"
export {
  buildModbusMessageFromRegisters,
  createModbusMessageFromRegisters,
} from "./helpers/createModbusMessageFromRegisters"
export { ModBusFunctionCode } from "./modbus/modbusProtocol"
export type { ReadableRegister, WritableRegister } from "./modbus/utils"
export {
  ConfigMessage,
  EmodbusMessage,
  HttpResponseChunkMessage,
  LastWillMessage,
  LastWillStrategy,
  ModbusBridgeConfigType,
} from "./proto/deltalink"
export * from "./udpScan"

class ModbusReponseError extends Error {
  modbusResponse: object
  hex: string

  constructor(message: string, options: ErrorOptions, response: EmodbusMessage) {
    super(message, options)
    Error.captureStackTrace(this, this.constructor)

    this.name = this.constructor.name
    this.modbusResponse = EmodbusMessage.toJSON(response) as object
    this.hex = Buffer.from(response.data).toString("hex")
  }
}

type CreateModbusBridgeMqttClientParams<TModbusDevice extends Device<GenericDevicesRegister>> = {
  mqttClient: MqttClient
  deltaLinkMacAddress: string
  logger?: Logger
  modbus: {
    device: TModbusDevice
    ipAddress: string
    port: number
    modbusAddress: number
    modbusAddressMap?: Record<string, number> | null
    solarmanSerialNumber?: string
    protocol?: "tcp" | "udp"
  }
  skipSubscribes?: boolean
}

// deprecated
const ModbusError = {
  SUCCESS: 0x00,
  ILLEGAL_FUNCTION: 0x01,
  ILLEGAL_DATA_ADDRESS: 0x02,
  ILLEGAL_DATA_VALUE: 0x03,
  SERVER_DEVICE_FAILURE: 0x04,
  ACKNOWLEDGE: 0x05,
  SERVER_DEVICE_BUSY: 0x06,
  NEGATIVE_ACKNOWLEDGE: 0x07,
  MEMORY_PARITY_ERROR: 0x08,
  GATEWAY_PATH_UNAVAIL: 0x0a,
  GATEWAY_TARGET_NO_RESP: 0x0b,
  TIMEOUT: 0xe0,
  INVALID_SERVER: 0xe1,
  CRC_ERROR: 0xe2, // only for Modbus-RTU
  FC_MISMATCH: 0xe3,
  SERVER_ID_MISMATCH: 0xe4,
  PACKET_LENGTH_ERROR: 0xe5,
  PARAMETER_COUNT_ERROR: 0xe6,
  PARAMETER_LIMIT_ERROR: 0xe7,
  REQUEST_QUEUE_FULL: 0xe8,
  ILLEGAL_IP_OR_PORT: 0xe9,
  IP_CONNECTION_FAILED: 0xea,
  TCP_HEAD_MISMATCH: 0xeb,
  EMPTY_MESSAGE: 0xec,
  ASCII_FRAME_ERR: 0xed,
  ASCII_CRC_ERR: 0xee,
  ASCII_INVALID_CHAR: 0xef,
  BROADCAST_ERROR: 0xf0,
  UNDEFINED_ERROR: 0xff, // otherwise uncovered communication error
}

export async function createModbusBridgeMqttClient<TDevice extends Device<GenericDevicesRegister>>(
  params: CreateModbusBridgeMqttClientParams<TDevice>,
) {
  const { modbus, mqttClient, deltaLinkMacAddress, logger } = params
  const { ipAddress, port, modbusAddress, protocol = "tcp", device, solarmanSerialNumber } = modbus
  const { registers } = device

  const createModbusClientId = init({ length: 10 })
  const modbusClientId = createModbusClientId()

  type RegisterType = TDevice["registers"][number]
  let serial = 0
  function nextSerial() {
    serial = (serial + 1) % 65535
    return serial
  }

  const queryHits = new Map<string, boolean>()

  const replyTopic = `delta-link/${deltaLinkMacAddress}/v2/${protocol}-reply/${modbusClientId}`
  const commandTopic = `delta-link/${deltaLinkMacAddress}/v2/${protocol}/${ipAddress}/${port}/${modbusClientId}`
  const queue = new CommandQueue(device, mqttClient, {
    commandTopic,
    replyTopic,
    logger,
    protocol,
    solarmanSerialNumber,
  })

  function analyseRegisters() {
    return { keys: [...queryHits.keys()], registers: registers.filter((r) => r.rw === "RO" || r.rw === "RW") }
  }

  function query(keys: ReadableRegister<RegisterType>["key"][]) {
    // TODO proc to
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { address, functionCode, length } = buildMultiReadRequest(registers, keys)

    if (address === undefined) {
      throw new Error("invalid address")
    }

    queryHits.set(keys.join(","), true)

    // logger?.debug({ keys, address, functionCode, length }, "Adding read command to queue")
    return queryRaw(functionCode, address, length)
  }

  function queryRaw(functionCode: ReadRegisterFunctionCode, address: number, length: number) {
    logger?.debug({ address, functionCode, length }, "Adding read command to queue")
    queue.enqueue({
      type: "READ",
      registerAddress: address,
      length,
      functionCode,
      serial: nextSerial(),
      deviceAddress: modbusAddress,
    })
  }

  function writeMulti<K extends WritableRegister<RegisterType>["key"]>(
    items: Record<K, Parameters<Extract<WritableRegister<RegisterType>, { key: K }>["convertor"]["encode"]>[0]>,
  ) {
    const keysToWrite = Object.keys(items)
    const registers = device.registers
      .filter((r) => r.rw === "WO" || r.rw === "RW")
      .filter((r) => keysToWrite.includes(r.key))
    registers.sort((a, b) => a.address - b.address)

    // funguje to jen na 16 bitove registry

    const firstRegister = registers[0]
    const lastRegister = registers[registers.length - 1]

    if (!firstRegister || !lastRegister) {
      throw new Error(`No registers found for keys ${keysToWrite.join(",")}`)
    }
    const requestedWriteSize = (lastRegister.address + 2 - firstRegister.address) / 2

    if (requestedWriteSize !== keysToWrite.length) {
      throw new Error(
        `Number of items (${keysToWrite.length}) does not match number of registers range (${requestedWriteSize}) - ${keysToWrite.join(",")}`,
      )
    }

    const d = registers.map((r) => {
      const key = r.key as K
      return r.convertor.encode(items[key] as never, r)
    })

    const data = Buffer.concat(d)
    // console.log(d)
    // const data = register.convertor.encode(payload, register)

    // console.log(requestedWriteSize , keysToWrite.length)
    // if (!register) {
    //   throw new Error(`Register not found ${key}`)
    // }

    // console.log("!!!!writeMulti", items)

    const queueId = cuid()
    // logger?.debug({ key, payload, data: data.toString("hex"), queueId }, "Adding write command to queue")
    queue.enqueue({
      data,
      queueId,
      type: "WRITE",
      functionCode: ModBusFunctionCode.WriteRegisters,
      registerAddress: firstRegister.address,
      serial: nextSerial(),
      deviceAddress: modbusAddress,
    })

    return { queueId }
  }

  function write<K extends WritableRegister<RegisterType>["key"]>(
    key: K,
    payload: Parameters<Extract<WritableRegister<RegisterType>, { key: K }>["convertor"]["encode"]>[0],
  ) {
    const register = device.registers.filter((r) => r.rw === "WO" || r.rw === "RW").find((r) => r.key == key)
    if (!register) {
      throw new Error(`Register not found ${key}`)
    }

    // type X = Parameters<Extract<WritableRegister<RegisterType>, { key: ""ecoMode1WorkWeek"}>["convertor"]["encode"]>[0]
    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore ano sem k hovnu
    const data = register.convertor.encode(payload, register)

    const queueId = cuid()
    logger?.debug({ key, payload, data: data.toString("hex"), queueId }, "Adding write command to queue")
    queue.enqueue({
      data,
      queueId,
      type: "WRITE",
      functionCode: register.functionCode || ModBusFunctionCode.WriteSingleHoldingRegister,
      registerAddress: register?.address,
      serial: nextSerial(),
      deviceAddress: modbusAddress,
    })

    return { queueId }
  }

  async function close() {
    return await queue.close()
  }

  async function waitForWriteResponse(queueID: string, timeoutSeconds?: number) {
    return await queue.waitForWriteResponse(queueID, timeoutSeconds)
  }

  const info = {
    ipAddress,
    port,
    modbusAddress,
    modbusClientId,
    protocol,
    deltaLinkMacAddress,
  } as const
  return {
    discover: () => {
      queue.goodWeDiscover()
    },
    query,
    queryRaw,
    write,
    writeMulti,
    analyseRegisters,
    close,
    waitForWriteResponse,
    on: queue.on.bind(queue),
    once: queue.once.bind(queue),
    mqttClient,
    info,
  }
}
export function modbusErrorNameByCode(errorCode: number, errorCodeEnum?: EmodbusMessageResult) {
  // deprecated
  if (errorCodeEnum == undefined) {
    // TODO jak natypovat?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    const errorName = Object.keys(ModbusError).find((key) => ModbusError[key] === errorCode)
    return errorName
  }

  return emodbusMessageResultToJSON(errorCodeEnum)
}
export async function createEModbusBridgeMqttClient<TDevice extends Device<GenericDevicesRegister>>(
  params: CreateModbusBridgeMqttClientParams<TDevice>,
) {
  // type CustomEmitter = BaseEmitter<MessageEvents<TDevice["registers"][number]>>
  const emitter = new BaseEmitter<MessageEvents<TDevice["registers"][number]>>()
  const debug = debugFactory("emodbus")

  const deviceState: ExpirableObjectRT<GenericPartialResponse<TDevice["registers"][number]>> =
    createExpirableObject(DEVICE_STATE_EXPIRATION)

  const { modbus, mqttClient, deltaLinkMacAddress, logger, skipSubscribes = false } = params
  const { ipAddress, port, modbusAddress, modbusAddressMap, protocol = "tcp", device } = modbus
  const { registers } = device

  const createModbusClientId = init({ length: 10 })
  const modbusClientId = createModbusClientId()

  type RegisterType = TDevice["registers"][number] // TODO

  // plus for modbus address
  const subTopic = `delta-link/${deltaLinkMacAddress}/v2/modbus-reply/${ipAddress}/${port}/+`
  const errorTopic = `delta-link/${deltaLinkMacAddress}/v2/modbus-error/${ipAddress}/${port}/+`
  if (!skipSubscribes) {
    await mqttClient.subscribeAsync(subTopic, { qos: 1 })
    await mqttClient.subscribeAsync(errorTopic, { qos: 1 })
  }

  const handleMessage = (topic: string, protoData: Buffer) => {
    // debug zapisu
    // if (topic == publishTopic) {
    //   const mm = EmodbusMessage.decode(protoData)
    //   if (mm.functionCode != 3 && mm.functionCode != 4) {
    //     // eslint-disable-next-line no-console
    //     console.log(new Date(), mm)
    //   }
    // }
    // console.log(topic == publishTopic)

    // if (topic !== subTopic && topic !== errorTopic) {
    // return
    // }

    if (topic.startsWith(errorTopic.slice(0, -1))) {
      const response = EmodbusErrorMessage.decode(protoData)
      debug("ERROR", { ...response, name: modbusErrorNameByCode(response.errorCode, response.errorCodeEnum) })
      emitter.emit("modbusError", response)
      return
    }

    if (topic.startsWith(subTopic.slice(0, -1))) {
      const responseModbusAddress = Number(topic.split("/").reverse()[0] || modbusAddress)
      const response = EmodbusMessage.decode(protoData)

      debug("RESPONSE", { ...response })

      // hodnoty vysledku sou k nicemu
      if (response.functionCode == ModBusFunctionCode.WriteRegisters) {
        // console.log("TODO vysledek zapisu1", response)
        return
      }
      if (response.functionCode == ModBusFunctionCode.WriteSingleHoldingRegister) {
        // console.log("TODO vysledek zapisu1", response)
        return
      }

      try {
        let targetDevice = undefined
        if (modbusAddressMap) {
          // TODO najit

          const mappedItem = Object.entries(modbusAddressMap).find(([, value]) => {
            return value == responseModbusAddress
          })

          if (mappedItem) {
            targetDevice = mappedItem[0]
          }
        }

        const responseArray = mapResponseToRegisters(
          response.functionCode as ModBusFunctionCode,
          device.registers,
          response.address,
          Buffer.from(response.data),
          targetDevice,
        )

        const errorResponses = responseArray.filter((r) => r.type === "decodeError")
        for (const response of errorResponses) {
          if (response.type === "decodeError") {
            emitter.emit("error", response)
          }
        }

        const successResponses = responseArray.filter((r) => r.type === "decoded")
        emitter.emit("dataRaw", response.data)

        const entries = successResponses.map((o) => [o.key, o])
        const partialData = Object.fromEntries(entries) as GenericPartialResponse<TDevice["registers"][number]>

        deviceState.upsert(partialData)
        const calculatedData = mapCalculatedRegisters(deviceState.get(), device.registers)

        emitter.emit("data", { ...partialData, ...calculatedData })
      } catch (error) {
        throw new ModbusReponseError("Modbus parsing error", { cause: error }, response)
      }
    }
  }

  mqttClient.on("message", (topic, protoData) => {
    try {
      handleMessage(topic, protoData)
    } catch (error) {
      emitter.emit("error", error)
    }
  })
  const getPublishTopic = (targetDevice?: string) => {
    let address = modbusAddress
    if (targetDevice && modbusAddressMap) {
      if (modbusAddressMap[targetDevice] === undefined) {
        throw new Error("Unknown target device: " + targetDevice)
      }
      address = modbusAddressMap[targetDevice]
    }

    return `delta-link/${deltaLinkMacAddress}/v2/modbus/${ipAddress}/${port}/${address}`
  }

  // mqttClient.subscribe(publishTopic) // debug zapisu

  function query(keys: ReadableRegister<RegisterType>["key"][]) {
    // TODO proc to
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { address, functionCode, length, targetDevice } = buildMultiReadRequest(registers, keys)

    if (address === undefined) {
      throw new Error("invalid address")
    }

    logger?.debug({ keys, address, functionCode, length }, "Adding read command to queue")
    // dylku cteni posilame v payloadu
    const payload = Buffer.alloc(2)
    payload.writeUInt16BE(length)

    return publishRequest(functionCode, address, payload, targetDevice)
  }

  async function publishRequest(
    functionCode: ModBusFunctionCode,
    address: number,
    payload: Buffer,
    targetDevice?: string,
  ) {
    const requestId = randomUInt16WithTrailingZerosInSecondByte()

    const message = EmodbusMessage.fromPartial({
      address,
      functionCode,
      requestId,
      data: payload,
    })

    const b = EmodbusMessage.encode(message)
    const publishTopic = getPublishTopic(targetDevice)

    try {
      logger?.debug({ functionCode, requestId, address }, `Sending MQTT request ${requestId} to ${publishTopic}`)
      debug("REQUEST", publishTopic, message)
      await mqttClient.publishAsync(publishTopic, Buffer.from(b.finish()), { qos: 1 })
      logger?.debug({ functionCode, requestId, address }, `Sent MQTT request ${requestId} to ${publishTopic}`)
    } catch (error) {
      logger?.warn(
        { functionCode, requestId, address, error },
        `Failed to send MQTT request ${requestId} to ${publishTopic}`,
      )

      throw error
    }
  }

  async function write<K extends WritableRegister<RegisterType>["key"]>(
    key: K,
    payload: Parameters<Extract<WritableRegister<RegisterType>, { key: K }>["convertor"]["encode"]>[0],
  ) {
    const register = device.registers.filter((r) => r.rw === "WO" || r.rw === "RW").find((r) => r.key == key)
    if (!register) {
      throw new Error(`Register not found ${key}`)
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore ano sem k hovnu
    const data = register.convertor.encode(payload, register)
    //.swap16() // protoze indiani ?
    logger?.debug({ key, payload, data: data.toString("hex") }, "Adding write command to queue")
    debug({ key, payload, data: data.toString("hex") }, "Adding write command to queue")

    return await publishRequest(
      register.functionCode || ModBusFunctionCode.WriteSingleHoldingRegister,
      register.address,
      data,
    )
  }

  async function close() {
    logger?.info({}, "Closing modbus bridge mqtt client.")
    try {
      await mqttClient.unsubscribeAsync([subTopic, errorTopic])
    } catch (error) {
      logger?.error({ error }, "Error unsubscribing from mqtt.")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function waitForWriteResponse(queueID: string, timeoutSeconds?: number) {}

  const info = {
    ipAddress,
    port,
    modbusAddress,
    modbusClientId,
    protocol,
    deltaLinkMacAddress,
  } as const

  return {
    query,
    queryRaw: publishRequest,
    write,
    close,
    waitForWriteResponse,
    on: emitter.on.bind(emitter),
    once: emitter.once.bind(emitter),
    mqttClient,
    info,
  }
}

export const mqttTopics = {
  tele: (mac: string) => `delta-link/${mac}/v2/tele`,
  log: (mac: string) => `delta-link/${mac}/v2/log`,
  mdns: (mac: string, service: string, protocol: string) => `delta-link/${mac}/v2/mdns/${service}/${protocol}`,
  mdnsReply: (mac: string) => `delta-link/${mac}/v2/mdns-reply`,
  mdnsSd: (mac: string) => `delta-link/${mac}/v2/mdns-sd`,
  mdnsSdReply: (mac: string) => `delta-link/${mac}/v2/mdns-sd-reply`,
  ping: (mac: string, ip: string) => `delta-link/${mac}/v2/ping/${ip}`,
  pingReply: (mac: string) => `delta-link/${mac}/v2/ping-reply`,
  logger: (mac: string, seconds: number) => `delta-link/${mac}/v2/logger/${seconds}`,
  restart: (mac: string) => `delta-link/${mac}/v2/restart`,
  restartInverter: (mac: string, ipAddress: string, username: string, password: string) =>
    `delta-link/${mac}/v2/restart/solax/${ipAddress}/${username}/${password}`,
  proxyEnable: (mac: string) => `delta-link/${mac}/v2/proxy/enable`,
  proxyDisable: (mac: string) => `delta-link/${mac}/v2/proxy/disable`,
  scan: (mac: string) => `delta-link/${mac}/v2/scan`,
  scanMac: (mac: string, inverterMac: string) => `delta-link/${mac}/v2/scan-mac/${inverterMac}`,
  scanStatus: (mac: string) => `delta-link/${mac}/v2/scan-status`,
  scanReply: (mac: string) => `delta-link/${mac}/v2/scan-reply`,
  scanWifi: (mac: string) => `delta-link/${mac}/v2/scan-wifi`,
  scanWifiReply: (mac: string) => `delta-link/${mac}/v2/scan-wifi-reply`,
  inverterWifiScan: (mac: string) => `delta-link/${mac}/v2/inverter-wifi-scan`,
  update: (mac: string) => `delta-link/${mac}/v2/update`,
  httpSend: (mac: string) => `delta-link/${mac}/v2/http-send`,
  httpReply: (mac: string) => `delta-link/${mac}/v2/http-reply`,
  httpReplyChunk: (mac: string) => `delta-link/${mac}/v2/http-reply-chunk`,
  udp: (mac: string, ip: string, port: string | number, clientId: string) =>
    `delta-link/${mac}/v2/udp/${ip}/${port}/${clientId}`,
  udpReply: (mac: string, clientId: string) => `delta-link/${mac}/v2/udp-reply/${clientId}`,
  udpScan: (mac: string, ip: string, port: string | number) => `delta-link/${mac}/v2/udp-scan/${ip}/${port}`,
  udpScanReply: (mac: string) => `delta-link/${mac}/v2/udp-scan-reply`,
  getBridgeConfig: (mac: string) => `delta-link/${mac}/v2/modbus-bridge-config/get`,
  setBridgeConfig: (mac: string, persist: boolean) => `delta-link/${mac}/v2/modbus-bridge-config/set/${persist}`,
  bridgeConfigReply: (mac: string) => `delta-link/${mac}/v2/modbus-bridge-config-reply`,
  rescanReconnectWifi: (mac: string) => `delta-link/${mac}/v2/rescan-reconnect-wifi`,
  setConfig: (mac: string) => `delta-link/${mac}/v2/config/set`,
  readLogBuffer: (mac: string) => `delta-link/${mac}/v2/read-log-buffer`,
} as const

export const telemetryPayloadSchema = z.object({
  heapSize: z.number().nullish(),
  freeHeapSize: z.number().nullish(),
  uptimeSecondsSincePowerOn: z.number().nullish(),
  uptimeSecondsSinceStart: z.number().nullish(),
  orphanCount: z.number(),
  ssid: z.string(),
  bssid: z.string().nullish(),
  rssi: z.number(),
  firmware: z.string(),
  localIP: z.string(),
  maxQueueSize: z.number().nullish(),
  tcpQueueSize: z.number(),
  udpQueueSize: z.number(),
  lastRestartReason: z.string(),
  wiFiPSType: z.string().nullish(),
  wifiSecondsSinceConnected: z.number().nullish(),
  incomingMessages: z.number().nullish(),
  outgoingMessages: z.number().nullish(),
  totalResponseTimeMs: z.number().nullish(),
  inverterConnectionType: z.string().nullish(),
  connectionPingAverageMs: z.number().nullish(),
  connectionSuccessRate: z.number().nullish(),
  publishQueueSize: z.number().nullish(),
  enqueueEnabled: z.boolean().nullish(),
  incomingBridgeRequests: z.number().nullish(),
  outgoingBridgeRequests: z.number().nullish(),
  timeoutedBridgeRequests: z.number().nullish(),
  totalBridgeResponseTimeMs: z.number().nullish(),
  configVersion: z.string().nullish(),
  board: z.enum(["ATOM", "ATOMS3"]).nullish(),
})

export const scanStatusPayloadSchema = z.discriminatedUnion("status", [
  z.object({ status: z.literal("running") }),
  z.object({ status: z.literal("stopped") }),
])

export const scanPayloadSchema = z.object({
  mac: z.string(),
  ip: z.string(),
  vendor: z.string(),
  device: z.string(),
  sn: z.string().nullish(),
  ssid: z.string().nullish(),
})

export const mdsnPayloadSchema = z.object({
  hostname: z.string(),
  ip: z.string(),
  port: z.number(),
  mac: z.string().nullish(),
  txt: z.record(z.string()),
})

export const httpReplyPayloadSchema = z.object({
  id: z.string(),
  code: z.number(),
  body: z.string().optional(),
  size: z.number().optional(),
  headers: z.record(z.string()).optional(),
})

export const httpSendPayload = z.object({
  id: z.string(),
  responseHeaders: z.array(z.string()),
  url: z.string(),
  method: z.string(),
  headers: z.record(z.string()),
  body: z.string(),
})

export type HttpSendPayload = z.infer<typeof httpSendPayload>

export type TelemetryPayload = z.infer<typeof telemetryPayloadSchema>

export type ModbusBridgeClient<TDevice extends Device<GenericDevicesRegister>> = Awaited<
  ReturnType<typeof createModbusBridgeMqttClient<TDevice>>
>

export type EModbusBridgeClient<TDevice extends Device<GenericDevicesRegister>> = Awaited<
  ReturnType<typeof createEModbusBridgeMqttClient<TDevice>>
>

export type ModbusClient<TDevice extends Device<GenericDevicesRegister>> =
  | ModbusBridgeClient<TDevice>
  | EModbusBridgeClient<TDevice>

export const linkBoxMdnsProperties = {
  serialNumber: "SN",
  deltaPortOverride: "DELTA_PORT_OVERRIDE",
} as const

export const defaultModbusPort = 502
export const defaultUdpModbusPort = 8899
export const defaultUdpModbusAddress = 247

export { parseAA55Message, parseSolarmanV5Message, tcpModbusPreprocessor } from "./devices"
