import { Prisma } from "@prisma/client"
import { z } from "zod"

import { PrismaClientExtended } from "../prisma/createClient"

const batteryPowerForInvertersSchema = z.array(
  z.object({
    time: z.date(),
    deviceId: z.string(),
    batteryPower: z.nullable(z.instanceof(Prisma.Decimal)).transform((v) => v?.toNumber() ?? null),
  }),
)

export async function getBatteryPowerForInvertersInRange(
  db: PrismaClientExtended,
  inverterIds: string[],
  rangeParams: {
    from: Date
    to: Date
    fillStartFrom: Date
    fillStartTo: Date
  },
) {
  const { from, to, fillStartFrom, fillStartTo } = rangeParams
  const inverterIdsParam = Prisma.join(inverterIds)
  const dataRaw = await db.$queryRaw`
	-- filtering out only relevant data to work with
	WITH inverter_states AS (
	  SELECT isv."time", isv.inverter_id, isv.battery_power FROM inverter_state_v2 isv 
	  where
	    "time" BETWEEN ${fillStartFrom} AND ${to}
	    AND inverter_id in (${inverterIdsParam})
	),
	series AS (
	  SELECT
	    time_bucket_gapfill('1 second', isv."time") as "time",
	    isv.inverter_id,
	    -- can use avg safely, since data sampling is larger than bucket size - avg of 1 value is the same value
	    AVG(isv.battery_power) AS value,
	    LOCF(
	      LAST(isv."time", isv."time"),
	      (
	        SELECT isv2."time"
	        FROM inverter_states isv2
	        -- looking back to ffill
	        WHERE isv2."time" BETWEEN ${fillStartFrom} AND ${fillStartTo} AND isv2.inverter_id = isv.inverter_id
		ORDER BY isv2."time" DESC
		LIMIT 1
	      )
	    ) as ffilled_from,
	    LOCF(
	      AVG(isv.battery_power),
	      (
	        SELECT isv2.battery_power
	        FROM inverter_states isv2
	        -- looking back to ffill
	        WHERE isv2."time" BETWEEN ${fillStartFrom} AND ${fillStartTo} AND isv2.inverter_id = isv.inverter_id
	        ORDER BY isv2."time" DESC
	        LIMIT 1
	      )
	    ) as ffilled_value
	  FROM inverter_states isv
	  WHERE
	    "time" BETWEEN ${from} AND ${to}
	    AND inverter_id IN (${inverterIdsParam})
	  GROUP BY 1, 2
	)
	SELECT
	  s.time,
	  s.inverter_id AS "deviceId",
	  s.ffilled_from AS "ffilledFrom",
	  -- when filling from before the start of interval, we want to allow more than 5s difference
	  CASE WHEN s.ffilled_from >= ${from} AND s.time - s.ffilled_from > '5 seconds'::interval THEN NULL
	    ELSE s.ffilled_value
	    END AS "batteryPower"
	FROM series s
	ORDER BY "deviceId", "time";
	`

  // may be a lot of data, therefore parsing asynchronously
  const data = await batteryPowerForInvertersSchema.parseAsync(dataRaw)
  return data
}
